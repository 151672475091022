// driving_school_classroom_multi_enroll_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["checkbox", "pupilRow", "enrolledList", "attendanceCount", "error", "saveButton"]
  static values = { programId: String }
  
  saveEnrollments(event) {
    event.preventDefault()
    let enrolledPupilIds = Array.from(this.enrolledListTarget.children).map(row => row.id.replace('pupil-row-', '')).filter(Boolean)

    event.target.disabled = true
    
    fetch(`/schools/classroom/program/${this.programIdValue}/quick-look/enroll`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify({ pupil_ids: enrolledPupilIds }),
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.text()
    })
    .then(html => {
      Turbo.renderStreamMessage(html)
    })
    .catch(error => {
      //data-driving-school-classroom-multi-enroll-target="error" shows the error message
      this.errorTarget.hidden = false
      console.error('Error:', error)
      this.saveButtonTarget.disabled = false
    })
  }


  connect() {
  }

  toggleEnrollment(event) {
    const checkbox = event.target
    const pupilRow = checkbox.closest('[data-driving-school-classroom-multi-enroll-target="pupilRow"]')
    
    if (!pupilRow) {
      console.error('Could not find pupil row', checkbox)
      return
    }

    const pupilId = pupilRow.id.replace('pupil-row-', '')
    
    let pupilName = 'Unknown'
    let pupilImage = 'person_default_page.jpg'  // default image path

    const nameElement = pupilRow.querySelector('.u-medium.u-block')
    if (nameElement) {
      pupilName = nameElement.textContent.trim()
    } else {
      console.warn(`Could not find name element for pupil ${pupilId}`)
    }

    const imageElement = pupilRow.querySelector('img')
    if (imageElement) {
      pupilImage = imageElement.src
    } else {
      console.warn(`Could not find image element for pupil ${pupilId}`)
    }
    
    if (checkbox.checked) {
      const attendeeHtml = this.createAttendeeHtml(pupilId, pupilName, pupilImage)
      this.enrolledListTarget.insertAdjacentHTML('beforeend', attendeeHtml)
      this.updateAttendanceCount(1)
    } else {
      const enrolledRow = this.enrolledListTarget.querySelector(`#pupil-row-${pupilId}`)
      if (enrolledRow) {
        enrolledRow.remove()
        this.updateAttendanceCount(-1)
      }
    }
  }


  createAttendeeHtml(pupilId, pupilName, pupilImage) {
    return `
      <div id="pupil-row-${pupilId}" class="">
        <div class="u-alignMiddle pb05">
          <span class="u-flex u-flexAlignCenter">
            <span class="pr1">
              <img src="${pupilImage}" width="24" class="media-object u-img u-circleImage" alt="${pupilName}">
            </span>
            <span class="u-alignMiddle">
              ${pupilName}
            </span>
          </span>
        </div>
      </div>
    `
  }

  updateAttendanceCount(change) {
    if (this.hasAttendanceCountTarget) {
      const currentCount = parseInt(this.attendanceCountTarget.textContent, 10)
      const newCount = currentCount + change
      this.attendanceCountTarget.textContent = newCount
    }
  }

  addCheckboxToRow(row, checkboxId, checkboxValue) {
    const label = document.createElement('label')
    label.className = 'Input--checkbox-label media m0'
    label.setAttribute('for', checkboxId)

    const input = document.createElement('input')
    input.type = 'checkbox'
    input.id = checkboxId
    input.name = 'driving_school_classroom_session_attendances[]'
    input.value = checkboxValue
    input.setAttribute('data-action', 'change->driving-school-classroom-multi-enroll#toggleEnrollment')
    input.setAttribute('data-driving-school-classroom-multi-enroll-target', 'checkbox')

    const span = document.createElement('span')
    span.className = 'Input--checkbox-custom'

    label.appendChild(input)
    label.appendChild(span)

    row.querySelector('.u-alignMiddle').appendChild(label)
  }
}