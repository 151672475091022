import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  onPostSuccess(event) {
    // console.log("why?");
    // console.error(event);
    // console.error(event.preventDefault());
    $('#message').val('');
    $('#send_message').prop('disabled', true)
  }

  beforeSend(event) {
    if (!$('#message').val()) {
      event.detail.formSubmission.stop();
    }
  }

  checkValidation(event) {
    // console.error($("#message").val())

    // console.error($('#toggle_lock_message')[0].attribute)
    // console.error($('#toggle_lock_message').data('lock'))


    if ($('#toggle_lock_message').data('lock')) {
      return;
    }

    if (!$('#message').val()) {
      $('#send_message').prop('disabled', true)
    } else {
      $('#send_message').prop('disabled', false)
    }

    // if ($('#send_message').prop('disabled')) {
    //   $('#send_message').prop('disabled', false)
    //   $('#toggle_lock_message').text('Lock')
    //   lock = false;
    // } else {
    //   $('#send_message').prop('disabled', true)
    //   $('#toggle_lock_message').text('Unlock')
    //   lock = true;
    // }
  }

  connect() {
    // console.error('HELLO?')
  }

  toggleLock(event) {
    event.preventDefault();

    var lockValue = $('#toggle_lock_message').data('lock');

    lockValue = !lockValue;

    $.ajax({
      url: "toggle-lock-message",
      type: 'POST',
      dataType: 'json',
      data: {
        lock: lockValue,
        message_group_id: event.target.dataset.messagegroupid
      },
      success: function(data, textStatus, jqXHR){
        console.error('Lock changed to ', lockValue);

        $('#toggle_lock_message').data('lock', lockValue);
        $('#send_message').prop('disabled', lockValue);

        if (lockValue) {
          $('#toggle_lock_message').text('Unlock')
        } else {
          $('#toggle_lock_message').text('Lock')
        }
      },
      error: function(jqXHR, textStatus, errorThrown){
      }
    });
  }

  update() {
    // Rails.fire(this.element, 'submit');
  }
}