import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "generateButton", 
    "form",
    "startDate", 
    "endDate", 
    "errorMessage",
    "successMessage",
    "typeOptions",
    "certificateSection"
  ];

  connect() {
    this.hasError = false;
    this.updateButtonState();
    this.toggleCertificateSection();
  }

  areDatesValid(startDate, endDate) {
    return startDate && endDate && startDate <= endDate;
  }

  updateButtonState() {
    const startDateValue = this.startDateTarget.value;
    const endDateValue = this.endDateTarget.value;
  
    if (this.areDatesValid(startDateValue, endDateValue)) {
      this.generateButtonTarget.disabled = false;
      this.setErrorState(false);
    } else {
      this.generateButtonTarget.disabled = true;
      if (startDateValue && endDateValue) {
        this.setErrorState(true);
      } else {
        this.setErrorState(false);
      }
    }
  }

  handleFormSubmit(event) {
    if (this.hasError) {
      event.preventDefault();
      return;
    }

    this.generateButtonTarget.disabled = true;
    this.showSuccessMessage();

    setTimeout(() => {
      this.generateButtonTarget.disabled = false;
      this.hideSuccessMessage();
    }, 5000);
  }

  setErrorState(hasError) {
    this.errorMessageTarget.hidden = !hasError;
    this.hasError = hasError;
  }

  showSuccessMessage() {
    this.successMessageTarget.hidden = false
  }

  hideSuccessMessage() {
    this.successMessageTarget.hidden = true
  }

  showErrorMessage() {
    this.setErrorState(true);
  }

  hideErrorMessage() {
    this.setErrorState(false);
  }

  toggleCertificateSection() {
    const isCertificateOption = this.typeOptionsTarget.value === 'certificate_of_completion';
    
    this.certificateSectionTarget.hidden = !isCertificateOption;
  }
}
