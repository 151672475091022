import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  async updateWeek(event, action) {
    event.preventDefault();
    const { startingDate, schoolToken, instructorId, domId } = event.currentTarget.dataset;
    await this.fetchAndUpdateCalendar(`enquiry_${action}_week`, startingDate, schoolToken, instructorId, domId);
  }
  
  async previousWeek(event) {
    await this.updateWeek(event, "previous");
  }
  
  async nextWeek(event) {
    await this.updateWeek(event, "next");
  }  

  async fetchAndUpdateCalendar(action, startingDate, schoolToken, instructorId, domId) {
    let action_word = 'previous_week'
    if (action.startsWith('enquiry_')) {
      action_word = action.substring('enquiry_'.length);
    } else {
      alert(action);
    }

    this.updateNavigationButtons(startingDate, action_word, instructorId);
    
    const response = await fetch(`/s/${schoolToken}/calendar/${instructorId}/${action_word}/${startingDate}`, {
      method: "GET",
      headers: {
        "Accept": "text/vnd.turbo-stream.html",
        "Content-Type": "application/x-www-form-urlencoded"
      }
    });

    if (response.ok) {
      const responseBody = await response.text();
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(responseBody, "text/html");
      const template = xmlDoc.querySelector("template");
      const targetElement = document.getElementById(domId);
      if (template && targetElement) {
        targetElement.innerHTML = "";
        targetElement.appendChild(template.content.cloneNode(true));
      }
    }
  }

  async updateCalendarDateWeekView(startingDate, instructorId){
    const targetElement = document.getElementById(`calendar-date-${instructorId}`);
    if (targetElement) {
      const formattedDate = `${startingDate.toLocaleString('default', { month: 'short' })} ${startingDate.getFullYear()}, Week ${this.getWeek(startingDate)}`;
      targetElement.innerHTML = formattedDate;
    }
  }

  async updateNavigationButtons(startingDate, action, instructorId) {
    startingDate = this.parseDateString(startingDate)

    const previousWeekButton = document.getElementById(`previous-week-button-${instructorId}`)
    const nextWeekButton = document.getElementById(`next-week-button-${instructorId}`)

    const previousWeekStartingDate = new Date(startingDate);
    const nextWeekStartingDate = new Date(startingDate);

    let setCalendarDate = (action == 'previous_week') ? startingDate.getDate() - 7 : startingDate.getDate() + 7;

    previousWeekStartingDate.setDate(setCalendarDate);
    nextWeekStartingDate.setDate(setCalendarDate);
    this.updateCalendarDateWeekView((action == 'previous_week') ? previousWeekStartingDate : nextWeekStartingDate, instructorId);

    previousWeekButton.dataset.startingDate = this.formatDateToString(previousWeekStartingDate)
    nextWeekButton.dataset.startingDate = this.formatDateToString(nextWeekStartingDate)
  }

  formatDateToString(date) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed, so add 1
    const day = date.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
  }

  parseDateString(dateString) {
    const cleanedDateString = dateString.replace(/"/g, ''); // Remove double quotes
    const [year, month, day] = cleanedDateString.split('-').map(Number);
    return new Date(year, month - 1, day); // Subtract 1 from the month since it's 0-indexed
  }

  getWeek(date) {
    date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    date.setUTCDate(date.getUTCDate() + 4 - (date.getUTCDay()||7));
    var yearStart = new Date(Date.UTC(date.getUTCFullYear(),0,1));
    var weekNo = Math.ceil(( ( (date - yearStart) / 86400000) + 1)/7);
    return weekNo;
  }
}
