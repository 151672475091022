window.get_captcha_token = function(action) {
  return new Promise(function(resolve, reject) {
    try {
      grecaptcha.ready(async function() {
        try {
          console.error('captcha ready');
          const token = await grecaptcha.execute(window._captcha_client_token, {action: action});
          console.error('token for action', action);
          resolve(token);
        } catch (e) {
          reject(e);
        }

      })

    } catch (e) {
      console.error(e);
      reject(e);
    }
  });
};

window.ajaxRecaptcha = async function(action, request) {
  if (window._skip_recaptcha) {
    console.log('Skipping recaptcha');
    return $.ajax(request)
  }

  console.log('Checking for recaptcha token');

  try {
      const token = await window.get_captcha_token(action);
      if (request['data']) {
        request['data']['recaptch_token'] = token;
      }
      return $.ajax(request);
  } catch (e) {
    // Submit request anyway and catch in API
    return $.ajax(request);
  }
}

window.callbackForToken = function(token, e) {
  try {
    e.submit(); 
    return;   
  } catch(f) {
    console.error(f);
  }

  try {
    e.form.submit();
    return;
  } catch (g) {
    console.error(g);
  }
}

window.submitFormWithRecaptcha = function(e, action) {
  window.get_captcha_token()
  .then((token) => {
    console.error(token);
    console.error(action)
    console.error(e.form);
    // e.form.submit();
  }).catch((error) => {
    console.error(error);
  });

  return false;
}