// app/javascript/controllers/form_updater_controller.js
import { Controller } from "stimulus"

export default class extends Controller {
 
  static targets = ["typeOfClass"]

  connect() {
    this.update()
  }

  update() {
    const typeId = this.typeOfClassTarget.value
    const url = `/schools/setup/classroom/update-form/${typeId}`

    fetch(url, {
      headers: {
        "Accept": "text/vnd.turbo-stream.html"
      }
    })
    .then(response => response.text())
    .then(html => {
      Turbo.renderStreamMessage(html)
    })
  }

}
