import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    // find calendar-full-screen-link element
    const calendarFullScreenLink = document.querySelector("#calendar-full-screen-link");

    // if the element contains  data-turbo="false" at all then return
    if (calendarFullScreenLink != undefined && calendarFullScreenLink.outerHTML.includes('data-turbo="false"')) {
      return;
    }

    if (calendarFullScreenLink == undefined) {
      return;
    }

    if (window.location.pathname === "/schools/schedule") {
      calendarFullScreenLink.insertAdjacentHTML("afterbegin", '<a data-turbo="false" class="u-linkClean  u-linkSubtle" href="/schools"><span class="icon  icon-shrink2  u-textGrey  u-textTiny  ml05  u-border  u-borderRadius  p05" style="position:relative; top:-2px;"></span></a></span>');
    } else {
      calendarFullScreenLink.insertAdjacentHTML("afterbegin", '<a data-turbo="false" class="u-linkClean  u-linkSubtle" href="/schools/schedule"><span class="icon  icon-enlarge2  u-textGrey  u-textTiny  ml05  u-border  u-borderRadius  p05" style="position:relative; top:-2px;"></span></a></span>');
    }
  }

  async updateDay(event, action) {
    event.preventDefault();
    let { startingDate, schoolToken, domId } = event.currentTarget.dataset;
    if (startingDate == undefined) {
      const calendarDateElement = document.querySelector("#calendar-date");
      startingDate = calendarDateElement.textContent.trim();
    }

    // find calendar-month-format and if it is present
    const calendarMonthFormat = document.querySelector("#calendar-month-format");
    if (calendarMonthFormat != undefined && startingDate == undefined) {
      // get todays date in the format of 1 August 2023
      const todaysDate = new Date();
      startingDate = `${todaysDate.getDate()} ${todaysDate.toLocaleString('default', { month: 'long' })} ${todaysDate.getFullYear()}`;
    }

    await this.fetchAndUpdateCalendar(`enquiry_${action}_day`, startingDate, schoolToken, domId);
  }

  async today(event) {
    await this.updateDay(event, "today");
  }

  async nextDay(event) {
    await this.updateDay(event, "next");
  }

  async previousDay(event) {
    await this.updateDay(event, "previous");
  }

  async updateZone(event) {
    await this.updateDay(event, "zone");
  }

  async updateStyle(event) {
    await this.updateDay(event, "style");
  }

  async updateDate(event) {
    event.preventDefault();
    const newDate = document.getElementById('new-calendar-date').value;

    // Split the date string and reformat it
    const [year, month, day] = newDate.split('-');
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const formattedDate = `${day} ${monthNames[parseInt(month) - 1]} ${year}`;

    // find the day of the week and replace calendar-day-word with it
    const date = new Date(newDate);
    const daysOfWeek = [
      "Sun,", "Mon,", "Tue,", "Wed,",
      "Thu,", "Fri,", "Sat,"
    ];
    const dayOfWeek = daysOfWeek[date.getDay()];
    document.getElementById('calendar-day-word').innerText = dayOfWeek;

    document.getElementById('calendar-date').innerText = formattedDate;

    this.cancelEdit();
    await this.updateDay(event, "date");
}

  cancelEdit() {
    document.getElementById('edit-date').style.display = 'none';
    document.getElementById('calendar-full-date').style.display = 'block';
  }

  async fetchAndUpdateCalendar(action, startingDate, schoolToken, domId) {
    let action_word = ''

    if (action.startsWith('enquiry_')) {
      action_word = action.substring('enquiry_'.length);
    } else {
      alert(action);
    }

    const selected_zone = document.querySelector("#zone-select")
    let selected_zone_id = undefined
    if (selected_zone) {
      selected_zone_id = selected_zone.value;
    }

    let style_to_fetch = 1
    const selected_style = document.querySelector("#style-select")
    if (selected_style) {
      style_to_fetch = selected_style.value;
    }

    const response = await fetch(`/schools/dashboard/calendar/daily/${schoolToken}/calendar/${style_to_fetch}/zone/${selected_zone_id}/action/${action_word}/${startingDate}`, {
      method: "GET",
      headers: {
        "Accept": "text/vnd.turbo-stream.html",
        "Content-Type": "application/x-www-form-urlencoded"
      }
    });

    if (response.ok) {

      const responseBody = await response.text();
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(responseBody, "text/html");
      const template = xmlDoc.querySelector("template");
      const targetElement = document.getElementById(domId);
      if (template && targetElement) {
        targetElement.innerHTML = "";
        targetElement.appendChild(template.content.cloneNode(true));
      }
    }
  }

  async updateCalendarDateDayView(startingDate, instructorId){
    const targetElement = document.getElementById(`calendar-date-${instructorId}`);
    if (targetElement) {
      const formattedDate = `${startingDate.toLocaleString('default', { month: 'short' })} ${startingDate.getFullYear()}, Day ${this.getDay(startingDate)}`;
      targetElement.innerHTML = formattedDate;
    }
  }

  async updateNavigationButtons(startingDate, action, instructorId) {
    startingDate = this.parseDateString(startingDate)

    const previousDayButton = document.getElementById(`previous-day-button-${instructorId}`)
    const nextDayButton = document.getElementById(`next-day-button-${instructorId}`)

    const previousDayStartingDate = new Date(startingDate);
    const nextDayStartingDate = new Date(startingDate);

    let setCalendarDate = (action == 'previous_day') ? startingDate.getDate() - 7 : startingDate.getDate() + 7;

    previousDayStartingDate.setDate(setCalendarDate);
    nextDayStartingDate.setDate(setCalendarDate);
    this.updateCalendarDateDayView((action == 'previous_day') ? previousDayStartingDate : nextDayStartingDate, instructorId);

    previousDayButton.dataset.startingDate = this.formatDateToString(previousDayStartingDate)
    nextDayButton.dataset.startingDate = this.formatDateToString(nextDayStartingDate)
  }

  formatDateToString(date) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed, so add 1
    const day = date.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
  }

  parseDateString(dateString) {
    const cleanedDateString = dateString.replace(/"/g, ''); // Remove double quotes
    const [year, month, day] = cleanedDateString.split('-').map(Number);
    return new Date(year, month - 1, day); // Subtract 1 from the month since it's 0-indexed
  }

  getDay(date) {
    date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    date.setUTCDate(date.getUTCDate() + 4 - (date.getUTCDay()||7));
    var yearStart = new Date(Date.UTC(date.getUTCFullYear(),0,1));
    var weekNo = Math.ceil(( ( (date - yearStart) / 86400000) + 1)/7);
    return weekNo;
  }
}
