import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  addNewSlot() {
    fetch("/driving_school_rules/add_new_slot", {
      method: "GET",
      headers: {
        "X-Requested-With": "XMLHttpRequest"
      }
    })
    .then(response => response.text())
    .then(html => {
      const newSlotDiv = document.getElementById("new_slots");
      newSlotDiv.innerHTML += html;
    });
  }
}
