import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {}

  async goToDate(event) {
    event.preventDefault();
    let { newDate, schoolToken, domId } = event.currentTarget.dataset;

    const response = await fetch(
      `/schools/dashboard/calendar/daily/${schoolToken}/calendar/99/zone//action/test/${newDate}`,
      {
        method: "GET",
        headers: {
          Accept: "text/vnd.turbo-stream.html",
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );

    if (response.ok) {
      const responseBody = await response.text();
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(responseBody, "text/html");
      const template = xmlDoc.querySelector("template");
      const targetElement = document.getElementById(domId);
      if (template && targetElement) {
        targetElement.innerHTML = "";
        targetElement.appendChild(template.content.cloneNode(true));
      }
    }
  }

  formatDateToString(date) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-indexed, so add 1
    const day = date.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  parseDateString(dateString) {
    const cleanedDateString = dateString.replace(/"/g, ""); // Remove double quotes
    const [year, month, day] = cleanedDateString.split("-").map(Number);
    return new Date(year, month - 1, day); // Subtract 1 from the month since it's 0-indexed
  }

  getDay(date) {
    date = new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
    );
    date.setUTCDate(date.getUTCDate() + 4 - (date.getUTCDay() || 7));
    var yearStart = new Date(Date.UTC(date.getUTCFullYear(), 0, 1));
    var weekNo = Math.ceil(((date - yearStart) / 86400000 + 1) / 7);
    return weekNo;
  }
}
