import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["dropdown", "disableNewStudents", "disableCurrentStudents"];

  connect() {
    this.initializeDropdown();
  }

  initializeDropdown() {
    const slotId = this.dropdownTarget.dataset.studentDropdownSlotId;
    const disableNewStudentsField = this.disableNewStudentsTargets.find(target =>
       target.dataset.slotId === slotId);
    const disableCurrentStudentsField = this.disableCurrentStudentsTargets.find(target => 
      target.dataset.slotId === slotId);

    if (disableNewStudentsField && disableCurrentStudentsField) {
      if (disableNewStudentsField.value === "false" && disableCurrentStudentsField.value === "false") {
        this.dropdownTarget.value = "all_students";
      } else if (disableNewStudentsField.value === "true" && disableCurrentStudentsField.value === "false") {
        this.dropdownTarget.value = "only_current_students";
      } else if (disableNewStudentsField.value === "false" && disableCurrentStudentsField.value === "true") {
        this.dropdownTarget.value = "only_new_students";
      }
    }
  }

  updateHiddenFields(event) {
    const dropdown = event ? event.target : this.dropdownTarget;
    const slotId = dropdown.dataset.studentDropdownSlotId;
    const disableNewStudentsField = slotId
    ? this.disableNewStudentsTargets.find(target => target.id === `slot_${slotId}_disable_new_students`)
    : document.getElementById("new_slot_disable_new_students");
    const disableCurrentStudentsField = slotId
    ? this.disableCurrentStudentsTargets.find(target => target.id === `slot_${slotId}_disable_current_students`)
    : document.getElementById("new_slot_disable_current_students");

    if (disableNewStudentsField && disableCurrentStudentsField) {
      switch (dropdown.value) {
        case "all_students":
          disableNewStudentsField.value = false;
          disableCurrentStudentsField.value = false;
          break;
        case "only_current_students":
          disableNewStudentsField.value = true;
          disableCurrentStudentsField.value = false;
          break;
        case "only_new_students":
          disableNewStudentsField.value = false;
          disableCurrentStudentsField.value = true;
          break;
      }
    }
  }
}
