import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    const resourceTypeElement = document.getElementById("resource_type");
    if (resourceTypeElement) {
      this.showResourceForm(resourceTypeElement.value);
    }
  }

  showResourceForm(event) {
    let resourceType;

    if (typeof event === 'string') {
      resourceType = event;
    } else {
      resourceType = event.target.value;
    }
    
    this.hideAllSections();

    const resourceTypes = {
      "Document": "resource-document",
      "Image": "resource-image",
      "Link": "link-resource-url",
      "Video": "video-resource-url"
    };

    const elementId = resourceTypes[resourceType];
    if (elementId) {
      this.toggleElementVisibility(elementId, true);
      const hiddenField = document.getElementById("resource_type");
      if (hiddenField) hiddenField.value = resourceType;
    }
  }

  async showQuickLook(event) {
    event.preventDefault();

    const type = event.currentTarget.dataset.type;
    const id = event.currentTarget.dataset.id || "";

    const url = id ? `/schools/resources/quick-look/${type}/${id}` : `/schools/resources/quick-look/${type}/add`;
    
    const response = await this.performFetch(url, "GET");

    if (response.ok) {
      const quickViewElement = document.querySelector("#school-resources-quick-look");
      quickViewElement.classList.remove("u-hidden");

      const responseBody = await response.text();
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(responseBody, "text/html");
      const template = xmlDoc.querySelector("template");
      const turboFrameElement = quickViewElement;

      if (turboFrameElement && template) {
        turboFrameElement.innerHTML = "";
        turboFrameElement.appendChild(template.content.cloneNode(true));
      }
    }
  }

  async performFetch(url, method, body = null) {
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
    const headers = {
      "X-CSRF-Token": csrfToken,
      "Accept": "text/vnd.turbo-stream.html"
    };
  
    const options = {
      method: method,
      headers: headers
    };    

    if (body) {
      options.body = body;
    }

    try {
      return await fetch(url, options);
    } catch (error) {
      console.error("Error performing fetch: ", error);
      return { error: "Network error, please check your connection and try again." };
    }
  }

  hideAllSections() {
    ["link-resource-url", "video-resource-url", "resource-document", "resource-image"].forEach(id => {
      this.toggleElementVisibility(id, false);
    });
  }

  toggleElementVisibility(id, visible) {
    const element = document.getElementById(id);
    if (element) {
      element.hidden = !visible;
    }
  }

  async validate(event) {
    event.preventDefault();

    this.toggleLoading(true);

    const errors = this.validateForm();

    if (errors.length > 0) {
      this.toggleLoading(false);
      this.showErrorPanel(errors.join("<br>"));
      return;
    }

    const form = event.target.closest("form");
    const formData = new FormData(form);

    const response = await this.performFetch(form.action, form.method, formData);

    if (response.ok) {
      const stream = await response.text();
      Turbo.renderStreamMessage(stream);
    } else if (response.error) {
      this.showErrorPanel(response.error);
    } else {
      console.error("Failed to submit the form: ", response.statusText);

      try {
        const responseBody = await response.json();
        if (responseBody.errors && responseBody.errors.length) {
          console.error("Category errors: ", responseBody.errors);
          this.showErrorPanel(responseBody.errors.join("<br>"));
        }
        else if (responseBody.error) {
          console.error("Category error: ", responseBody);
          this.showErrorPanel(responseBody.error);
        }
      } catch (error) {
        console.error("Error parsing response: ", error);
        this.showErrorPanel("Something has gone wrong. Please try again later.");
      }
    }

    this.toggleLoading(false);
  }

  validateForm() {
    let validationErrors = [];

    let requiredFields = [{ id: "name", name: "Title" }];

    const resourceTypeElement = document.getElementById("resource_type");

    if (resourceTypeElement) {
      const resourceType = resourceTypeElement.value;

      switch(resourceType) {
        case "Document":
          const existingDocument = document.getElementById("existing-document");
          if (!existingDocument) {
            requiredFields.push({ id: "resource-file-document", name: "Document", checkPresence: true });
          }
          requiredFields.push({ id: "resource-file-document", name: "Document", checkSize: true });
          break;
        case "Image":
          const existingImage = document.getElementById("existing-image");
          if (!existingImage) {
            requiredFields.push({ id: "resource-file-image", name: "Image", checkPresence: true });
          }
          requiredFields.push({ id: "resource-file-image", name: "Image", checkSize: true });
          break;
        case "Link":
          requiredFields.push({ id: "resource_link_url", name: "Link" });
          break;
        case "Video":
          requiredFields.push({ id: "resource_video_url", name: "Link" });
          break;
      }
    }

    requiredFields.forEach(field_data => {
      let field = document.getElementById(field_data.id);
      const MAX_FILE_SIZE = 4 * 1024 * 1024;
      if (field) {
        if (field.type != "file" && field.value.trim() === "") {
          validationErrors.push(`${field_data.name} cannot be empty.`);
        } else if (field.type === "file") {
          if (field_data.checkPresence && field.files.length === 0) {
            validationErrors.push(`${field_data.name} must be included.`);
          }
          if (field_data.checkSize && field.files[0] && field.files[0].size > MAX_FILE_SIZE) {
            validationErrors.push(`${field.files[0].name} is too large. Maximum file size is 4MB.`);
          }
        }
      }
    });

    return validationErrors;
  }

  async confirmDelete(event) {
    event.preventDefault();

    const id = event.currentTarget.dataset.id;
    const type = event.currentTarget.dataset.type;
    const url = `/schools/resources/quick-look/${type}/${id}/delete`;

    const response = await this.performFetch(url, "PATCH");

    if (response.ok) {
      const stream = await response.text();
      Turbo.renderStreamMessage(stream);
    } else if (response.error) {
      this.showErrorPanel(response.error);
    } else {
      console.error(`Failed to archive the ${type}: `, response.statusText);
      this.showErrorPanel(`Failed to delete the ${type}. Please try again later.`);
    }
  }

  showDeletePanel(event) {
    event.preventDefault();

    const deletePanel = document.getElementById("delete-confirmation-panel");
    deletePanel.removeAttribute("hidden");
  }

  hideDeletePanel(event) {
    event.preventDefault();
    
    const deletePanel = document.getElementById("delete-confirmation-panel");
    deletePanel.setAttribute("hidden", true);
  }

  showErrorPanel(message) {
    const errorPanel = document.getElementById("error-panel");
    const errorMessage = document.getElementById("error-message");
  
    errorMessage.innerHTML = message;
    errorPanel.hidden = false;
    errorMessage.hidden = false;
  } 

  toggleLoading(show) {
    const formFields = document.getElementById("form-content");
    const loader = document.getElementById("loader");

    if (show) {
        const formFieldsHeight = formFields.offsetHeight;
        loader.style.height = `${formFieldsHeight}px`;
        loader.style.display = "flex";
        formFields.classList.add("hidden");
    } else {
        loader.style.display = "none";
        formFields.classList.remove("hidden");
    }

    this.toggleFormButtons(show);
  }

  toggleFormButtons(disable) {
    const buttons = document.querySelectorAll("form button");
    buttons.forEach(button => {
      button.disabled = disable
      button.classList.toggle("Button--disabled", disable);
    });
  }

  closeQuickLook(event) {
    event.preventDefault();
    const quickViewElement = document.querySelector("#school-resources-quick-look");
    quickViewElement.classList.add("u-hidden");
  }
}