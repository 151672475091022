import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  // static targets = ["packagePanel", "packageRadio", "selectedPackageId", "otherPackages", "toggleOtherPackages"]

  // connect() {
  //   this.updateSelectedPackage()
  // }

  static targets = [
    "packagePanel", 
    "packageRadio", 
    "selectedPackageId", 
    "packageSection", 
    "otherPackages",
    "toggleOtherPackages",
    "perks",
    "togglePerks"
  ]

  connect() {
    this.initializeSelectedPackage()
    // if there is no initial selected package
    // show the other packages section
    if (!this.selectedPackageIdTarget.value) {
      this.toggleOtherPackages({ preventDefault: () => {} })
      // hide toggleOtherPackages button
      this.toggleOtherPackagesTarget.classList.add("u-hidden")
      // select first package
      this.selectPackageById(this.packagePanelTargets[0].dataset.packageId)
    }
  }

  initializeSelectedPackage() {
    const initialSelectedPackageId = this.packageSectionTarget.dataset.initialSelectedPackageId
    if (initialSelectedPackageId) {
      this.selectPackageById(initialSelectedPackageId)
    } else {
      this.updateSelectedPackage()
    }
  }

  selectPackage(event) {
    const clickedPanel = event.currentTarget
    const packageId = clickedPanel.dataset.packageId
    this.selectPackageById(packageId)
  }

  selectPackageById(packageId) {
    this.packagePanelTargets.forEach(panel => {
      const radio = panel.querySelector('input[type="radio"]')
      const isSelected = panel.dataset.packageId === packageId

      if (isSelected) {
        panel.classList.add("Panel--success")
        panel.classList.remove("Panel--subtle")
        panel.classList.remove("Panel--white")
        radio.checked = true
        this.selectedPackageIdTarget.value = packageId
      } else {
        panel.classList.remove("Panel--success")
        panel.classList.add("Panel--subtle")
        panel.classList.add("Panel--white")
        radio.checked = false
      }
    })
  }

  updateSelectedPackage() {
    const selectedRadio = this.packageRadioTargets.find(radio => radio.checked)
    if (selectedRadio) {
      this.selectPackageById(selectedRadio.value)
    }
  }

  toggleOtherPackages(event) {
    event.preventDefault()
    this.otherPackagesTarget.classList.toggle("u-hidden")
    // switch toggleOtherPackages text
    const toggleText = this.toggleOtherPackagesTarget.innerText

    //check text contains "Show other packages"

    this.toggleOtherPackagesTarget.innerText = toggleText.includes("Show other packages") ? "Hide other packages" : "Show other packages"
  }
  
  togglePerks(event) {
    event.preventDefault()
    const packageId = event.currentTarget.dataset.packageId
    const perksTarget = this.perksTargets.find(target => target.dataset.packageId === packageId)
    const toggleTarget = this.togglePerksTargets.find(target => target.dataset.packageId === packageId)

    if (!perksTarget || !toggleTarget) {
      console.error(`Targets not found for package ID: ${packageId}`)
      return
    }

    const toggleText = toggleTarget.innerText
    toggleTarget.innerText = toggleText.includes("See all perks") ? "Hide all perks" : "See all perks"

    perksTarget.classList.toggle("u-hidden")
    console.log("Toggled perks for package ID:", packageId)
  }
  // selectPackage(event) {
  //   const clickedPanel = event.currentTarget
  //   const radioButton = clickedPanel.querySelector('input[type="radio"]')
  //   radioButton.checked = true
  //   this.selectedPackageIdTarget.value = clickedPanel.dataset.packageId
  //   this.updateSelectedPackage()
  // }

  // updateSelectedPackage() {
  //   this.packagePanelTargets.forEach((panel, index) => {
  //     const radio = this.packageRadioTargets[index]
  //     const isSelected = panel.dataset.selected === "true" || radio.checked

  //     if (isSelected) {
  //       panel.classList.add("Panel--success")
  //       panel.classList.remove("Panel--subtle")
  //       this.selectedPackageIdTarget.value = panel.dataset.packageId
  //       radio.checked = true
  //     } else {
  //       panel.classList.remove("Panel--success")
  //       panel.classList.add("Panel--subtle")
  //     }
  //   })
  // }
}