import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "tab",
    "pickedInstructorToken",
    "radio",
    "pupilId",
  ];

  connect() {

  }


  // submit(event) {
  //   event.preventDefault();
  //   const selectedProgram = this.getSelectedProgram();
  //   const pupilId = this.getPupilId();
    
  //   if (selectedProgram && pupilId) {
  //     this.postForm(selectedProgram, pupilId);
  //   } else {
  //       console.error('No program selected');
  //   }
  // }


  async showQuickLook(event) {
    event.preventDefault();

    const pupilToken = this.data.get("pupilId");
    const quickViewElement = document.querySelector("#school-dashboard-lesson-quick-look");
    quickViewElement.classList.remove("u-hidden");
    
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
  
    const response = await fetch(`/schools/pupils/${pupilToken}/adjust-balance/quick-look`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "X-CSRF-Token": csrfToken,
        "Accept": "text/vnd.turbo-stream.html"
      }
    });

    if (response.ok) {

      const responseBody = await response.text();
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(responseBody, "text/html");
      const template = xmlDoc.querySelector("template");
      const targetElement = document.getElementById("school_lesson_quick_look");

      if (template && targetElement) {
        targetElement.innerHTML = "";
        targetElement.appendChild(template.content.cloneNode(true));
      }
    }
  }

  toggleErrorPanel(show, errorMessage) {
    const errorSection = document.getElementById("error-section");
    const errorList = document.getElementById("errors-list");
  
    if (show) {
      errorSection.classList.remove("u-hidden");
      errorList.textContent = errorMessage || "An error occurred";
    } else {
      errorSection.classList.add("u-hidden");
      errorList.textContent = "";
    }
  }

  scrollToTop() {
    const quicklook = document.getElementById("balance-quicklook");
    
    if (quicklook) {
      quicklook.scrollTop = 0;
    }
  }
}