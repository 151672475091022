import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "refundButton",
    "refundTypeFull",
    "refundTypePartial",
    "refundAmountContainer",
    "refundAmount",
    "errors",
    "cancelUpcomingLessons",
    "refundReason",
  ];

  connect() {
    console.log("SchoolPaymentRefundQuickLookController connected");
    setTimeout(() => {
      this.validateForm();
    });
  }

  disconnect() {
    console.log("SchoolPaymentRefundQuickLookController disconnected");
  }

  async showQuickLook(event) {
    event.preventDefault();

    const paymentToken = this.data.get("paymentTokenValue");
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;

    const response = await fetch(
      `/schools/payments/${paymentToken}/quick-look/refund`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "X-CSRF-Token": csrfToken,
          Accept: "text/vnd.turbo-stream.html",
        },
      }
    );

    if (response.ok) {
      console.log("response.ok");
      console.log(response);

      const quickViewElement = document.querySelector(
        "#school-payment-quick-look"
      );
      quickViewElement.classList.remove("u-hidden");

      const responseBody = await response.text();
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(responseBody, "text/html");

      quickViewElement.innerHTML = "";
      quickViewElement.appendChild(xmlDoc.body);
    } else {
      alert(
        "There was an error while trying to load the quick look, please try again later."
      );
    }
  }

  closeQuickLook(event) {
    event.preventDefault();
    const quickViewElement = document.querySelector(
      "#school-payment-quick-look"
    );
    quickViewElement.classList.add("u-hidden");
  }

  async confirmRefund(event) {
    this.beginButtonLoading();

    const paymentToken = this.data.get("paymentTokenValue");
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
    const response = await fetch(
      `/schools/payments/${paymentToken}/quick-look/refund`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken,
          Accept: "application/json",
        },
        body: JSON.stringify({
          refundType: this.refundTypeFullTarget.checked ? "full" : "partial",
          refundAmount: this.refundAmountTarget.value,
          cancelUpcomingLessons: this.cancelUpcomingLessonsTarget.checked,
          refundReason: this.refundReasonTarget.value,
        }),
      }
    );

    if (response.ok) {
      const jsonResponse = await response.json();
      // Refresh page
      window.location.reload();
    } else {
      alert(
        "There was an error while trying to load the quick look, please try again later."
      );
    }

    this.closeQuickLook(event);
  }

  validateForm() {
    const validate = this.data.get("validate");
    if (!validate) {
      return;
    }

    const refundTypeFull = this.refundTypeFullTarget.checked;
    const refundTypePartial = this.refundTypePartialTarget.checked;
    const priceInPence = this.data.get("priceInPence");
    const refundAmount = this.refundAmountTarget.value;
    const cancelUpcomingLessons = this.cancelUpcomingLessonsTarget.checked;

    console.log(
      JSON.stringify(
        {
          refundTypeFull,
          refundTypePartial,
          priceInPence,
          refundAmount,
          cancelUpcomingLessons,
        },
        null,
        2
      )
    );

    const errors = [];

    // Check the refund amount is a vlid number, with no decimal places or 2 decimal places
    if (
      refundTypePartial &&
      !(refundAmount.match(/^\d+$/) || refundAmount.match(/^\d+\.\d{2}$/))
    ) {
      errors.push("Refund amount cannot be 0 or a decimal with more than 2 numbers.");
    }

    if (refundTypePartial && refundAmount * 100 >= priceInPence) {
      errors.push("Refund amount must be less than the total paid");
    }

    if (errors.length === 0) {
      this.errorsTarget.hidden = true;
      this.errorsTarget.innerHTML = "";
      this.refundButtonTarget.disabled = false;
    } else {
      this.errorsTarget.hidden = false;
      this.errorsTarget.innerHTML = errors.join("<br>");
      this.refundButtonTarget.disabled = true;
    }

    this.enableRefundAmountIfApplicable();
  }

  enableRefundAmountIfApplicable() {
    if (this.refundTypePartialTarget.checked) {
      this.refundAmountContainerTarget.hidden = false;
      this.refundAmountTarget.disabled = false;
    } else {
      this.refundAmountContainerTarget.hidden = true;
      this.refundAmountTarget.disabled = true;
    }
  }

  beginButtonLoading() {
    let button = this.refundButtonTarget;
    let spinner = button.querySelector(".Spinner");
    spinner.classList.remove("u-hidden");
    spinner.classList.add("u-inlineBlock");
    button.disabled = true;
  }

  endButtonLoading() {
    let button = this.refundButtonTarget;
    let spinner = button.querySelector(".Spinner");
    spinner.classList.add("u-hidden");
    spinner.classList.remove("u-inlineBlock");
    button.disabled = false;
  }

  alert() {
    alert("Hello from the controller");
  }
}
