

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  static targets = [];

  connect() {
    
  }

  completedForm(event) {

    this.element.dispatchEvent(new CustomEvent('fullyComplete', {
      detail: { formName: 'driving-school-admin-booking-form-v2-payment-details',
      lessonDetails: {} }
    }));

  }


}