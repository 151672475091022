import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
  }

  submit(event) {
    event.preventDefault();

    const errorPanel = document.getElementById("errors-panel");
    errorPanel.classList.add("u-hidden");

    // validate duration_in_minutes_value price_in_pence_value start_date and start_time are not empty
    // check duration_in_minutes_value is above 30 and check price_in_pence_value is above 5
    const durationInMinutesValue = document.getElementById('student_lesson_duration_in_minutes').value;
    const priceInPenceValue = document.getElementById('price_in_pence_value').value;
    const startDateValue = document.getElementById('instructor_offer_date').value;
    const startTimeValue = document.getElementById('instructor_offer_date').value;

    const errors = [];
    if (durationInMinutesValue === '' || priceInPenceValue === '' || startDateValue === '' || startTimeValue === '') {
      errors.push('All fields are required');
    }
    if (durationInMinutesValue < 30) {
      errors.push('Duration in minutes must be above 30');
    }
    if (priceInPenceValue < 5) {
      errors.push('Price in pence must be above 5');
    }
    // date must be today or in the future
    //convert start date to date object
    const startDateValueDateObject = new Date(startDateValue);
    if (startDateValueDateObject < new Date().toISOString().split('T')[0]) {
      errors.push('Date must be today or in the future');
    }

    if (errors.length > 0) {
      
      const errorPanel = document.getElementById("errors-panel");
      errorPanel.classList.remove("u-hidden");

      const errorList = document.getElementById("errors-list");
      errorList.innerHTML = "";
      errors.forEach(error => {
        const li = document.createElement("li");
        li.innerText = error;
        errorList.appendChild(li);
      });
      return
    } else {
      // submit form
      const form = document.getElementById('new_student_lesson');
      form.submit();
    }

  }

}
