import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "eventDetail" ]

  connect() {
  }

  openEventDetails(event) {
    event.preventDefault()
    let eventId = this.eventDetailTarget.dataset.schoolTeamEventEventId
    const schoolNavigationToken = document.getElementById("school-navigation-token").innerHTML.trim()

    fetch(`/schools/team/${schoolNavigationToken}/event/${eventId}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.text();
    })
    .then(data => {
      document.body.innerHTML = data;
    })
    .catch(error => console.error('There was an error!', error));
  }
}