// app/javascript/controllers/sequential_loader_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["frame"];
  currentIndex = 0;  // Initialize index to zero
  loading = false;   // Flag to check if currently loading a frame

  connect() {
    this.loadNextFrame();  // Start loading the first frame
  }

  loadNextFrame() {
    // Check if already loading a frame or no more frames to load
    if (this.loading || this.currentIndex >= this.frameTargets.length) return;

    this.loading = true;  // Set loading flag to true
    const frame = this.frameTargets[this.currentIndex];
    console.log("Loading frame " + this.currentIndex);

    if (!frame.src) {  // Ensure we load the frame only if it hasn't been loaded yet
      frame.src = frame.dataset.srcData;  // Set the 'src' from our custom data attribute
    }

    // Listener to detect when the frame has loaded
    frame.addEventListener('turbo:frame-load', () => {
      console.log("Frame loaded: ", this.currentIndex);
      this.currentIndex++;  // Increment the index
      this.loading = false;  // Reset loading flag
      this.loadNextFrame();  // Call this method again to load the next frame
    }, { once: true });
  }
}
