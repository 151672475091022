import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["link", "formContainer"];

  connect() {
    this.checkInstructorsAndLoadForm();
  }

  checkInstructorsAndLoadForm() {
    const instructors = this.linkTargets;
    if (instructors.length === 1) {
      const profileId = instructors[0].dataset.profileId;
      this.loadFormForInstructor(profileId);
    }
  }

  async loadFormForInstructor(profileId) {
    const url = `/schools/setup/instructors/form/${profileId}`;

    try {
      const response = await fetch(url);
      const html = await response.text();

      this.formContainerTarget.innerHTML = html;
    } catch (error) {
      console.error("Error fetching form:", error);
    }
  }


  async loadForm(event) {
    event.preventDefault();

    const profileId = event.currentTarget.dataset.profileId;
    
    this.loadFormForInstructor(profileId);
  }
}