import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input"];

  updateTime() {

    const element = this.element;
    const packageId = element.getAttribute("data-driving-school-import-package-time-package-id-value");
    const pupilId = element.getAttribute("data-driving-school-import-package-time-pupil-id-value");

    const inputValue = this.inputTarget.value;

    // Perform the fetch request to update the server.
    fetch(`/schools/pupils/${pupilId}/package-update/${packageId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector("meta[name='csrf-token']").getAttribute("content")
      },
      body: JSON.stringify({ duration_in_minutes: inputValue })
    })
    .then(response => {
      if (!response.ok) {
          return response.json().then(err => {
              throw new Error(err.error);
          });
      }
      return response.json();
  })
  .then(data => {
      window.location.reload();
  })
  .catch(error => {
      // Handle errors here
      console.error("There was a problem with the operation:", error.message);
      // Display the error message from the server
      alert(error.message);
  });
  }
}
