import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["packageOption"];

  assignPackage(event, payLater = false) {
    event.currentTarget.disabled = true;

    const selectedPackageElement = this.packageOptionTargets.find(
      (element) => element.querySelector('input[type="radio"]').checked
    );
    const packageId = selectedPackageElement.dataset.packageId;
    const schoolsNavigationToken = document.querySelector("#school_navigation_token").value;

    let request_url = `/schools/pupils/${schoolsNavigationToken}/actions/assign-package/${packageId}`;
    if (payLater) {
      request_url += "?pay_later=true";
    }

    const debug = new URLSearchParams(window.location.search).get("debug");
    if (debug) {
      request_url += (request_url.includes('?') ? '&' : '?') + `debug=${debug}`;
    }

    const success_url = `/schools/pupils/${schoolsNavigationToken}/actions?package_assigned=true`;

    this.sendRequest(request_url, success_url);
  }

  payLaterForPackage(event) {
    this.assignPackage(event, true);
  }

  sendRequest(request_url, success_url) {
    fetch(request_url, {
      method: "POST",
      headers: {
        "X-CSRF-Token": this.getMetaValue("csrf-token"),
        Accept: "text/html",
      },
      credentials: "same-origin",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.text();
      })
      .then((html) => {
        window.location.href = success_url;
      })
      .catch((error) => {
        console.error('Error:', error);
        // remove the packageId from the URL
        let redirect_url = window.location.href.split("?")[0];
        window.location.href = redirect_url + "?error=true";
      });
  }


  getMetaValue(name) {
    return document.querySelector(`meta[name="${name}"]`).getAttribute("content");
  }

  getMetaValue(name) {
    const element = document.head.querySelector(`meta[name="${name}"]`);
    return element.getAttribute("content");
  }
}
