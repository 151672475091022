import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  
  showPackages() {
    // make sure either option-1 or option-2 or option-3 is selected
    const option1 = document.getElementById("option-1");
    const option2 = document.getElementById("option-2");
    const option3 = document.getElementById("option-3");

    if (option1.checked || option2.checked || option3.checked) {
      const part1 = document.getElementById("student-select-date-part-1");
      const part3 = document.getElementById("student-select-date-part-3");
    
      part1.classList.add("u-hidden");
      part3.classList.remove("u-hidden");
      window.scrollTo(0, 0);
    } else {
      alert("Please select one of the options");
    }
  }

  showSummary() {
    
    const part2 = document.getElementById("student-select-date-part-2");
    const part3 = document.getElementById("student-select-date-part-3");

    part2.classList.add("u-hidden");
    part3.classList.remove("u-hidden");

    window.scrollTo(0, 0);
    
  }
}
