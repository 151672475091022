import { Controller } from "stimulus";
import { Turbo } from "@hotwired/turbo-rails";

export default class extends Controller {
  static targets = ["spinner", "form", "button"];

  connect() {
    this.formTarget.addEventListener("submit", this.showSpinner.bind(this));
  }

  disconnect() {
    this.formTarget.removeEventListener("submit", this.showSpinner.bind(this));
  }

  showSpinner() {
    this.spinnerTarget.classList.remove("u-hidden");
    this.spinnerTarget.classList.add("u-inlineBlock");
    this.buttonTarget.disabled = true;
  }

  hideSpinner() {
    this.spinnerTarget.classList.add("u-hidden");
    this.spinnerTarget.classList.remove("u-inlineBlock");
    this.buttonTarget.disabled = false;
  }
}
