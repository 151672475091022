import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["pupilListLoading", "pupilList"];

  connect() {
    this.hideLoading();
  }

  displayLoading() {
    optionalChaining(() =>
      this.targets.find("pupilListLoading").classList.remove("u-hidden")
    );
    optionalChaining(() =>
      this.targets.find("pupilList").classList.add("u-hidden")
    );
  }

  hideLoading() {
    optionalChaining(() =>
      this.targets.find("pupilListLoading").classList.add("u-hidden")
    );
    optionalChaining(() =>
      this.targets.find("pupilList").classList.remove("u-hidden")
    );
  }

  closeQuickLook(event) {
    event.preventDefault();
    const quickViewElement = document.querySelector(
      "#school-dashboard-open-slot-quick-look"
    );
    quickViewElement.classList.add("u-hidden");
  }

  async showQuickLook(event) {
    event.preventDefault();

    const instructorId = this.data.get("instructorId");
    const startTime = this.data.get("startTime");
    const endTime = this.data.get("endTime");
    const slotId = encodeURIComponent(this.data.get("slotId"));
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;

    const response = await fetch(
      `/schools/open-slot/${instructorId}/quick-look/${startTime}/${endTime}?slot_id=${slotId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "X-CSRF-Token": csrfToken,
          Accept: "text/vnd.turbo-stream.html",
        },
      }
    );

    if (response.ok) {
      const quickViewElement = document.querySelector(
        "#school-dashboard-lesson-quick-look"
      );
      quickViewElement.classList.remove("u-hidden");

      const responseBody = await response.text();
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(responseBody, "text/html");
      const template = xmlDoc.querySelector("template");
      const targetElement = document.getElementById("school_lesson_quick_look");

      if (template && targetElement) {
        targetElement.innerHTML = "";
        targetElement.appendChild(template.content.cloneNode(true));
      }
    }
  }

  setInstructor(event) {
    // from the  data-id html tag, get the value
    const lessonId = event.target.dataset.id;

    // set the value of student_lesson_premium_instructor_profile_id to the instructorId
    document.getElementById(
      "premium_instructor_profile_student_lesson_id"
    ).value = lessonId;

    // find all the items that start with the ID selected-item-instructor
    const selectedItems = document.querySelectorAll(
      '[id^="selected-item-instructor"]'
    );

    // selectedItems.forEach((item) => {
    //   if (item.dataset.id == instructorId) {
    //     item.value = true
    //   }
    //   else {
    //     item.value = false
    //   }
    // })
  }

  // Used by the method below to keep track of the timeout so it casn be cancelled.
  studentSearchTimeout = null;
  async studentSearchList(event) {
    event.preventDefault();
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
    const searchTerm = event.target.value;

    if (this.studentSearchTimeout) {
      clearTimeout(this.studentSearchTimeout);
    }

    this.displayLoading();

    this.studentSearchTimeout = setTimeout(async () => {
      const response = await fetch(
        `/schools/open-slot/filter-book-slot-students?search_term=${searchTerm}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "X-CSRF-Token": csrfToken,
            Accept: "text/vnd.turbo-stream.html",
          },
        }
      );

      if (response.ok) {
        const stream = await response.text();
        Turbo.renderStreamMessage(stream);
      }

      this.hideLoading();
    }, 500);
  }

  async setInstructorAndLocation(event) {
    const pupilId = event.target.dataset.id;

    // "Do you think God stays in heaven because he, too, lives in fear of what he's created here on earth?"
    let url = `/us/schools/quick-look/${pupilId}/set-instructor-and-location`;
    let hideInstructor = !!(
      document.getElementById("hide_instructor") &&
      document.getElementById("hide_instructor").value
    );
    let hideLocation = "false"

    // This feels like a Hack to not show instructor when the classroom type is being set.
    if (document.getElementById("select_open_classroom_type")) {
      if (document.getElementById("select_open_classroom_type").checked) {
        hideInstructor = "true";
        hideLocation = "true"
      }
    }

    const pickedInstructorId =
      document.getElementById("picked_instructor_id") &&
      document.getElementById("picked_instructor_id").value;
    const slotInstructorId =
      document.getElementById("slot_instructor_id") &&
      document.getElementById("slot_instructor_id").value;
    const slotStartTime =
      document.getElementById("slot_start_time") &&
      document.getElementById("slot_start_time").value;
    const queryParams = [
      { name: "instructor_token", value: pickedInstructorId },
      { name: "hide_instructor", value: hideInstructor },
      { name: "hide_location", value: hideLocation },
      { name: "slot_instructor_id", value: slotInstructorId },
      { name: "slot_start_time", value: slotStartTime },
    ];
    const query = queryParams
      .filter((param) => param.value)
      .map((param) => `${param.name}=${param.value}`)
      .join("&");
    if (query) url += `?${query}`;

    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "X-CSRF-Token": csrfToken,
        Accept: "text/vnd.turbo-stream.html",
      },
    });

    if (response.ok) {
      const stream = await response.text();
      Turbo.renderStreamMessage(stream);
      var element = document.getElementById(
        "school_quick_look_add_lesson_instructor_and_location_form"
      );

      // let showLocation = true
      // This feels like a Hack to not show location when the classroom type is being set.
      // if (document.getElementById("select_open_classroom_type")) {
      //   if (document.getElementById("select_open_classroom_type").checked) {
      //     showLocation = false;
      //   }
      // }

      if (element && hideLocation == "false") {
        element.removeAttribute("hidden");
      }

      if (element && hideLocation == "true") {
        element.setAttribute("hidden", true);
      }

    }
  }

  async reserveSlot(event) {
    event.preventDefault();
    // const url = this.element.getAttribute('href');
    const url = new URL(
      this.element.getAttribute("href"),
      window.location.origin
    );
    const params = new URLSearchParams(url.search);

    const startTime = params.get("start_time");
    const endTime = params.get("end_time");
    const instructorId = params.get("instructor_id");

    const response = await fetch(this.element.getAttribute("href"), {
      method: "POST",
      body: JSON.stringify({
        reserved_slot: true,
        start_time: startTime,
        end_time: endTime,
        instructor_id: instructorId,
      }),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "X-CSRF-Token": this.getCSRFToken(),
        Accept: "text/vnd.turbo-stream.html",
      },
    });

    if (response.ok) {
      const quickViewElement = document.querySelector(
        "#school-dashboard-lesson-quick-look"
      );
      quickViewElement.classList.remove("u-hidden");

      const responseBody = await response.text();
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(responseBody, "text/html");
      const template = xmlDoc.querySelector("template");
      const targetElement = document.getElementById("school_lesson_quick_look");

      if (template && targetElement) {
        targetElement.innerHTML = "";
        targetElement.appendChild(template.content.cloneNode(true));
      }
    }
  }

  async deleteSlot(event) {
    event.preventDefault();

    let button = event.currentTarget;
    let spinner = button.querySelector(".Spinner");
    spinner.classList.remove("u-hidden");
    spinner.classList.add("u-inlineBlock");
    button.disabled = true;


    let deleteSlotValidationMessage = document.getElementById("delete-slot-validation-message");
    deleteSlotValidationMessage.hidden = true;

    const slotId = this.data.get("slotId");
    const startTimeForDelete = this.data.get("startTime");
    const endTimeForDelete = this.data.get("endTime");
    const instructorIdForDelete = this.data.get("instructorId");

    const urlForDelete = `/schools/bookings/delete-slot?slot=true&start_time=${startTimeForDelete}&end_time=${endTimeForDelete}&instructor_id=${instructorIdForDelete}&slot_id=${slotId}`;

    const response = await fetch(urlForDelete, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "X-CSRF-Token": this.getCSRFToken(),
        Accept: "text/vnd.turbo-stream.html",
      },
    });

    if (response.ok) {
      const quickViewElement = document.querySelector(
        "#school-dashboard-lesson-quick-look"
      );
      quickViewElement.classList.remove("u-hidden");

      const responseBody = await response.text();
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(responseBody, "text/html");
      const template = xmlDoc.querySelector("template");
      const targetElement = document.getElementById("school_lesson_quick_look");

      if (template && targetElement) {
        targetElement.innerHTML = "";
        targetElement.appendChild(template.content.cloneNode(true));
      }
    } else {
      let deleteSlotValidationMessage = document.getElementById("delete-slot-validation-message");
      deleteSlotValidationMessage.hidden = false;
      let spinner = button.querySelector(".Spinner");
      spinner.classList.add("u-hidden");
      spinner.classList.remove("u-inlineBlock");
      this.element.disabled = false;
    }
  }

  async editSlotOpenQuickLook(event) {
    event.preventDefault();
    const response = await fetch(this.element.getAttribute("href"), {
      method: "GET",
      headers: {
        "X-CSRF-Token": this.getCSRFToken(),
        Accept: "text/vnd.turbo-stream.html",
      },
    });

    if (response.ok) {
      const quickViewElement = document.querySelector(
        "#school_lesson_quick_look"
      );
      quickViewElement.classList.remove("u-hidden");

      const responseBody = await response.text();
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(responseBody, "text/html");
      const template = xmlDoc.querySelector("template");
      const targetElement = document.getElementById("school_lesson_quick_look");

      if (template && targetElement) {
        targetElement.innerHTML = "";
        targetElement.appendChild(template.content.cloneNode(true));
      }
    }
  }

  getCSRFToken() {
    const metaTag = document.querySelector("meta[name='csrf-token']");
    return metaTag ? metaTag.getAttribute("content") : "";
  }

  async bookDriveTest(event) {
    const instructorId = document.getElementById("instructor_id").value;
    const startTime = document.getElementById("start_time").value;
    const url = `/schools/open-slot/${instructorId}/quick-look/book-slot/practical-test/start-time/${startTime}`;

    await this.fetchData(url, instructorId, startTime, true);
  }

  async cancelOptionsAction(event) {
    const instructorId = document.getElementById("instructor_id").value;
    const startTime = document.getElementById("start_time").value;
    const url = `/schools/open-slot/${instructorId}/quick-look/view-options/start-time/${startTime}`;

    await this.fetchData(url, instructorId, startTime, false);
  }

  async fetchData(url, instructorId, startTime, isPracticalTest) {
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "X-CSRF-Token": csrfToken,
        Accept: "text/vnd.turbo-stream.html",
      },
    });

    if (response.ok) {
      const stream = await response.text();
      Turbo.renderStreamMessage(stream);
    }

    const updateSlotDetailsUrl = `/schools/open-slot/${instructorId}/quick-look/update-slot-details/start-time/${startTime}?is_practical_test=${isPracticalTest}`;
    const updateSlotDetailsResponse = await fetch(updateSlotDetailsUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "X-CSRF-Token": csrfToken,
        Accept: "text/vnd.turbo-stream.html",
      },
    });

    if (updateSlotDetailsResponse.ok) {
      const stream = await updateSlotDetailsResponse.text();
      Turbo.renderStreamMessage(stream);
    }
  }
}

function optionalChaining(valueFn) {
  try {
    return valueFn();
  } catch (e) {
    return undefined;
  }
}
