import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  
  sendToInstructorForDrive(event) {
    const message = document.getElementById(`enquiry-send-to-instructor-for-drive-message`).value;
    let error = document.getElementById(`send-on-enquiry-text-message-error`);
    const enquiryId = event.target.dataset.enquiryIdValue;
    let instructorToken = 'XXX';

    // find instructor-token-for-just-drive and set value to instructorToken
    const instructorTokenForJustDrive = document.getElementById(`instructor-token-for-just-drive`);
    if (instructorTokenForJustDrive) {
      instructorToken = instructorTokenForJustDrive.value;
    } else {
      const error_message = document.getElementById(`send-on-enquiry-error-message`);
      error_message.innerHTML = "Instructor not set correctly, please refresh and try again. If the problem persists, please contact us.";
      error.classList.remove("u-hidden");
      return;
    }

    if (instructorToken == "XXX") {
      // find error-message and add error message
      const error_message = document.getElementById(`send-on-enquiry-error-message`);
      error_message.innerHTML = "Instructor not set correctly, please refresh and try again. If the problem persists, please contact us.";
      error.classList.remove("u-hidden");
      return;
    }

    if (message === "") {  
      // find error-message and add error message
      const error_message = document.getElementById(`send-on-enquiry-error-message`);
      error_message.innerHTML = "Text messages can't be blank";
      error.classList.remove("u-hidden");
      return;
    }

    error.classList.add("u-hidden");

    fetch(`/schools/enquiries/${enquiryId}/send-enquiry-for-drive`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
      body: JSON.stringify({ 
        message: message,
        instructorToken: instructorToken
       })
    }).then(response => {
      if (response.ok) {
        
        let response_json = response.json();
        console.log(JSON.stringify(response_json));
        // redirect to BASE URL /schools/enquiries
        window.location.href = "/schools/enquiries?sent_on_drive=true";
        
      } else {
        
        alert("Something has gone wrong. Please try again.");
        const error_message = document.getElementById(`send-on-enquiry-error-message`);
        error_message.innerHTML = "Something has gone wrong. Please try again.";
        error.classList.remove("u-hidden");

        throw new Error('Network response was not ok.');
      }
    }).catch(error => {

      alert("Something has gone wrong. Please try again.");
      const error_message = document.getElementById(`send-on-enquiry-error-message`);
      error_message.innerHTML = "Something has gone wrong. Please try again.";
      let error1 = document.getElementById(`send-on-enquiry-text-message-error`);
      error1.classList.remove("u-hidden");
      console.error('Error:', error);
    });
  }
}