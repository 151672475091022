import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "content", "tab" ];

  update(event) {
    event.preventDefault();

    let tab1 = document.getElementById("opening-times-tab");
    let tab2 = document.getElementById("scheduled-closures-tab");
    if (event.target.href.includes("opening-times")) {
      tab1.classList.add('is-active');
      tab2.classList.remove('is-active');
    }
    else {
      tab2.classList.add('is-active');
      tab1.classList.remove('is-active');
    }

    fetch(event.target.href)
      .then(response => response.text())
      .then(html => {
        this.contentTarget.innerHTML = html;
      });
  }

  toggleActiveClass(activeTab) {
    this.tabTargets.forEach(tab => {
      if (tab === activeTab) {
        tab.classList.add('is-active');
      } else {
        tab.classList.remove('is-active');
      }
    });
  }
}
