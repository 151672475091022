import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  initialize() {
    this.formData = {};
  }

  static targets = [
    "locationDetails",
    "slotDetails",
    "personalDetails",
    "extraDetails",
    "paymentDetails",
    "skipSchedule",
    "assignFreePackage",
    "payLaterAndTrack",
  ];

  connect() {}

  fullyComplete(event) {
    this.hideAll();
    window.scrollTo(0, 0);
    // alert("Fully complete");
    // show a really cool spinnner / car moving....?
  }

  extraDetailsComplete(event) {
    this.hideAll();
    this.paymentDetailsTarget.hidden = false;
    window.scrollTo(0, 0);
  }

  personalDetailsComplete(event) {
    this.hideAll();
    this.extraDetailsTarget.hidden = false;
    window.scrollTo(0, 0);
  }

  slotPicked(event) {
    this.hideAll();
    this.personalDetailsTarget.hidden = false;
    this.skipScheduleTarget.value = "false";
    window.scrollTo(0, 0);
  }

  addressFound(event) {
    this.skipScheduleTarget.value = "false";

    const { locationData } = event.detail;
    this.goToSlotDetails(locationData);

    let booking_location_pickup_home_work_zipcode = document.getElementById(
      "booking_location_pickup_home_work_zipcode_value"
    );
    if (booking_location_pickup_home_work_zipcode) {
      booking_location_pickup_home_work_zipcode.value =
        locationData.pick_up_zipcode;
    }

    let booking_location_pickup_home_work_address_line_1 =
      document.getElementById(
        "booking_location_pickup_home_work_address_value"
      );
    if (booking_location_pickup_home_work_address_line_1) {
      booking_location_pickup_home_work_address_line_1.value =
        locationData.pick_up_address_line_1;
    }

    // same for dropoff
    let booking_location_dropoff_home_work_zipcode = document.getElementById(
      "booking_location_drop_off_home_work_zipcode_value"
    );
    if (booking_location_dropoff_home_work_zipcode) {
      booking_location_dropoff_home_work_zipcode.value =
        locationData.drop_off_zipcode;
    }

    let booking_location_dropoff_home_work_address_line_1 =
      document.getElementById(
        "booking_location_drop_off_home_work_address_value"
      );
    if (booking_location_dropoff_home_work_address_line_1) {
      booking_location_dropoff_home_work_address_line_1.value =
        locationData.drop_off_address_line_1;
    }
  }

  schedulingSkipped(event) {
    event.preventDefault();
    this.goToPersonalDetails();
    this.skipScheduleTarget.value = "true";
  }

  assignFreePackagePressed(event) {
    this.assignFreePackageTarget.value = "true";
  }

  payLaterPressed(event) {
    this.assignFreePackageTarget.value = "true";
    this.payLaterAndTrackTarget.value = "true";
  }

  addressOutsideZone(event) {
    event.preventDefault();
    const { locationData } = event.detail;
    this.goToLocationDetails(locationData);
  }

  backToAddress() {
    this.hideAll();
    this.locationDetailsTarget.hidden = false;
  }

  backToSlots() {
    this.hideAll();
    this.slotDetailsTarget.hidden = false;
  }

  backToPersonalDetails() {
    this.hideAll();
    this.personalDetailsTarget.hidden = false;
  }

  backToExtraDetails() {
    this.hideAll();
    this.extraDetailsTarget.hidden = false;
  }

  goToAddressDetails() {
    this.hideAll();
    this.locationDetailsTarget.hidden = false;
    window.scrollTo(0, 0);
  }

  goToLocationDetails(locationData) {
    this.hideAll();

    this.locationDetailsTarget.hidden = false;

    this.dispatch("updateLocationDetails", {
      detail: {
        locationData: locationData,
      },
    });
    window.scrollTo(0, 0);
  }

  goToSlotDetails(locationData) {
    this.hideAll();
    this.slotDetailsTarget.hidden = false;

    this.dispatch("updateSlotDetails", {
      detail: {
        locationData: locationData,
      },
    });
    window.scrollTo(0, 0);
  }

  goToPersonalDetails() {
    this.hideAll();
    this.personalDetailsTarget.hidden = false;
    window.scrollTo(0, 0);
  }

  hideAll() {
    this.locationDetailsTarget.hidden = true;
    this.slotDetailsTarget.hidden = true;
    this.personalDetailsTarget.hidden = true;
    this.extraDetailsTarget.hidden = true;
    this.paymentDetailsTarget.hidden = true;
  }
}
