/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// const f = () => {
//   console.log('Hello World from Webpacker func')
// }

// f();

import '@hotwired/turbo-rails'
import '@hotwired/stimulus'
import { Turbo } from "@hotwired/turbo-rails"
import { Application } from "@hotwired/stimulus"
import "./controllers/hello_controller"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"
import SchoolNavigationController from "./controllers/school_navigation_controller"
import * as recaptcha from '../utils/recaptcha';
import * as feedback from '../utils/feedback';

const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application


// Tweak
application.register("school-navigation", SchoolNavigationController)

const context = require.context("./controllers", true, /\.js$/)
Stimulus.load(definitionsFromContext(context))

Turbo.session.drive = false
// Turbo.setProgressBarDelay(0)

// document.addEventListener("turbo:submit-end", async (event) => {
//   console.error('yo???', event)
// })
