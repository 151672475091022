import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["programPanel", "programRadio", "selectedProgramId"]

  connect() {
    this.updateSelectedProgram()
  }

  selectProgram(event) {
    const clickedPanel = event.currentTarget
    const radioButton = clickedPanel.querySelector('input[type="radio"]')
    radioButton.checked = true
    this.selectedProgramIdTarget.value = clickedPanel.dataset.programId
    this.updateSelectedProgram()
  }

  updateSelectedProgram() {
    this.programPanelTargets.forEach((panel, index) => {
      const radio = this.programRadioTargets[index]
      if (radio.checked) {
        panel.classList.add("Panel--success")
        panel.classList.remove("Panel--subtle", "Panel--borderOnly")
        this.selectedProgramIdTarget.value = panel.dataset.programId
      } else {
        panel.classList.remove("Panel--success")
        panel.classList.add("Panel--subtle", "Panel--borderOnly")
      }
    })
  }
}