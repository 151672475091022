import { Controller } from "@hotwired/stimulus";
import * as luxon from "luxon";

export default class extends Controller {
  static targets = [
    "pickUpLocation",
    "dropOffLocation",
    "zoneName",
    "zoneColor",
    "weekView",

    // Following three targets are the fancy date and time selection fields
    "lessonNewDate",
    "lessonNewTime",
    "lessonNewDurationInMinutes",

    "pickedSlotIsoTime",
    "pickedInstructorIds",
    "pickedVehicleIds",
    "pickedSlotDuration",
    "pickedZoneId",

    "pickedInstructorId",
    "pickedVehicleId",
  ];

  timezone = this.data.get("timezone");
  drivingSchoolToken = this.data.get("drivingSchoolToken");

  connect() {}

  updateSlotDetails(event) {
    this.updateLocationDisplayStrings(event.detail.locationData);
    this.pickedZoneIdTarget.value = event.detail.locationData.zone_id;
    this.updatePostParametersForWeekView();
  }

  updateLocationDisplayStrings(locationData) {
    this.pickUpLocationTarget.innerHTML = `${locationData.pick_up_address_line_1}, ${locationData.pick_up_zipcode}`;
    this.dropOffLocationTarget.innerHTML = `${locationData.drop_off_address_line_1}, ${locationData.drop_off_zipcode}`;
    this.zoneNameTarget.innerHTML = locationData.zone_name;
    this.zoneColorTarget.style.backgroundColor = locationData.zone_color;
  }

  /**
   * Triggered when a slot is clicked on the week view partial
   * @param {*} event
   */
  slotClicked(event) {
    // Get the slot details
    const startDateIso = event.detail.startDate;
    const duration = event.detail.duration;
    const instructorIds = event.detail.instructorIds;
    const vehicleIds = event.detail.vehicleIds;

    // Set the hidden form fields
    this.pickedSlotIsoTimeTarget.value = startDateIso;
    this.pickedSlotDurationTarget.value = duration;
    this.pickedInstructorIdsTarget.value = instructorIds;
    this.pickedVehicleIdsTarget.value = vehicleIds;

    // Get local time
    let localLuxonTime = luxon.DateTime.fromISO(startDateIso);
    localLuxonTime = localLuxonTime.setZone(this.timezone);

    // Set the fancy date and time selection fields
    this.lessonNewDateTarget.value = localLuxonTime.toISODate();
    this.lessonNewTimeTarget.value = localLuxonTime.toFormat("HH:mm");
    this.lessonNewDurationInMinutesTarget.value = duration;

    // Refresh the instructor and vehicle dropdowns to refelct the new slot
    this.refreshInstructorSelection();
    this.refreshVehicleSelection();
  }

  picked_slot_iso_time_change(event) {
    // const pickedSlotIsoTime = this.pickedSlotIsoTimeTarget.value;
    // this.lessonNewDateTarget.value = pickedSlotIsoTime.split("T")[0];
  }

  completedForm(event) {
    event.preventDefault();

    const errors = this.validateForm();
    const validationBlock = document.getElementById("validation-slot-block");
    const validationMessage = document.getElementById(
      "validation-message-slot-text"
    );

    if (errors.length > 0) {
      validationBlock.hidden = false;
      validationMessage.innerHTML = errors.join("<br>");
      validationMessage.hidden = false;
      return;
    }

    this.element.dispatchEvent(
      new CustomEvent("slotPicked", {
        detail: {
          formName: "driving-school-admin-booking-form-v2-slot-details",
          lessonDetails: {},
        },
      })
    );
  }

  validateForm() {
    let errors = [];

    const pickedSlotIsoTime = this.pickedSlotIsoTimeTarget.value;
    const timeIsValid = luxon.DateTime.fromISO(pickedSlotIsoTime).isValid;
    const pickedInstructorId = this.pickedInstructorIdTarget.value;
    const pickedVehicleId = this.pickedVehicleIdTarget.value;
    const duration = this.pickedSlotDurationTarget.value;
    const durationAsInt = parseInt(duration);
    const durationIsValid =
      durationAsInt &&
      durationAsInt > 0 &&
      `${durationAsInt}` === `${duration}`;

    if (!pickedSlotIsoTime || pickedSlotIsoTime == "" || !timeIsValid) {
      errors.push(
        "An invalid time has been selected, please select an available slot or skip scheduling"
      );
    }

    if (!pickedInstructorId || pickedInstructorId == "") {
      errors.push("An instructor has not been selected");
    }

    if (!pickedVehicleId || pickedVehicleId == "") {
      errors.push("A vehicle has not been selected");
    }

    if (!duration || duration == "" || !durationIsValid) {
      errors.push("An invalid duration has been selected");
    }

    return errors;
  }

  // This function is activated when the instructor dropdown is changed to set the value of a hidden form element
  async changeInstructorSelectionFromDropdown(event) {
    this.pickedInstructorIdTarget.value = event.detail.id;
    const vehicleId = event.detail.dispatchItem.vehicleId;

    // Broadcast to the vehicle selector to update the vehicle dropdown
    this.dispatch("selectVehicle", {
      detail: {
        id: vehicleId,
      },
    });
  }

  // This function is activated when the vehicle dropdown is changed to set the value of a hidden form element
  async changeVehicleSelectionFromDropdown(event) {
    this.pickedVehicleIdTarget.value = event.detail.id;
  }

  // When the fancy lesson time dropdown is changed
  lessonNewTimeChange(event) {
    // No need to update hidden field here, as the iso time controller does it for us
    // But we should deselect the week view slot
    this.deselectWeekViewSlot();
  }

  // When the fancy date dropdown is changed
  lessonNewDateChange(event) {
    // No need to update hidden field here, as the iso time controller does it for us
    // But we should deselect the week view slot
    this.deselectWeekViewSlot();
  }

  // When the fancy dropdown is changed
  lessonNewDurationInMinutesChange(event) {
    this.pickedSlotDurationTarget.value = event.currentTarget.value;
    this.deselectWeekViewSlot();
  }

  /**
   * This function gathers all the details from the form and dispatches an event to the week view to update the post parameters
   * so that the week views slots can be updated
   */
  updatePostParametersForWeekView() {
    this.dispatch("updateWeekViewPostParameters", {
      detail: {
        postParameters: {
          zone_ids: `${this.pickedZoneIdTarget.value || ""}`,
          driving_school_token: this.drivingSchoolToken,
        },
      },
    });
  }

  /**
   * This function deselects any currently selected slot on the week view
   */
  deselectWeekViewSlot() {
    this.dispatch("selectSlot", {
      detail: {
        id: null,
      },
    });
  }

  /**
   * This function is called to refresh the instructor selection dropdown when the hidden field is changed
   */
  async refreshInstructorSelection() {
    let instructorIds = this.pickedInstructorIdsTarget.value || "";
    let currentInstructorId = this.pickedInstructorIdTarget.value || "0";
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;

    const actionQueryParam = `item_selected_function=${encodeURIComponent(
      "driving-school-admin-booking-form-v2-slot-details#changeInstructorSelectionFromDropdown"
    )}`;
    const instructorResponse = await fetch(
      `/schools/bookings/add/v2/instructors?selected_instructor_id=${currentInstructorId}&instructor_ids=${instructorIds}&${actionQueryParam}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "X-CSRF-Token": csrfToken,
          Accept: "text/vnd.turbo-stream.html",
        },
      }
    );

    // Set the selected instructor id to blank
    this.pickedInstructorIdTarget.value = "";

    if (instructorResponse.ok) {
      const responseBody = await instructorResponse.text();
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(responseBody, "text/html");
      const template = xmlDoc.querySelector("template");
      const targetElement = document.getElementById(
        "driving_school_admin_booking_form_v2_instructor_selector"
      );

      if (template && targetElement) {
        targetElement.innerHTML = "";
        targetElement.appendChild(template.content.cloneNode(true));
      }
    } else {
      alert("HTTP-Error: " + instructorResponse.status);
    }
  }

  /**
   * This function is called to refresh the vehicle selection dropdown when the hidden field is changed
   */
  async refreshVehicleSelection() {
    // Here, we need to replace the turbo stream item on the page with a new url that takes into account which vehicles are selected
    // We call our API which will replace the appropriate turbo stream item on the page
    let vehicleIds = this.pickedVehicleIdsTarget.value || "";
    let selectedVehicleId = this.pickedVehicleIdTarget.value || "0";

    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
    const actionQueryParam = `item_selected_function=${encodeURIComponent(
      "driving-school-admin-booking-form-v2-slot-details#changeVehicleSelectionFromDropdown"
    )}&force_select_item_function=${encodeURIComponent(
      "driving-school-admin-booking-form-v2-slot-details:selectVehicle@window"
    )}`;

    const vehicleResponse = await fetch(
      `/schools/bookings/add/v2/vehicles?vehicle_ids=${vehicleIds}&${actionQueryParam}&selected_vehicle_id=${selectedVehicleId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "X-CSRF-Token": csrfToken,
          Accept: "text/vnd.turbo-stream.html",
        },
      }
    );

    // Set the selected vehicle id to blank
    this.pickedVehicleIdTarget.value = "";

    if (vehicleResponse.ok) {
      const responseBody = await vehicleResponse.text();
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(responseBody, "text/html");
      const template = xmlDoc.querySelector("template");
      const targetElement = document.getElementById(
        "driving_school_admin_booking_form_v2_vehicle_selector"
      );

      if (template && targetElement) {
        targetElement.innerHTML = "";
        targetElement.appendChild(template.content.cloneNode(true));
      }
    } else {
      alert("HTTP-Error: " + vehicleResponse.status);
    }
  }
}
