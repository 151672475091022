import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  
  sendText(event) {
    const enquiryId = event.target.dataset.enquiryIdValue;
    // get message_message contents
    const message = document.getElementById(`enquiry_new_message`).value;
    let error = document.getElementById(`text-message-error`);

    // if message is empty, return
    if (message === "") {  
      // find error-message and add error message
      const error_message = document.getElementById(`error-message`);
      error_message.innerHTML = "Text messages can't be blank";
      error.classList.remove("u-hidden");
      return;
    }

    error.classList.add("u-hidden");
    
    // find text-message-spinner and remove u-hidden class
    let spinner = document.getElementById(`text-message-spinner`);
    spinner.classList.remove("u-hidden");

    // find a tag text-message-button and add pointer-events: none to disable button
    let button_a_tag_link = document.getElementById(`text-message-button`);
    button_a_tag_link.style.pointerEvents = "none";

    // send a POST request to the server
    fetch(`/schools/enquiries/${enquiryId}/send-text`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
      body: JSON.stringify({ message: message })
    }).then(response => {
      if (response.ok) {
        
        const enquiry_new_message = document.getElementById(`enquiry_new_message`);
        enquiry_new_message.value = "";

        return response.text(); // Get the response as text (HTML) instead of JSON
      } else {
        
        const error_message = document.getElementById(`error-message`);
        error_message.innerHTML = "Something has gone wrong. Please try again.";
        error.classList.remove("u-hidden");

        spinner.classList.add("u-hidden");
        button_a_tag_link.style.pointerEvents = "auto";

        throw new Error('Network response was not ok.');
      }
    }).then(html => {
      // Assuming 'text_message_history' is the ID of your Turbo Frame
      document.getElementById("text_message_history").outerHTML = html;
      
      spinner.classList.add("u-hidden");
      button_a_tag_link.style.pointerEvents = "auto";

    }).catch(error => {

      const error_message = document.getElementById(`error-message`);
      error_message.innerHTML = "Something has gone wrong. Please try again.";
      let error1 = document.getElementById(`text-message-error`);
      error1.classList.remove("u-hidden");


      spinner.classList.add("u-hidden");
      button_a_tag_link.style.pointerEvents = "auto";
      
      console.error('Error:', error);
    });
  }

}