import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "tab",
    "pickedInstructorToken"
  ];

  connect() {
  }


  async openConflictManager(event) {
    event.preventDefault();

    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;

    const response = await fetch(`/schools/conflicts/quick-look`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "X-CSRF-Token": csrfToken,
        "Accept": "text/vnd.turbo-stream.html"
      }
    });

    if (response.ok) {
      const quickViewElement = document.querySelector("#school-dashboard-lesson-quick-look");
      quickViewElement.classList.remove("u-hidden");

      const responseBody = await response.text();
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(responseBody, "text/html");
      const template = xmlDoc.querySelector("template");
      const targetElement = document.getElementById("school_lesson_quick_look");

      if (template && targetElement) {
        targetElement.innerHTML = "";
        targetElement.appendChild(template.content.cloneNode(true));
      }
    }
  }

  async showAddLessonQuickLook(event) {
    event.preventDefault();

    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
    const instructorToken = event.currentTarget.dataset.instructorToken;

    let url = `/schools/lesson/add-lesson/quick-look`;
    if (instructorToken) {
      url += `?instructor_token=${instructorToken}`;
    }

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "X-CSRF-Token": csrfToken,
        "Accept": "text/vnd.turbo-stream.html"
      }
    });

    if (response.ok) {
      const quickViewElement = document.querySelector("#school-dashboard-lesson-quick-look");
      quickViewElement.classList.remove("u-hidden");

      const responseBody = await response.text();
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(responseBody, "text/html");
      const template = xmlDoc.querySelector("template");
      const targetElement = document.getElementById("school_lesson_quick_look");

      if (template && targetElement) {
        targetElement.innerHTML = "";
        targetElement.appendChild(template.content.cloneNode(true));
      }
    }
  }

  async showAddLessonQuickLookWithStudentId(event) {
    event.preventDefault();

    const pupilId = this.data.get('pupilId')
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;

    const response = await fetch(`/schools/lesson/add-lesson/quick-look?pupil_id=${pupilId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "X-CSRF-Token": csrfToken,
        "Accept": "text/vnd.turbo-stream.html"
      }
    });

    if (response.ok) {
      const quickViewElement = document.querySelector("#school-dashboard-lesson-quick-look");
      quickViewElement.classList.remove("u-hidden");

      const responseBody = await response.text();
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(responseBody, "text/html");
      const template = xmlDoc.querySelector("template");
      const targetElement = document.getElementById("school_lesson_quick_look");

      if (template && targetElement) {
        targetElement.innerHTML = "";
        targetElement.appendChild(template.content.cloneNode(true));
      }
    }
  }

  async showQuickLook(event) {
    event.preventDefault();

    const lessonId = this.data.get("lessonId");
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;

    const response = await fetch(`/schools/lesson/${lessonId}/quick-look/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "X-CSRF-Token": csrfToken,
        "Accept": "text/vnd.turbo-stream.html"
      }
    });

    if (response.ok) {
      const quickViewElement = document.querySelector("#school-dashboard-lesson-quick-look");
      quickViewElement.classList.remove("u-hidden");

      const responseBody = await response.text();
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(responseBody, "text/html");
      const template = xmlDoc.querySelector("template");
      const targetElement = document.getElementById("school_lesson_quick_look");

      if (template && targetElement) {
        targetElement.innerHTML = "";
        targetElement.appendChild(template.content.cloneNode(true));
      }
    }
  }

  toggleDate(event) {
    event.preventDefault();
    document.getElementById('calendar-full-date').style.display = 'none';
    document.getElementById('edit-date').style.display = 'block';
  }

  async showQuickLookForEvents(event) {
    event.preventDefault();

    const eventId = this.data.get("eventId");
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;

    const response = await fetch(`/schools/events/${eventId}/quick-look/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "X-CSRF-Token": csrfToken,
        "Accept": "text/vnd.turbo-stream.html"
      }
    });

    if (response.ok) {
      const quickViewElement = document.querySelector("#school-dashboard-lesson-quick-look");
      quickViewElement.classList.remove("u-hidden");

      const responseBody = await response.text();
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(responseBody, "text/html");
      const template = xmlDoc.querySelector("template");
      const targetElement = document.getElementById("school_lesson_quick_look");

      if (template && targetElement) {
        targetElement.innerHTML = "";
        targetElement.appendChild(template.content.cloneNode(true));
      }
    }
  }

  async sendTurboRequest(url){
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "X-CSRF-Token": csrfToken,
        "Accept": "text/vnd.turbo-stream.html"
      }
    });

    return response;
  }

  async showSlotData(event) {
    event.preventDefault();
    var lessonIdElement = document.getElementById("us-lesson-id");
    if (lessonIdElement) {
      var lessonId = lessonIdElement.textContent;
      var url = `/schools/lesson/${lessonId}/quick-look/slot-data`;
      const response = await this.sendTurboRequest(url);
      if (response.ok) {
        const stream = await response.text()
        Turbo.renderStreamMessage(stream)
        this.toggleActiveClass();
      }
    }
  }

  async showDateTimeEdit(event) {
    event.preventDefault();
    var lessonIdElement = document.getElementById("us-lesson-id");
    if (lessonIdElement) {
      var lessonId = lessonIdElement.textContent;
      var url = `/schools/lesson/${lessonId}/quick-look/date-time-edit`;
      const response = await this.sendTurboRequest(url);
      if (response.ok) {
        const stream = await response.text()
        Turbo.renderStreamMessage(stream)
        this.toggleActiveClass();
      }
    }
  }

  async toggleActiveClass() {
    this.tabTargets.forEach((tab) => {
      tab.classList.toggle("is-active");
    });
  }
}