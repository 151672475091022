import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [];

  connect() {
    console.log("school_lesson_quick_look_add_lesson_edit_controller.js");
    this.load_location_and_postcode();
  }

  async load_location_and_postcode() {
    var address_line_1 = document.getElementById("address_line_1_value");
    if (address_line_1) {
      address_line_1 = address_line_1.value;
    }

    var zipcode = document.getElementById("zipcode_value");
    if (zipcode) {
      zipcode = zipcode.value;
    }

    var school_token = document.getElementById("us_school_token");
    if (school_token) {
      school_token = school_token.value;
    }

    var address_line_2 = document.getElementById("address_line_2_value");
    if (address_line_2) {
      address_line_2 = address_line_2.value;
    }

    var city = document.getElementById("city_value");
    if (city) {
      city = city.value;
    }

    var state = document.getElementById("state_value");
    if (state) {
      state = state.value;
    }

    var country = document.getElementById("country_value");
    if (country) {
      country = country.value;
    }

    let extraParams = "";
    if (document.getElementById("pick_up_location_id")) {
      const pickupLocationId = document.getElementById(
        "pick_up_location_id"
      ).value;
      const pickUpCategory = document.getElementById("pick_up_category").value;
      const dropoffLocationId = document.getElementById(
        "drop_off_location_id"
      ).value;
      const dropOffCategory =
        document.getElementById("drop_off_category").value;
      extraParams = `&pick_up_location_id=${pickupLocationId}&pick_up_category=${pickUpCategory}&drop_off_location_id=${dropoffLocationId}&drop_off_category=${dropOffCategory}`;
    }

    if (
      document.getElementById("booking_location_pickup_home_work_address_value")
    ) {
      console.log("SETTING ADDRESS");
      document.getElementById(
        "booking_location_pickup_home_work_address_value"
      ).value = address_line_1;
      document.getElementById(
        "booking_location_pickup_home_work_zipcode_value"
      ).value = zipcode;
      document.getElementById(
        "booking_location_pickup_home_work_address_line_2_value"
      ).value = address_line_2;
      document.getElementById(
        "booking_location_pickup_home_work_city_value"
      ).value = city;
      document.getElementById(
        "booking_location_pickup_home_work_state_value"
      ).value = state;
      document.getElementById(
        "booking_location_pickup_home_work_country_value"
      ).value = country;

      document.getElementById(
        "booking_location_drop_off_home_work_address_value"
      ).value = address_line_1;
      document.getElementById(
        "booking_location_drop_off_home_work_zipcode_value"
      ).value = zipcode;
      document.getElementById(
        "booking_location_drop_off_home_work_address_line_2_value"
      ).value = address_line_2;
      document.getElementById(
        "booking_location_drop_off_home_work_city_value"
      ).value = city;
      document.getElementById(
        "booking_location_drop_off_home_work_state_value"
      ).value = state;
      document.getElementById(
        "booking_location_drop_off_home_work_country_value"
      ).value = country;

      const url = `/fetch_locations?zipcode=${zipcode}&address=${address_line_1}&driving_school_token=${school_token}${extraParams}&city=${city}&state=${state}&country=${country}`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "text/vnd.turbo-stream.html",
        },
      });

      if (response.ok) {
        const stream = await response.text();
        Turbo.renderStreamMessage(stream);
      } else {
        console.error(`HTTP error ${response.status}: ${response.statusText}`);
      }
    }
  }

  async editLocation() {
    var pupilId = document.getElementById("pupil_id");
    var pick_up_location_id = document.getElementById("pick_up_location_id");
    var drop_off_location_id = document.getElementById("drop_off_location_id");
    var pick_up_category = document.getElementById("pick_up_category");
    var drop_off_category = document.getElementById("drop_off_category");
    var pick_up_address = document.getElementById("pick_up_address");
    var drop_off_address = document.getElementById("drop_off_address");
    var pick_up_postcode = document.getElementById("pick_up_postcode");
    var drop_off_postcode = document.getElementById("drop_off_postcode");
    var drop_off_is_same_as_pick_up = document.getElementById(
      "drop_off_is_same_as_pick_up"
    );
    var slot_instructor_id = document.getElementById(
      "slot_instructor_id_for_location_edit"
    );
    var slot_start_time = document.getElementById(
      "slot_start_time_for_location_edit"
    );

    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
    if (pupilId) {
      pupilId = pupilId.value;
    }

    console.log("pupilId: " + pupilId);
    const url = `/us/schools/quick-look/${pupilId}/edit-location?pick_up_location_id=${pick_up_location_id.value}&drop_off_location_id=${drop_off_location_id.value}&pick_up_category=${pick_up_category.value}&drop_off_category=${drop_off_category.value}&pick_up_address=${pick_up_address.value}&drop_off_address=${drop_off_address.value}&pick_up_postcode=${pick_up_postcode.value}&drop_off_postcode=${drop_off_postcode.value}&drop_off_is_same_as_pick_up=${drop_off_is_same_as_pick_up.value}&slot_instructor_id=${slot_instructor_id.value}&slot_start_time=${slot_start_time.value}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "X-CSRF-Token": csrfToken,
        Accept: "text/vnd.turbo-stream.html",
      },
    });

    if (response.ok) {
      const stream = await response.text();
      Turbo.renderStreamMessage(stream);
    }
  }

  validate(event) {
    const errors = this.validate_form();

    if (errors.length > 0) {
      event.preventDefault();
      this.show_validation_block(errors.join("<br>"));
      return;
    }
  }

  validate_form() {
    let errors = [];

    let currentPickupCategory = "";
    if (!document.getElementById("booking-location-pickup-home-work").hidden) {
      currentPickupCategory = "Address";
    } else if (
      !document.getElementById("booking-location-pickup-points").hidden
    ) {
      currentPickupCategory = "Pick-up points";
    } else if (
      !document.getElementById("booking-location-pickup-schools").hidden
    ) {
      currentPickupCategory = "School";
    }

    let currentDropoffCategory = "";
    if (!document.getElementById("sameAsPickUpLocation").checked) {
      if (
        !document.getElementById("booking-location-drop-off-home-work").hidden
      ) {
        currentDropoffCategory = "Address";
      } else if (
        !document.getElementById("booking-location-drop-off-points").hidden
      ) {
        currentDropoffCategory = "Drop-off points";
      } else if (
        !document.getElementById("booking-location-drop-off-schools").hidden
      ) {
        currentDropoffCategory = "School";
      }
    }

    if (currentPickupCategory === "Address") {
      this.validate_access_note(
        "pick_up_note",
        "Access note for pick-up location",
        errors
      );
    }

    if (currentDropoffCategory === "Address") {
      this.validate_access_note(
        "drop_off_note",
        "Access note for drop-off location",
        errors
      );
    }

    if (currentPickupCategory === "Pick-up points") {
      this.validate_location({
        locationSelector: "#booking-location-pickup-points",
        errors,
      });
    }

    if (currentDropoffCategory === "Drop-off points") {
      this.validate_location({
        locationSelector: "#booking-location-drop-off-points",
        isPickup: false,
        errors,
      });
    }

    if (currentPickupCategory === "School") {
      this.validate_location({
        locationSelector: "#booking-location-pickup-schools",
        isSchool: true,
        errors,
      });
    }
    if (currentDropoffCategory === "School") {
      this.validate_location({
        locationSelector: "#booking-location-drop-off-schools",
        isPickup: false,
        isSchool: true,
        errors,
      });
    }

    return errors;
  }

  validate_access_note(fieldId, fieldName, errors) {
    const field = document.getElementById(fieldId);
    const MAX_LENGTH = 100;
    if (field && field.value.trim().length > MAX_LENGTH) {
      errors.push(`${fieldName} must be ${MAX_LENGTH} characters or less.`);
    }
  }

  validate_location({
    locationSelector,
    isPickup = true,
    isSchool = false,
    errors,
  }) {
    const locationPoints = document.querySelectorAll(
      `${locationSelector} ul li`
    );
    const locationType = isPickup ? "pick-up" : "drop-off";

    if (!locationPoints || locationPoints.length === 0) {
      if (isSchool) {
        errors.push(
          `No schools are available, you must select another type of ${locationType} location.`
        );
      } else {
        errors.push(
          `No ${locationType} points are available, you must select another type of ${locationType} location.`
        );
      }
    } else {
      const selectedPoint = Array.from(locationPoints).some(
        (point) => !point.classList.contains("Panel--subtle")
      );

      if (!selectedPoint) {
        if (isSchool) {
          errors.push(
            `You must select a ${locationType} school from the options.`
          );
        } else {
          errors.push(
            `You must select a ${locationType} point from the options.`
          );
        }
      }
    }
  }

  show_validation_block(message) {
    const validationBlock = document.getElementById("validation-block");
    const validationMessage = document.getElementById("validation-message");

    validationMessage.innerHTML = message;
    validationBlock.hidden = false;
    validationMessage.hidden = false;
  }
}
