import { Controller } from "@hotwired/stimulus";
import * as luxon from "luxon";

export default class extends Controller {
  static targets = [
    "title",
    "loadingContainer",
    "loadedContainer",
    "eventContainer",
    "eventTemplate",
    "dayLabel",
    "timezoneLabel",
    "date",
    "errorContainer",
    "event",
    "jumpToDateContainer",
    "jumpToDateInput",
  ];

  timeIsoString = this.data.get("timeIsoString");
  timezone = this.data.get("timezone");
  eventsUrl = this.data.get("eventsUrl");
  showSlotDetails = this.data.get("showSlotDetails") === "true";
  slotSelectedFunction = this.data.get("slotSelectedFunction");
  postParameters = JSON.parse(this.data.get("postParameters"));
  alwaysClickOnFirstSlot = this.data.get("alwaysClickOnFirstSlot") === "true";

  hasJumpedToFirstSlot = false;

  curentApiCallId = 0;

  connect() {
    setTimeout(() => {
      this.refreshView();
    });
  }

  eventClicked(event) {
    // Add the Calendar-Event--Selected class to the clicked element
    this.eventTargets.forEach((element) => {
      element.classList.remove("Calendar-Event--Selected");
    });
    event.currentTarget.classList.add("Calendar-Event--Selected");

    const startDate =
      event.currentTarget.dataset.startIsoTime || new Date().toISOString();
    const duration = Number(event.currentTarget.dataset.duration || 0);
    const instructorIds = (event.currentTarget.dataset.instructorIds || "")
      .split(",")
      .map(Number);
    const vehicleIds = (event.currentTarget.dataset.vehicleIds || "")
      .split(",")
      .map(Number);

    // When a slot is clicked, we communicate to the parent element what has happened
    this.element.dispatchEvent(
      new CustomEvent("slotSelected", {
        detail: {
          startDate,
          duration,
          instructorIds,
          vehicleIds,
        },
      })
    );

    if (!this.slotSelectedFunction) {
      console.log("A slot was selected, but no input function was provided.");
    }
  }

  /**
   * Function used by parent elements to communicate to this element that a slot has been selected and should highlight if possible
   * @param {*} event
   */
  forceSelectSlot(event) {
    const id = event.detail.id;
    this.eventTargets.forEach((element) => {
      if (false) {
        // TODO: Check if the element is the one we want to select
        element.classList.add("Calendar-Event--Selected");
      } else {
        element.classList.remove("Calendar-Event--Selected");
      }
    });
  }

  /**
   * Function used by parent elements to communicate to this element that the post parameters have been updated
   * @param {*} event
   */
  forceUpdatePostParameters(event) {
    this.postParameters = event.detail.postParameters;
    this.hasJumpedToFirstSlot = false;
    this.refreshView();
  }

  previousWeek() {
    let time = luxon.DateTime.fromISO(this.timeIsoString);
    time = time.setZone(this.timezone);
    this.timeIsoString = time.minus({ weeks: 1 }).toISO();
    this.setJumpToDateValueToCurrentIsoString();
    this.refreshView();
    this.hideJumpToDateContainer();
  }

  nextWeek() {
    let time = luxon.DateTime.fromISO(this.timeIsoString);
    time = time.setZone(this.timezone);
    this.timeIsoString = time.plus({ weeks: 1 }).toISO();
    this.setJumpToDateValueToCurrentIsoString();
    this.refreshView();
    this.hideJumpToDateContainer();
  }

  jumpToSelectedDate() {
    const dateValue = this.jumpToDateInputTarget.value;
    const luxonDate = luxon.DateTime.fromISO(dateValue, {
      zone: this.timezone,
    });
    if (!luxonDate.isValid) {
      this.hideJumpToDateContainer();
      return;
    }
    this.timeIsoString = luxonDate.toISO();
    this.setJumpToDateValueToCurrentIsoString();
    this.refreshView();
    this.hideJumpToDateContainer();
  }

  /**
   * Deletes all items and refreshes the view
   */
  async refreshView() {
    await this.deleteAllEvents();
    this.showLoader();
    this.hideError();

    this.curentApiCallId += 1;
    const time = luxon.DateTime.fromISO(this.timeIsoString).setZone(
      this.timezone
    );
    const startTime = time.startOf("week");
    const endTime = time.endOf("week");
    const response = await this.makeCallToApi(
      this.curentApiCallId,
      startTime,
      endTime
    );

    if (response.outdated) {
      return;
    }

    if (response.errorMessage) {
      this.hideLoader();
      return this.showError(`Failed to get slots: ${response.errorMessage}`);
    }

    this.setTitle(response.title);
    this.populate_weekday_labels(response.day_labels);
    this.populateEvents(response.events);

    // Remove the isCurrently class from all Calendar-Hour and Calendar-WeekDay elements
    this.element.querySelectorAll(".Calendar-Hour").forEach((element) => {
      element.classList.remove("isCurrently");
    });
    this.element.querySelectorAll(".Calendar-WeekDay").forEach((element) => {
      element.classList.remove("isCurrently");
    });

    // Add the isCurrently class to the current day and hour
    let currentTime = luxon.DateTime.now().setZone(this.timezone);
    currentTime = currentTime.setZone(this.timezone);

    console.log("Current time", currentTime.toISO());
    console.log("Start time", startTime.toISO());
    console.log("End time", endTime.toISO());

    const currentTimeIsInWeek =
      currentTime > startTime && currentTime < endTime;
    if (currentTimeIsInWeek) {
      // The string will be like Calendar-Hour-05pm
      const matchingHourString = `Calendar-Hour-${currentTime.hour
        .toString()
        .padStart(2, "0")}${currentTime.hour > 11 ? "pm" : "am"}`;
      const matchingHourElement = this.element.querySelector(
        `.${matchingHourString}`
      );
      if (matchingHourElement) {
        matchingHourElement.classList.add("isCurrently");
      }
      // The string will be like Calendar-WeekDay-mon
      const matchingWeekDayString = `Calendar-WeekDay-${currentTime.weekdayShort.toLowerCase()}`;
      const matchingWeekDayElement = this.element.querySelector(
        `.${matchingWeekDayString}`
      );
      if (matchingWeekDayElement) {
        matchingWeekDayElement.classList.add("isCurrently");
      }
    }

    setTimeout(async () => {
      // The API may return a time to jump to
      const timeToJumpTo = response.start_date;
      if (!this.hasJumpedToFirstSlot && timeToJumpTo) {
        this.timeIsoString = timeToJumpTo;
        this.setJumpToDateValueToCurrentIsoString();
        this.hasJumpedToFirstSlot = true;
        this.refreshView();
      } else if (this.alwaysClickOnFirstSlot) {
        this.clickOnFirstSlot();
        this.hideLoader();
      } else {
        this.clickPreSelectedSlot();
        this.hideLoader();
      }
    });
  }

  async makeCallToApi(apiCallId, startTime, endTime) {
    const timezoneLabelText = [
      ...new Set([startTime.offsetNameShort, endTime.offsetNameShort]),
    ].join(" / ");
    this.setTimezoneLabel(timezoneLabelText);

    this.dateTarget.innerHTML = `${startTime.toLocaleString({
      month: "short",
    })} ${startTime.year}, Week ${startTime.weekNumber}`;

    // const postParameters = this.getPostParameters();
    const url = this.eventsUrl;
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
      body: JSON.stringify({
        iso_start_time: startTime.toISO(),
        iso_end_time: endTime.toISO(),
        ...{
          ...this.postParameters,
        },
      }),
    });

    if (this.curentApiCallId !== apiCallId) {
      return { outdated: true };
    }

    if (response.ok) {
      const responseBody = await response.text();
      const asJson = JSON.parse(responseBody);
      return asJson;
    } else {
      return { errorMessage: "Something went wrong" };
    }
  }

  /**
   * Adds the events to the calendar.
   * @param {*} items
   */
  populateEvents(items) {
    const timezone = this.timezone;
    let now = luxon.DateTime.now();
    now = now.setZone(timezone);

    let earliestTime = 24;
    let latestTime = 0;

    items.forEach((item) => {
      const startDateTime = luxon.DateTime.fromISO(item.full_date).setZone(
        timezone
      );
      const clonedTemplate = this.targets
        .find("eventTemplate")
        .content.cloneNode(true);

      // Do we need a return here if in past

      // Title should be in format similar to: "1:40pm"
      let title = startDateTime
        .toLocaleString({ hour: "numeric", minute: "numeric", hour12: true })
        .toLowerCase()
        .replace(" ", "");

      // replace 0: with 12:
      if (title.startsWith("0:")) {
        title = title.replace("0:", "12:");
      }

      clonedTemplate.querySelector(".Calendar-Event-Time").textContent = title;

      if (this.showSlotDetails) {
        const slot = item.number_of_available_slots > 1 ? "Slots" : "Slot";
        clonedTemplate.querySelector(
          ".Calendar-Event-Slots"
        ).textContent = `${item.number_of_available_slots} ${slot}`;

        const openToNewStudents = item.open_to_new_students == true;
        const openToExistingStudents = item.open_to_existing_students == true;

        // if open to both show "New Students | Current Students" else show "New Students" or "Current Students"
        if (openToNewStudents && openToExistingStudents) {
          clonedTemplate.querySelector(
            ".Calendar-Event-StudentType"
          ).textContent = "New + Current Students";
        }
        if (openToNewStudents && !openToExistingStudents) {
          clonedTemplate.querySelector(
            ".Calendar-Event-StudentType"
          ).textContent = "New Students";
        }
        if (!openToNewStudents && openToExistingStudents) {
          clonedTemplate.querySelector(
            ".Calendar-Event-StudentType"
          ).textContent = "Current Students";
        }
      } else {
        clonedTemplate.querySelector(".Calendar-Event-Slots").textContent = "";
      }

      const getHoursNumberToTime = (hoursNumber) => {
        var ampm = hoursNumber >= 12 ? "pm" : "am";
        hoursNumber = hoursNumber % 12;
        hoursNumber = hoursNumber ? hoursNumber : 12; // If hours is 0 then make it 12.
        var strHours = hoursNumber < 10 ? "0" + hoursNumber : hoursNumber; // If hours is less than 10, prefix a '0'.
        return strHours + ampm;
      };

      earliestTime = Math.min(earliestTime, startDateTime.hour - 1);
      latestTime = Math.max(latestTime, startDateTime.hour + 1);

      clonedTemplate
        .querySelector(".Calendar-Event")
        .setAttribute("data-day", item.day);

      clonedTemplate
        .querySelector(".Calendar-Event")
        .setAttribute("data-duration", item.duration);
      clonedTemplate
        .querySelector(".Calendar-Event")
        .setAttribute(
          "data-time-hours",
          getHoursNumberToTime(startDateTime.hour)
        );
      clonedTemplate
        .querySelector(".Calendar-Event")
        .setAttribute(
          "data-time-mins",
          startDateTime.minute.toString().padStart(2, "0")
        );
      clonedTemplate
        .querySelector(".Calendar-Event")
        .setAttribute("data-start-date-time", item.start_date_time);
      clonedTemplate
        .querySelector(".Calendar-Event")
        .setAttribute("data-start-iso-time", item.full_date);

      clonedTemplate
        .querySelector(".Calendar-Event")
        .setAttribute("data-instructor-ids", item.instructor_ids);

      clonedTemplate
        .querySelector(".Calendar-Event")
        .setAttribute("data-vehicle-ids", item.vehicle_ids);

      if (item.ineligible) {
        optionalChaining(() =>
          clonedTemplate
            .querySelector(".Calendar-Event")
            .classList.add("Calendar-Event--ineligible")
        );
      }

      if (item.pre_selected == true) {
        clonedTemplate
          .querySelector(".Calendar-Event")
          .setAttribute("data-pre-selected", "true");
        clonedTemplate
          .querySelector(".Calendar-Event")
          .setAttribute("data-reserved-lesson-id", item.reserved_lesson_id);
      }

      this.targets.find("eventContainer").appendChild(clonedTemplate);
    });

    earliestTime = Math.min(7, Math.max(earliestTime, 0));
    latestTime = Math.max(18, Math.min(latestTime, 24));

    const weeklySlotsElement = this.element.getElementsByClassName(
      "Calendar--weeklySlots"
    );
    if (weeklySlotsElement.item(0)) {
      let styleString = "auto 30px ";
      for (let i = 0; i <= 24; i++) {
        if (i >= earliestTime && i <= latestTime) {
          styleString += "60px ";
        } else {
          styleString += "0px ";
        }
      }
      weeklySlotsElement.item(0).style.gridTemplateRows = styleString;
    }
  }

  clickOnFirstSlot() {
    const firstSlot = this.element.querySelector(".Calendar-Event");
    if (firstSlot) {
      firstSlot.click();
    }
  }

  clickPreSelectedSlot() {
    const preSelectedSlot = this.element.querySelector(
      "[data-pre-selected='true']"
    );
    if (preSelectedSlot) {
      preSelectedSlot.click();
    }
  }

  setTitle(title) {
    this.titleTarget.innerHTML = title;
  }

  setTimezoneLabel(timezone) {
    this.timezoneLabelTarget.innerHTML = timezone;
  }

  async deleteAllEvents() {
    this.eventContainerTarget.innerHTML = "";
  }

  hideError() {
    this.errorContainerTarget.hidden = true;
  }

  showError(message) {
    this.errorContainerTarget.innerHTML = message;
    this.errorContainerTarget.hidden = false;
  }

  populate_weekday_labels(weekdays) {
    this.dayLabelTargets.forEach((element, index) => {
      element.textContent = weekdays[index];
    });
  }

  // Shows the loading animation
  showLoader() {
    this.loadingContainerTarget.classList.remove("hidden");
    this.loadedContainerTarget.classList.add("hidden");
  }

  // Hides the loading animation
  hideLoader() {
    this.loadingContainerTarget.classList.add("hidden");
    this.loadedContainerTarget.classList.remove("hidden");
  }

  hideJumpToDateContainer() {
    this.jumpToDateContainerTarget.hidden = true;
    this.dateTarget.hidden = false;
  }

  showJumpToDateContainer() {
    this.jumpToDateContainerTarget.hidden = false;
    this.dateTarget.hidden = true;
  }

  setJumpToDateValueToCurrentIsoString() {
    const luxonDate = luxon.DateTime.fromISO(this.timeIsoString, {
      zone: this.timezone,
    }).startOf("week");
    this.jumpToDateInputTarget.value = luxonDate.toISODate();
  }
}
