import { Controller } from "@hotwired/stimulus";

export default class extends Controller {


  static targets = [
    "checkAvailabilityButton"
  ]

  connect() {
    console.log(this);
  }

  async findSlotsPressed(event) {
    event.preventDefault();

    let button = this.checkAvailabilityButtonTarget;
    let spinner = button.querySelector(".Spinner");
    spinner.classList.remove("u-hidden");
    spinner.classList.add("u-inlineBlock");
    button.disabled = true;
    
    const errors = this.validateForm();
    const validationBlock = document.getElementById("validation-address-block");
    const validationMessage = document.getElementById("validation-message-address-text");
    
    if (errors.length > 0) {
      validationBlock.hidden = false;
      validationMessage.innerHTML = errors.join("<br>");
      validationMessage.hidden = false;
    } else {
      validationBlock.hidden = true;
      validationMessage.hidden = true;

      let address_line_1 = document.getElementById("address_line_1_value").value;
      let zipcode = document.getElementById("zipcode_value").value;
      
      const driving_school_token = document
        .getElementById("us-school-token")
        .innerHTML.trim();

      const url = `/fetch_locations?zipcode=${zipcode}&address=${address_line_1}&driving_school_token=${driving_school_token}`;
      const response = await fetch(url, {
        method: "GET",
        headers: { 
          "Accept": "application/json", // Expecting JSON response
          "Content-Type": "application/json" 
        },
      });

      if (response.ok) {
        const data = await response.json(); // Parse the JSON response
      
        // Check if address is in zone
        if (data.is_address_in_zone) {
          
          let data_to_return =
          {
            "pick_up_address_line_1": address_line_1,
            "pick_up_zipcode": zipcode,
            "drop_off_address_line_1": address_line_1,
            "drop_off_zipcode": zipcode,
            "is_address_in_zone": data.is_address_in_zone,
            "zone_name": data.matching_zone.name,
            "zone_color": data.matching_zone.color,
            "zone_id": data.matching_zone.id
          }

          this.element.dispatchEvent(new CustomEvent('addressFound', {
            detail: { formName: 'driving-school-admin-booking-form-v2-address-details',
            locationData: data_to_return }
          }));

          let button = this.checkAvailabilityButtonTarget;
          let spinner = button.querySelector(".Spinner");
          spinner.classList.add("u-hidden");
          spinner.classList.remove("u-inlineBlock");
          button.disabled = false;
          
          
        } else {

          let data_to_return =
          {
            "pick_up_address_line_1": address_line_1,
            "pick_up_zipcode": zipcode,
            "drop_off_address_line_1": address_line_1,
            "drop_off_zipcode": zipcode,
            "is_address_in_zone": data.is_address_in_zone,
            "zone_name": 'Outside of zone',
            "zone_color": '#000000',
            "zone_id": 0
          }

          // Address is not in zone, so now check if locations or schools are empty
          if (data.locations.length === 0 && data.schools.length === 0) {
            alert('No locations or schools available');
            // Handle the logic for when both are empty
          } else {
            
            const url = `/fetch_locations?zipcode=${zipcode}&address=${address_line_1}&driving_school_token=${driving_school_token}`;
            const response = await fetch(url, {
              method: "GET",
              headers: { 
                "Accept": "text/vnd.turbo-stream.html"
              },
            });
          
            if (response.ok) {
              const stream = await response.text();
              validationMessage.hidden = true;
              Turbo.renderStreamMessage(stream);
      
              // find us-booking-form-admin-pick-up-and-slots and show it
              const bookingFormAdminPickUpAndSlots = document.getElementById(
                "us-booking-form-admin-pick-up-and-slots"
              );
              if (bookingFormAdminPickUpAndSlots) {
                bookingFormAdminPickUpAndSlots.hidden = false;
              }
      
              // find us-booking-form-admin-location and hide it
              const bookingFormAdminLocation = document.getElementById(
                "us-booking-form-admin-location"
              );
              if (bookingFormAdminLocation) {
                bookingFormAdminLocation.hidden = true;
              }
      
              this.element.dispatchEvent(new CustomEvent('addressOutsideZone', {
                detail: { formName: 'driving-school-admin-booking-form-v2-address-details',
                locationData: data_to_return }
              }));

            } else {
              // Handle error
              validationMessage.innerHTML =
                "The address you entered is not valid or is not covered by this school. Please try again.";
              validationMessage.hidden = false;

              let button = this.nextButtonTarget;
              let spinner = button.querySelector(".Spinner");
              spinner.classList.add("u-hidden");
              spinner.classList.remove("u-inlineBlock");
              button.disabled = false;
            }





          }
        }
      }
      else {
        alert('response is not ok')
        // // Handle error
        // validationMessage.innerHTML =
        //   "The address you entered is not valid or is not covered by this school. Please try again.";
        // validationMessage.hidden = false;
      }
    }
  }


  validateForm() {
    let validationErrors = [];

    const requiredFields = [
      { id: "address_line_1_value", name: "Address line 1" },
      { id: "zipcode_value", name: "Zipcode" },
    ];

    requiredFields.forEach((field_data) => {
      let field = document.getElementById(field_data.id);

      if (field) {
        if (field.value.trim() === "") {
          validationErrors.push(`${field_data.name} cannot be empty.`);
        }
        if (
          field_data.id === "zipcode_value" &&
          field.value.trim().length != 5
        ) {
          validationErrors.push(`${field_data.name} must be 5 numbers.`);
        }
      }
    });

    return validationErrors;
  }

}