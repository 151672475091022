import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.initializeDatePicker();
  }

  initializeDatePicker() {
    flatpickr(this.element, {
      dateFormat: "j F, Y",
    });
  }
}
