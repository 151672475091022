import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "enquiryId", "body", "instructorOffer", "button", "modal", "enquiryContainer"];

  open_message_quick_view(event) {
    // find message-pupil-test and remove u-hidden
    const message_pupil_test = document.getElementById("message-pupil-test");
    message_pupil_test.classList.remove("u-hidden");

    // call API to mark message as read
    ///schools/enquiries/:enquiry_id/mark-text-as-read
    const id = event.target.dataset.enquiryIdTarget;
    const url = `/schools/enquiries/${id}/mark-text-as-read`;

    fetch(url, { 
      method: 'POST', 
    headers: { 
      "Accept": "application/json", 
      "Content-Type": "application/json" ,
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')

  } })
    .then(response => response.json())
    .then(data => {
      console.log(data)
    })
    .catch(error => console.error(error));
  }

  closeQuickLook(event) {
    const message_pupil_test = document.getElementById("message-pupil-test");
    message_pupil_test.classList.add("u-hidden");
  }

  toggleEnquiry(event) {
    if (this.enquiryContainerTarget.innerHTML === "") {
      this.loadEnquiry(event)
    } else {
      this.hideEnquiry()
    }
  }

  getFormattedDate(dateObj) {
    const day = dateObj.getDate();
    const year = dateObj.getFullYear();
    const month = dateObj.toLocaleString('en-US', { month: 'long' });
    const formattedDate = `${day} ${month}, ${year}`;
    return formattedDate
  }

  getFormattedTime(dateObj) {
    const hours = dateObj.getHours();
    const minutes = dateObj.getMinutes();
    const formattedHours = hours < 10 ? `0${hours}` : hours; // Add leading zero for hours less than 10
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes; // Add leading zero for minutes less than 10
    const formattedTime = `${formattedHours}:${formattedMinutes}`;
    return formattedTime;
  }

  selectCalendarTab(event) {
    const id = event.target.dataset.instructorId;
    let enquiryCalendar = document.getElementById(`enquiry-${id}-calendar`);
    enquiryCalendar.classList.remove("u-hidden")

    let enquirySlots = document.getElementById(`enquiry-${id}-slots`);
    enquirySlots.classList.add("u-hidden")

    let enquiryCalendarTab = document.getElementById(`enquiry-${id}-calendar-tab`);
    let enquirySlotsTab = document.getElementById(`enquiry-${id}-slots-tab`);

    enquiryCalendarTab.classList.add("is-active")
    enquirySlotsTab.classList.remove("is-active")
  }

  selectSlotTab(event) {
    const id = event.target.dataset.instructorId;
    let enquirySlots = document.getElementById(`enquiry-${id}-slots`);
    enquirySlots.classList.remove("u-hidden")

    let enquirySlotsTab = document.getElementById(`enquiry-${id}-slots-tab`);
    enquirySlotsTab.classList.add("is-active")

    let enquiryCalendar = document.getElementById(`enquiry-${id}-calendar`);
    enquiryCalendar.classList.add("u-hidden")

    let enquiryCalendarTab = document.getElementById(`enquiry-${id}-calendar-tab`);
    enquiryCalendarTab.classList.remove("is-active")
  }

  async loadEnquiry(event) {
    const startTime = event.target.dataset.enquiriesLessonStart
    const lessonId = event.target.dataset.enquiriesLessonId
    const instructorId = event.target.dataset.enquiriesInstructorId

    $(`#is_open_slot_${instructorId}`).val("true")
    $(`#open_slot_lesson_${instructorId}`).val(lessonId)

    const dateObj = new Date(startTime);

    this.openBookLesson(dateObj, instructorId, false);
  }

  async openBookLesson(dateObj, instructorId, isCurrentTime = true) {
    if (isCurrentTime) { dateObj.setHours(0, 0, 0, 0) }

    const formattedDate = this.getFormattedDate(dateObj)
    const formattedTime = this.getFormattedTime(dateObj)

    $(`#date-picker-1-${instructorId}`).val(formattedDate)

    $(`#date-picker-1-${instructorId}`).on("mousedown", (event) => {
      event.preventDefault()
      return
    })

    $(`#time-picker-1-${instructorId}`).on("mousedown", (event) => {
      event.preventDefault()
      return
    })

    $(`#time-picker-1-${instructorId}`).val(formattedTime)

    let time_format_hour = 9
    let time_format_minutes = 0
    
    if (formattedTime != undefined && formattedTime != "") {
      // For a time format like "09:45" (24-hour format)
      const timeParts = formattedTime.split(":");
      time_format_hour = parseInt(timeParts[0], 10); // Parse hours
      time_format_minutes = parseInt(timeParts[1], 10); // Parse minutes
  }
  
    

    $(`#time-picker-1-${instructorId}`).pickatime({
      min: [5,0],
      max: [22,0],
      select: [time_format_hour,time_format_minutes], 
      interval: 15,
      onSet: (event) => {
        $(this).hide()
        window.event.stopPropagation()
      }
    })


    // Get the picker object
    const timePicker = $(`#time-picker-1-${instructorId}`).pickatime('picker');

    // Set default time, for example, 9:30
    timePicker.set('select', [time_format_hour,time_format_minutes]);

    $(`#date-picker-1-${instructorId}`).pickadate({
      onClose: (event) => {
        $(this).hide()
        $("#date_time_validation_error").hide()
        window.event.stopPropagation()
      }
    })

    const offerInstructor = document.getElementById(`modal-${instructorId}-instructor`);
    offerInstructor.classList.toggle("hidden")
  }

  hideEnquiry() {
    this.enquiryContainerTarget.innerHTML = ""
  }

  toggle() {
    this.bodyTarget.classList.toggle("hidden");
  }

  offerInstructor(event) {
    const id = event.target.parentElement.dataset.id
    const offerInstructor = document.getElementById(`enquiry-${id}-instructor`);
    offerInstructor.classList.toggle("hidden")
    // window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  onClick(event) {

    // disable button
    const button = event.target;
    button.disabled = true;

    const schoolToken = event.target.dataset.schoolToken
    const instructorToken = event.target.dataset.instructorToken
    
    const url = '/s/' + schoolToken + '/offer-to-instructor/' + instructorToken
    window.location = url
  }

  sendOnEnquiryForDrive(event) {
    const instructorToken = event.target.dataset.instructorToken
    
    let element = document.getElementById('send-on-enquiry-button');
    element.setAttribute('data-instructor-token-value', instructorToken);

    // check data-instructor-token-value is not XXX and if it is, show error message
    if (instructorToken == "XXX") {
      alert("Please refresh and try again, if the problem persists, please contact us.");
      return;
    }

    // find instructor-token-for-just-drive and set value to instructorToken
    const instructorTokenForJustDrive = document.getElementById(`instructor-token-for-just-drive`);
    if (instructorTokenForJustDrive) {
      instructorTokenForJustDrive.value = instructorToken;
    }

    const message_pupil_test = document.getElementById("send-on-enquiry-for-drive");
    message_pupil_test.classList.remove("u-hidden");
  }

  closeSendOnEnquiryQuickLook(event) {
    const message_pupil_test = document.getElementById("send-on-enquiry-for-drive");
    message_pupil_test.classList.add("u-hidden");
  }

  openLessonModal(event) {
    const instructorId = event.target.dataset.id
    const dateObj = new Date();
    $(`#is_open_slot_${instructorId}`).val("false")
    $(`#open_slot_lesson_${instructorId}`).val("")
    this.openBookLesson(dateObj, instructorId);
  }

  formSubmit() {

    // disable button find lesson-enquiry-book and set disabled to true
    const button = document.getElementById('lesson-enquiry-book');
    if (button) {
      button.disabled = true;
    }

    const id = event.target.dataset.id
    const form = document.getElementById(`lessonEnquiryForm-${id}`);
    form.submit();
  }  

  formSubmitAndRequestPayment() {

    // disable button find lesson-enquiry-book and set disabled to true
    const button = document.getElementById('lesson-enquiry-book');
    if (button) {
      button.disabled = true;
    }

    const id = event.target.dataset.id
    const form = document.getElementById(`lessonEnquiryForm-${id}`);
    form.submit();
  }  

}