import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["tab"];

  connect() {}

  updateEventDetail() {
    const eventId = document.getElementById("us-event-id").innerHTML.trim();
    if (eventId) {
      this.makeCallToApi(eventId);
    }
  }

  async makeCallToApi(eventId) {
    const request_params = this.requestParams();
    const url = `/schools/event/${eventId}/quick-look/edit-event`;
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "text/vnd.turbo-stream.html",
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
      body: JSON.stringify({
        ...request_params,
      }),
    });
    if (response.ok) {
      const stream = await response.text();
      Turbo.renderStreamMessage(stream);
    } else {
      return { errorMessage: "Something went wrong" };
    }
  }

  requestParams() {
    const eventTitle = optionalChaining(
      () => document.getElementById("event_title").value || null
    );
    const eventNotes = optionalChaining(
      () => document.getElementById("event_notes").value || null
    );
    const eventNewStartDate = optionalChaining(
      () => document.getElementById("event_new_start_date").value || null
    );
    const eventNewStartTime = optionalChaining(
      () => document.getElementById("event_new_start_time").value || null
    );
    const eventNewEndDate = optionalChaining(
      () => document.getElementById("event_new_end_date").value || null
    );
    const eventNewEndTime = optionalChaining(
      () => document.getElementById("event_new_end_time").value || null
    );

    const usEventInstructorId = optionalChaining(
      () => document.getElementById("us-event-instructor-id").value || null
    );

    return {
      eventTitle: eventTitle ? eventTitle : undefined,
      eventNotes: eventNotes ? eventNotes : undefined,
      eventNewStartDate: eventNewStartDate ? eventNewStartDate : undefined,
      eventNewStartTime: eventNewStartTime ? eventNewStartTime : undefined,
      eventNewEndDate: eventNewEndDate ? eventNewEndDate : undefined,
      eventNewEndTime: eventNewEndTime ? eventNewEndTime : undefined,
      usEventInstructorId: usEventInstructorId
        ? usEventInstructorId
        : undefined,
    };
  }
}

function optionalChaining(valueFn) {
  try {
    return valueFn();
  } catch (e) {
    return undefined;
  }
}
