import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "instructorRadio",
    "selectedVehicleInput",
    "slot_premium_instructor",
    "addSlotButton",
  ];

  connect() {
    const locationContainer = document.getElementById("booking-location-quicklook-wrapper");

    if (locationContainer) {
      this.observer = new MutationObserver(() => {
        this.checkForLocationEdit();
      });

      this.observer.observe(locationContainer, { childList: true, subtree: true });
    }
  }

  disconnect() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  checkForLocationEdit() {
    const locationEditForm = document.getElementById("quicklook-schedule-location-edit");
    const saveButton = document.getElementById("school_open_slot_quick_look_book_slot_submit");

    if (saveButton) {
      if (locationEditForm) {
        saveButton.setAttribute("disabled", "disabled");
      } else {
        saveButton.removeAttribute("disabled");
      }
    }
  }

  isADurationSelected() {
    var durationSelectElement = document.querySelectorAll(
      "select[name='slot_duration']"
    );
    if (!durationSelectElement) {
      return null;
    }
    return parseInt(durationSelectElement[0].value);
  }

  isAnInstructorSelected() {
    // Select all radio inputs for instructors
    var instructorRadios = document.querySelectorAll(
      'input[name="selected-item-instructor"]'
    );

    // Iterate through each radio input to check if it's selected
    for (var i = 0; i < instructorRadios.length; i++) {
      if (instructorRadios[i].checked) {
        // If a radio is checked, an instructor is selected
        return instructorRadios[i].getAttribute("data-id");
      }
    }

    // If the slot_premium_instructor is present, check if it has a value
    if (
      this.hasSlot_premium_instructorTarget &&
      this.slot_premium_instructorTarget.value != ""
    ) {
      return this.slot_premium_instructorTarget.value;
    }

    // If none are selected, return false
    return null;
  }

  getCheckedElements(selector) {
    var elements = document.querySelectorAll(selector);
    var checkedElements = [];

    elements.forEach(function (element) {
      if (element.checked) {
        // Add the ID of the checked element to the array
        // You can change 'id' to any other attribute you need
        checkedElements.push(element.id);
      }
    });

    // Return null if no elements are checked, or the array of checked element IDs
    return checkedElements.length > 0 ? checkedElements : null;
  }

  displayErrors(errors) {
    if (errors.length > 0) {
      document.getElementById("error-messages").innerHTML = errors.join("<br>");
      document
        .getElementById("error-messages-panel")
        .classList.remove("u-hidden");
    }
  }

  addSlot(event) {
    event.preventDefault();
    document.getElementById("add-slot-button").disabled = true;

    let button = this.addSlotButtonTarget;
    let spinner = button.querySelector(".Spinner");
    spinner.classList.remove("u-hidden");
    spinner.classList.add("u-inlineBlock");

    let error = [];

    let instructor_id = this.isAnInstructorSelected();
    if (instructor_id == null) {
      error.push("Please select an instructor");
    }

    let duration = this.isADurationSelected();
    if (duration == null) {
      error.push("Please select a duration");
    }

    let zone_ids = this.getCheckedElements('[id^="zone_"]');
    if (zone_ids == null) {
      error.push("Please select at least one zone");
    }

    let vehicle_ids = this.getCheckedElements('[id^="vehicle_"]');
    if (vehicle_ids == null) {
      error.push("Please select at least one vehicle");
    }

    // make sure start_date and start_time are filled in
    if (
      document.getElementById("start_date").value == "" ||
      document.getElementById("start_time").value == ""
    ) {
      error.push("Please select a date and time");
    }

    // make sure available_to_current_students or available_to_new_students is checked
    if (
      document.getElementById("available_to_current_students").checked ==
        false &&
      document.getElementById("available_to_new_students").checked == false
    ) {
      error.push("Please select at least one student type");
    }

    if (error.length > 0) {
      document.getElementById("add-slot-button").disabled = false;
      this.displayErrors(error);

      // Remove the spinner
      spinner.classList.add("u-hidden");
      spinner.classList.remove("u-inlineBlock");
    } else {
      // hide error message
      document.getElementById("error-messages").innerHTML = "";

      document.getElementById("error-messages-panel").classList.add("u-hidden");

      let format_data = {
        start_date: document.getElementById("start_date").value,
        start_time: document.getElementById("start_time").value,
        start_time_iso: document.getElementById("start_time_iso").value,
        instructor_id: instructor_id,
        duration: duration,
        vehicle_ids: vehicle_ids,
        zone_ids: zone_ids,
        available_to_current_students: document.getElementById(
          "available_to_current_students"
        ).checked,
        available_to_new_students: document.getElementById(
          "available_to_new_students"
        ).checked,
      };

      // post to the server
      fetch("/schools/lesson/add-lesson/quick-look/add-slot", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": document.querySelector("meta[name=csrf-token]")
            .content,
        },
        body: JSON.stringify(format_data),
      })
        .then((response) => {
          if (!response.ok) {
            document.getElementById("add-slot-button").disabled = false;
            // If the response status is not okay, throw an error
            return response.json().then((data) => {
              throw new Error(data.errors.join("<br>")); // Assuming errors are in an array
            });
          }
          return response.json();
        })
        .then((data) => {
          document
            .getElementById("success-messages-panel")
            .classList.remove("u-hidden");
          // make add-slot-button disabled
          // Remove the spinner
          spinner.classList.add("u-hidden");
          spinner.classList.remove("u-inlineBlock");
        })
        .catch((error) => {
          document.getElementById("add-slot-button").disabled = false;
          // Handle errors (both client-side and server-side)
          this.displayErrors([error.message]);
          // Remove the spinner
          spinner.classList.add("u-hidden");
          spinner.classList.remove("u-inlineBlock");
        });
    }
  }

  setInstructorUsingDropdownWidget(event) {
    const instructorId = event.detail.dispatchItem.id;
    this.setInstructor({ target: { dataset: { id: instructorId } } });
  }

  setVehicleUsingDropdownWidget(event) {
    const vehicleId = event.detail.dispatchItem.id;
    this.selectedVehicleInputTarget.value = vehicleId;
  }

  setInstructor(event) {
    // from the  data-id html tag, get the value
    const instructorId = event.target.dataset.id;

    // set the value of student_lesson_premium_instructor_profile_id to the instructorId
    if (document.getElementById("student_lesson_premium_instructor_profile_id"))
      document.getElementById(
        "student_lesson_premium_instructor_profile_id"
      ).value = instructorId;

    if (
      document.getElementById(
        "premium_instructor_profile_premium_instructor_profile_id"
      )
    )
      document.getElementById(
        "premium_instructor_profile_premium_instructor_profile_id"
      ).value = instructorId;

    // If slot_premium_instructor is present, set the value to the instructorId
    if (this.hasSlot_premium_instructorTarget)
      this.slot_premium_instructorTarget.value = instructorId;

    this.instructorRadioTargets.forEach((radio) => {
      if (`${radio.value}` == `${instructorId}`) {
        radio.checked = true;
      } else {
        radio.checked = false;
      }
    });

    // find all the items that start with the ID selected-item-instructor
    const selectedItems = document.querySelectorAll(
      '[id^="selected-item-instructor"]'
    );

    selectedItems.forEach((item) => {
      if (item.dataset.id == instructorId) {
        item.value = true;
      } else {
        item.value = false;
      }
    });
  }

  setVehicle(event) {
    // from the  data-id html tag, get the value
    const vehicleId = event.target.value;

    // set the value of student_lesson_premium_instructor_profile_id to the instructorId
    document.getElementById("student_lesson_vehicle_id").value = vehicleId;
  }

  showOutOfZoneInstructors(event) {
    event.preventDefault();

    // check if instructors-out-of-zone is hidden
    if (
      document
        .getElementById("instructors-out-of-zone")
        .classList.contains("u-hidden")
    ) {
      document
        .getElementById("instructors-out-of-zone")
        .classList.remove("u-hidden");
    } else {
      document
        .getElementById("instructors-out-of-zone")
        .classList.add("u-hidden");
    }
  }
}
