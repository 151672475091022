import { DateTime, IANAZone } from 'luxon';

/**
 * Returns an abbreviation for a timezone, based on your locality
 * @param {string} timezone 
 * @returns 
 */
export function getOffsetNamesForTimezone(timezone) {
    const offsetNames = new Set();

    const today = DateTime.now().setZone(timezone);
    for(let i = 0; i < 365; i++) {
        const dt = today.plus({days: i});
        const offsetName = dt.offsetNameShort;
        offsetNames.add(offsetName);
    }

    return Array.from(offsetNames).join("/");
}