import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["link", "formContainer"];

  connect() {
    // this.checkInstructorsAndLoadForm();
  }

  checkInstructorsAndLoadForm() {
    const instructors = this.linkTargets;
    if (instructors.length === 1) {
      const profileId = instructors[0].dataset.profileId;
      this.loadFormForInstructor(profileId);
    }
  }

  async loadFormForInstructor(profileId) {
    const url = `/schools/fleet/assignments/form/${profileId}`;

    try {
      const response = await fetch(url);
      const html = await response.text();

      this.formContainerTarget.innerHTML = html;
    } catch (error) {
      console.error("Error fetching form:", error);
    }
  }

  async loadForm(event) {
    event.preventDefault();

    const profileId = event.currentTarget.dataset.profileId;
    
    this.loadFormForInstructor(profileId);
  }

  async showInstructorAssignmentsForm(event) {
    event.preventDefault();

    const instructorId = this.data.get("instructorId");
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;

    const response = await fetch(`/schools/fleet/assignments/form/${instructorId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "X-CSRF-Token": csrfToken,
        "Accept": "text/vnd.turbo-stream.html"
      }
    });

    if (response.ok) {
      const responseBody = await response.text();
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(responseBody, "text/html");
      const template = xmlDoc.querySelector("template");
      const targetElement = document.getElementById("vehicles_assignment_panel");

      if (template && targetElement) {
        targetElement.innerHTML = "";
        targetElement.appendChild(template.content.cloneNode(true));
      }
    }

  }
}