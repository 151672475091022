import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ "output" ]
  static values = { showIf: String }

  connect() {
    this.hide()
  }

  hide() {
    this.outputTarget.classList.add('u-hidden')
    this.outputTarget.classList.remove('u-tableBody')
    document.getElementById('show_button').classList.remove('u-hidden')
    return
  }

  show(event) {
    this.outputTarget.classList.remove('u-hidden')
    this.outputTarget.classList.add('u-tableBody')
    event.target.classList.add('u-hidden')
    document.getElementById('driving_schools_package_name').focus()
    return
  }
}
