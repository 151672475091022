import { Controller } from "stimulus";
import { Turbo } from "@hotwired/turbo-rails";

export default class extends Controller {
  static targets = ["spinner"];

  connect() {
    document.addEventListener("turbo:submit-start", this.showSpinner.bind(this));
    document.addEventListener("turbo:submit-end", this.hideSpinner.bind(this));
  }

  disconnect() {
    document.removeEventListener("turbo:submit-start", this.showSpinner.bind(this));
    document.removeEventListener("turbo:submit-end", this.hideSpinner.bind(this));
  }

  showSpinner() {
    
    this.spinnerTarget.classList.remove('u-hidden');
    this.spinnerTarget.classList.add('u-inlineBlock');
    this.element.disabled = true;
  }

  hideSpinner() {
    this.spinnerTarget.classList.add('u-hidden');
    this.spinnerTarget.classList.remove('u-inlineBlock');
    this.element.disabled = false;
  }
}
