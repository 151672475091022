import { Controller } from "@hotwired/stimulus";

export default class extends Controller {


  payCancelledLesson(event) {
    event.preventDefault();
    const url = this.data.get('payUrl');
    window.location.href = url;
  }

  takeOnlinePayment(event) {
    const lessonId = document.getElementById('lesson-id').value;
    window.location.href = `/schools/us/dashboard/lessons/${lessonId}/no-show/take-online-payment`
  }

  waiveFeeJson(event) {
    event.preventDefault(); // Prevent the default form submission

    let lessonId = event.currentTarget.dataset.lessonId; 
    if (lessonId == null) {
      lessonId = document.getElementById('lesson-id').value;
    }

    const url = `/schools/us/dashboard/lessons/${lessonId}/no_show/waive-fee`;

    fetch(url, { method: 'GET', headers: {
      "Accept": "application/json",
       "Content-Type": "application/json"
     }})
      .then(response => response.text())
      .then(content => {
        alert('Fee waived');
        window.location.reload();
      })
      .catch(error => 
        alert('Error waiving fee')
      );
  }

  waiveFee(event) {
    event.preventDefault(); // Prevent the default form submission

    let lessonId = event.currentTarget.dataset.lessonId; 
    if (lessonId == null) {
      lessonId = document.getElementById('lesson-id').value;
    }

    const url = `/schools/us/dashboard/lessons/${lessonId}/no_show/waive-fee`;

    fetch(url, { method: 'GET', headers: { "Accept": "text/vnd.turbo-stream.html", 'Turbo-Frame': 'school_lesson_no_show_payment' } })
      .then(response => response.text())
      .then(content => {
        Turbo.renderStreamMessage(content)
      })
      .catch(error => console.error(error));
  }

  sendEmailReminder(event) {
    event.preventDefault(); // Prevent the default form submission
    const lessonId = document.getElementById('lesson-id').value;

    const url = `/schools/us/dashboard/lessons/${lessonId}/no_show/email-reminder`;

    fetch(url, { method: 'GET', headers: { "Accept": "text/vnd.turbo-stream.html", 'Turbo-Frame': 'school_lesson_no_show_payment' } })
      .then(response => response.text())
      .then(content => {
        Turbo.renderStreamMessage(content)
      })
      .catch(error => console.error(error));
  }
}
