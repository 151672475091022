import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "button",
    "fieldContainer",
    "emailField",
    "updateButton",
    "error",
    "emailPlaceholder",
  ];

  showEmailField() {
    this.buttonTarget.style.display = "none";
    this.fieldContainerTarget.style.display = "block";
  }

  updateEmail() {
    const email = this.emailFieldTarget.value;
    const pupilId = document.getElementById('pupil_id').value;
    console.log(pupilId);
    // console.log(this.data);
    // const pupilId = this.data.get("pupilId");
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;

    // Validate the email address
    if (!this.validateEmail(email)) {
      this.showError("Invalid email address");
      return;
    }

    fetch("/bookings/pupil/email/update", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
        // Additional headers if required
      },
      body: JSON.stringify({ pupil_id: pupilId, email }),
    })
      .then((response) => {
        if (response.ok) {
          // Email updated successfully
          this.buttonTarget.style.display = "block";
          this.fieldContainerTarget.style.display = "none";
          this.emailFieldTarget.value = "";
          this.hideError();

          // Update the email placeholder
          const emailPlaceholder = this.data.get("emailPlaceholder");
          const updatedEmail = email || emailPlaceholder;
          this.data.set("emailPlaceholder", updatedEmail);
          this.updateEmailPlaceholder();
        } else {
          // Handle error response if needed
          throw new Error("Failed to update email");
        }
      })
      .catch((error) => {
        // Handle error if needed
        console.error(error);
      });
  }

  updateEmailPlaceholder() {
    const emailPlaceholder = this.data.get("emailPlaceholder");
    const emailPlaceholderTarget = this.emailPlaceholderTarget;
    if (emailPlaceholderTarget) {
      emailPlaceholderTarget.textContent = emailPlaceholder;
    }
  }

  validateEmail(email) {
    // Add your email validation logic here
    // This is a simple example
    const emailRegex = /\S+@\S+\.\S+/;
    return emailRegex.test(email);
  }

  showError(message) {
    this.errorTarget.textContent = message;
    this.errorTarget.style.display = "block";
  }

  hideError() {
    this.errorTarget.style.display = "none";
  }
}
