import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  arrowsInformation = [];
  // TODO: This is rubbish should have been a bettwr way to do this
  textAboveLine = !!document.querySelector(
    ".Calendar-Wrapper--dailyInstructorHorizontal"
  );

  // Every minute we recheck lesson travel time
  timer = setInterval(() => {
    this.updateDisplayText();
  }, 20 * 1000);

  mouseListener = (event) => {
    this.arrowsInformation.forEach((arrowInformation) => {
      const svg = document.getElementById(arrowInformation.svgId);
      if (!svg) {
        return;
      }

      // Check if the mouse is near the last point
      const centerX = (arrowInformation.lastX1 + arrowInformation.lastX2) / 2;
      const centerY = (arrowInformation.lastY1 + arrowInformation.lastY2) / 2;
      const diameter = Math.max(
        Math.abs(arrowInformation.lastX1 - arrowInformation.lastX2),
        Math.abs(arrowInformation.lastY1 - arrowInformation.lastY2),
        200
      );

      if (
        event.clientX > centerX - diameter / 2 &&
        event.clientX < centerX + diameter / 2 &&
        event.clientY > centerY - diameter / 2 &&
        event.clientY < centerY + diameter / 2 &&
        !arrowInformation.isDrawing
      ) {
        // opacity should range from 0 to 1, with 0 being just inside the circle and 1 being at the ecenter of the circle
        const distance = Math.sqrt(
          Math.pow(centerX - event.clientX, 2) +
            Math.pow(centerY - event.clientY, 2)
        );
        const opacity = 1 - distance / (diameter / 2) / 2;
        svg.style.opacity = opacity;
      } else {
        svg.style.opacity = 0.5;
      }
    });
  };

  moveListener = () => {
    this.arrowsInformation.forEach((arrowInformation) => {
      const svg = document.getElementById(arrowInformation.svgId);
      if (svg) {
        svg.style.opacity = 0.5;
      }
      this.createAndDrawLinIfNecessary(arrowInformation.objectId);
    });
  };

  connect() {
    const lessonPairString = this.data.get("lesson-pairs");
    const lessonPairs = lessonPairString
      .split("|")
      .map((pair) => pair.split("-"));
    this.arrowsInformation = lessonPairs.map((pair) => {
      return {
        objectId: pair[0],
        nextLessonObjectId: pair[1],
        lessonId: pair[2],
        svgId: Math.random().toString(36).substring(7),
        lastX1: 0,
        lastY1: 0,
        lastX2: 0,
        lastY2: 0,
        lastChangeDetected: 0,
        lastChangeExecuted: 0,
        isDrawing: false,
        displayText: "",
      };
    });
    this.createAndDrawLinIfNecessary();
    this.setupMovementListeners(this.moveListener);
    this.listenForMouseEvents();
    this.updateDisplayText();
  }

  disconnect() {
    window.removeEventListener("mousemove", this.mouseListener);
    this.tearDownMovementListeners(this.moveListener);
    clearInterval(this.timer);
    this.arrowsInformation.forEach((arrowInformation) => {
      const svg = document.getElementById(arrowInformation.svgId);
      if (svg) {
        svg.remove();
      }
    });
  }

  createAndDrawLinIfNecessary(objectId = null) {
    let filteredArrowsInformation = this.arrowsInformation;
    if (objectId) {
      filteredArrowsInformation = this.arrowsInformation.filter(
        (arrowInformation) => arrowInformation.objectId === objectId
      );
    }

    filteredArrowsInformation.forEach((arrowInformation) => {
      const elementA = document.getElementById(arrowInformation.objectId);
      const elementB = document.getElementById(
        arrowInformation.nextLessonObjectId
      );
      let svg = document.getElementById(arrowInformation.svgId);
      if (!elementA || !elementB) {
        if (svg) {
          svg.innerHTML = "";
        }
        return;
      }
      if (!svg) {
        svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
        svg.setAttribute("id", arrowInformation.svgId);
        svg.setAttribute(
          "style",
          "position: fixed; top: 0; left: 0; width: 100%; height: 100%; pointer-events: none;"
        );
        // Z-index should be higher than the highest z-index of the elements
        svg.style.zIndex = 3;
        // Opacity starts at 0
        svg.style.opacity = 0.5;
        document.body.appendChild(svg);
      }
      const rectA = elementA.getBoundingClientRect();
      const rectB = elementB.getBoundingClientRect();
      const startX = this.textAboveLine
        ? rectA.right
        : rectA.left + rectA.width / 2;
      const startY = this.textAboveLine
        ? rectA.top + rectA.height / 2
        : rectA.bottom;
      const endX = this.textAboveLine
        ? rectB.left
        : rectB.left + rectB.width / 2;
      const endY = this.textAboveLine
        ? rectB.top + rectB.height / 2
        : rectB.top;
      if (
        startX === arrowInformation.lastX1 &&
        startY === arrowInformation.lastY1 &&
        endX === arrowInformation.lastX2 &&
        endY === arrowInformation.lastY2
      ) {
        return;
      }
      svg.innerHTML = "";
      arrowInformation.lastX1 = startX;
      arrowInformation.lastY1 = startY;
      arrowInformation.lastX2 = endX;
      arrowInformation.lastY2 = endY;

      const line = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "line"
      );
      line.setAttribute("x1", startX);
      line.setAttribute("y1", startY);
      line.setAttribute("x2", endX);
      line.setAttribute("y2", endY);
      line.setAttribute(
        "stroke",
        arrowInformation.displayText
          ? arrowInformation.lineColor
          : "transparent"
      );
      line.setAttribute("stroke-width", "3");
      svg.appendChild(line);
      const text = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "text"
      );

      // Calendar-Wrapper--dailyInstructorHorizontal
      const textAboveLine = this.textAboveLine;
      if (textAboveLine) {
        text.setAttribute("x", (startX + endX) / 2);
        text.setAttribute("y", (startY + endY) / 2 - 10);
        text.setAttribute("text-anchor", "middle");
      } else {
        // Should be right of line
        text.setAttribute("x", (startX + endX) / 2 + 10);
        text.setAttribute("y", (startY + endY) / 2);
        text.setAttribute("text-anchor", "start");
      }
      text.setAttribute("alignment-baseline", "middle");
      text.setAttribute("fill", arrowInformation.lineColor);
      text.setAttribute("font-size", "11px");
      text.textContent = arrowInformation.displayText;
      svg.appendChild(text);
    });
  }

  async updateDisplayText() {
    // We make a get request to the server to get the travel time between the two lessons
    // /schools/dashboard/calendar/calculate_minutes_to_next_lesson?lesson_id=1

    const lessonIds = this.arrowsInformation
      .map((arrowInformation) => arrowInformation.lessonId)
      .join(",");
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
    const response = await fetch(
      `/schools/dashboard/calendar/calculate_minutes_to_next_lesson?lesson_ids=${lessonIds}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "X-CSRF-Token": csrfToken,
          Accept: "application/json",
        },
      }
    );
    if (response.ok) {
      const responseBody = await response.json();
      this.arrowsInformation.forEach((arrowInformation) => {
        const time =
          responseBody[arrowInformation.lessonId] &&
          responseBody[arrowInformation.lessonId][
            "minutes_drive_to_next_lesson"
          ];

        if (time || time == 0 || time == "0") {
          arrowInformation.displayText =
            time || time == 0 || time == "0" ? `${time} mins` : "";
        }
        // Draw the line red if there is a warning
        if (
          responseBody[arrowInformation.lessonId] &&
          responseBody[arrowInformation.lessonId]["warning"]
        ) {
          arrowInformation.lineColor = "red";
        } else {
          arrowInformation.lineColor = "#509055";
        }
      });
    } else {
      this.arrowsInformation.forEach((arrowInformation) => {
        arrowInformation.displayText = "";
      });
    }

    this.arrowsInformation.forEach((arrowInformation) => {
      const svg = document.getElementById(arrowInformation.svgId);
      if (svg && svg.querySelector("text") && svg.querySelector("line")) {
        // Update text
        svg.querySelector("text").textContent = arrowInformation.displayText;
        // Update line color
        svg
          .querySelector("line")
          .setAttribute(
            "stroke",
            arrowInformation.displayText
              ? arrowInformation.lineColor
              : "transparent"
          );
        svg
          .querySelector("text")
          .setAttribute("fill", arrowInformation.lineColor);
      }
    });
  }

  listenForMouseEvents() {
    window.addEventListener("mousemove", this.mouseListener);
  }

  setupMovementListeners(callback) {
    if (typeof callback !== "function") {
      console.error("The provided callback is not a function");
      return;
    }

    // Scroll event listener for window
    window.addEventListener("scroll", callback);

    // Scroll event listener for all scrollable elements
    document.querySelectorAll("*").forEach((element) => {
      if (
        element.scrollHeight > element.clientHeight ||
        element.scrollWidth > element.clientWidth
      ) {
        element.addEventListener("scroll", callback);
      }
    });

    // Resize event listener for window
    window.addEventListener("resize", callback);
  }

  tearDownMovementListeners(callback) {
    // Remove all scroll event listeners
    window.removeEventListener("scroll", callback);
    document.querySelectorAll("*").forEach((element) => {
      element.removeEventListener("scroll", callback);
    });

    // Remove resize event listener
    window.removeEventListener("resize", callback);
  }
}
