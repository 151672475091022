import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "input"]
  static firstLoad = false;
  connect() {
    // this.element.scrollIntoView()
    // console.log(this.element.scrollHeight)
    // window.scrollTo(0, this.element.scrollHeight);
    // console.error($("#send_message"))

    const delay = Controller.firstLoad ? 150 : 1000;
    Controller.firstLoad = true;

    setTimeout(() => {
      if ($("#send_message")[0]) {
        $("#send_message")[0].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
      }
    }, delay);
  }

  clickChat(event) {
    // console.error(event);
    // console.error(this.inputTargets)
    $('.sideBarChat').attr('class', 'Chat  media  sideBarChat');
    // this.inputTargets.forEach(element => {
    //   // console.error(element[0].classList)
    //   console.error(element);
    // });

    // console.error(event);
    
    let element = event.target;
    let i = 0;

    while (element.nodeName !== 'BUTTON') {

      if (element.offsetParent.nodeName === 'BUTTON' ) {
        element = element.offsetParent;
      } else {
        element = element.parentElement;
        i++;
  
        if (i > 100) {
          break;
        }
      }
    }

    if (element.nodeName === 'BUTTON') {
      element.classList.add('isSelected');
    } 
  }
}