// app/javascript/controllers/opening_hours_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  
  toggle(event) {
    const checkbox = event.target;
    const parent = checkbox.closest(".u-tableRow");
    const startTimeField = parent.querySelector(".start-time");
    const endTimeField = parent.querySelector(".end-time");

    if (checkbox.checked) {
      startTimeField.value = startTimeField.value || '09:00';
      endTimeField.value = endTimeField.value || '17:00';
    }
  
    startTimeField.disabled = !checkbox.checked;
    endTimeField.disabled = !checkbox.checked;
  }

  submitAndShowMessage(event) {
    event.preventDefault();
    event.target.closest("form").submit();
    this.showSavedMessage(event);
  }

  showSavedMessage(event) {
    const savedMessage = document.getElementById("saved-state");
    savedMessage.hidden = false;

    setTimeout(() => {
      savedMessage.hidden = true;
    }, 3000);
  }

  connect() {
    document.addEventListener('turbo:before-stream-render', this.handleTurboBeforeStreamRender.bind(this));
  }

  disconnect() {
    document.removeEventListener('turbo:before-stream-render', this.handleTurboBeforeStreamRender.bind(this));
  }

  submitAndShowMessage(event) {
    event.preventDefault();
    Turbo.navigator.submitForm(document.getElementById('opening-hours-form'));
  }

  handleTurboBeforeStreamRender() {
    this.showSavedMessage();
  }

}
