// app/javascript/controllers/address_autocomplete_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "address",
    "address2",
    "zipcode",
    "city",
    "state",
    "country",
  ];

  randomClassId = Math.random().toString(36).substring(7);

  static is_loading_google_maps = false;
  static has_loaded_google_maps = false;

  connect() {
    const onLoadBehaviour = () => {
      if (typeof google === "undefined") {
        console.error("Google Maps JavaScript API not loaded");
        return;
      }

      this.autocomplete = new google.maps.places.Autocomplete(
        this.addressTarget,
        {
          componentRestrictions: { country: ["us", "ca"] },
          fields: ["address_components", "geometry", "name"],
        }
      );

      this.autocomplete.addListener(
        "place_changed",
        this.fillInAddress.bind(this)
      );
    };

    console.log(`${this.randomClassId}: Starting behaviour`);

    if (this.constructor.has_loaded_google_maps) {
      console.log(`${this.randomClassId}: Google Maps already loaded`);
      onLoadBehaviour();
    } else if (!this.constructor.is_loading_google_maps) {
      console.log(`${this.randomClassId}: Loading Google Maps`);
      this.constructor.is_loading_google_maps = true;
      const script = document.createElement("script");
      script.src =
        "https://maps.googleapis.com/maps/api/js?key=AIzaSyDJaq4iwCVY0J8XSS1dflRkwnpnH7OHoOE&libraries=places&v=weekly";
      script.onload = () => {
        console.log(`${this.randomClassId}: Google Maps loaded`);
        onLoadBehaviour();
        this.constructor.has_loaded_google_maps = true;
      };
      document.head.appendChild(script);
    } else {
      console.log(`${this.randomClassId}: Waiting for Google Maps to load`);
      // Wait for the script to load
      const waitFunction = () => {
        setTimeout(() => {
          if (this.constructor.has_loaded_google_maps) {
            console.log(`${this.randomClassId}: Google Maps loaded`);
            onLoadBehaviour();
          } else {
            console.log(`${this.randomClassId}: Waiting...`);
            waitFunction();
          }
        }, 500);
      };
      waitFunction();
    }
  }

  fillInAddress() {
    const place = this.autocomplete.getPlace();
    const name = place.name;
    let address = "";
    let zipcode = "";

    for (const component of place.address_components) {
      const componentType = component.types[0];

      switch (componentType) {
        case "street_number":
          address = `${component.long_name} ${address}`;
          break;
        case "route":
          address += component.short_name;
          break;
        case "postal_code":
          zipcode = `${component.long_name}${zipcode}`;
          break;
        case "postal_code_suffix":
          break;
        case "locality":
          this.cityTarget.value = component.long_name;
          break;
        case "administrative_area_level_1":
          this.stateTarget.value = component.short_name;
          break;
        case "country":
          this.countryTarget.value = component.long_name;
          break;
      }
    }

    this.addressTarget.value = address;
    this.zipcodeTarget.value = zipcode;

    // Check addressTarget value and name
    // If different, set address2 to the name
    if (this.addressTarget.value !== name) {
      this.address2Target.value = name;
    } else {
      this.address2Target.value = "";
    }
  }
}
