import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['noteField'];

  async showQuickLook(event) {
    event.preventDefault();

    const quickViewElement = document.querySelector("#driving-school-note-quick-look");
    quickViewElement.classList.remove("u-hidden");
  }

  closeQuickLook(event) {
    event.preventDefault();

    const quickViewElement = document.querySelector("#driving-school-note-quick-look");
    quickViewElement.classList.add("u-hidden");

    this.clearForm();
  }

  clearForm() {
    this.toggleErrorPanel(false, "");

    const form = this.element.querySelector("form");
    if (form) {
      form.reset();
    }
  }

  toggleErrorPanel(show, message) {
    const errorPanel = document.getElementById("error-panel");
    const errorMessage = document.getElementById("error-message");
    
    if (show) {
      errorMessage.innerHTML = message;
      errorPanel.hidden = false;
      errorMessage.hidden = false;
    } else {
      errorMessage.innerHTML = "";
      errorPanel.hidden = true;
      errorMessage.hidden = true;
    }
  }

  toggleLoading(show) {
    const formFields = document.getElementById("form-content");
    const loader = document.getElementById("loader");

    if (show) {
        loader.style.display = "flex";
        formFields.classList.add("hidden");
        
    } else {
        loader.style.display = "none";
        formFields.classList.remove("hidden");
    }

    const button = document.getElementById("driving_school_note_save");
    button.disabled = show;
    button.classList.toggle("Button--disabled", show);
  }

  validateForm(event) {
    const noteField = this.noteFieldTarget;
    
    if (noteField.value.trim() === "") {
      this.toggleErrorPanel(true, "The note field cannot be empty.");
      event.preventDefault();
    }
  }

  submitForm(event) {
    event.preventDefault();
    this.toggleLoading(true);

    const form = event.target.closest("form");

    fetch(form.action, {
      method: form.method,
      body: new FormData(form),
      headers: {
        'Accept': 'text/html'
      }
    })
    .then(response => {
      this.toggleLoading(false);

      if (response.ok) {
        return response.text();
      } else {
        return response.text().then(text => {
          throw new Error(text);
        });
      }
    })
    .catch(error => {
      this.toggleLoading(false);
      this.toggleErrorPanel(true, error.message);
    });
  }
}