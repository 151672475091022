import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.initializeTimePicker();
  }

  initializeTimePicker() {
    flatpickr(this.element, {
      enableTime: true,
      noCalendar: true,
      dateFormat: "H:i",
    });
  }
}
