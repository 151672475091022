import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  async updateWeek(event, action) {
    event.preventDefault();
    const { startingDate, schoolToken, instructorToken, domId } =
      event.currentTarget.dataset;
    await this.fetchAndUpdateCalendar(
      `enquiry_${action}_week`,
      startingDate,
      schoolToken,
      instructorToken,
      domId
    );
  }

  async to_date(event) {
    await this.updateWeek(event, "to_date");
  }

  async selectDate(event) {
    event.preventDefault();
    const newDateValue = document.getElementById(
      "new-calendar-week-date"
    ).value;

    if (!newDateValue) {
      alert("Please select a date");
      return;
    }

    // Parse the selected date
    let selectedDate = new Date(newDateValue);
    let action = "enquiry_to_date_week";

    // Calculate the start date of the week (assuming week starts on Monday)
    const startOfWeek = this.getMondayOfWeek(selectedDate);
    const startingDate = this.formatDateToString(startOfWeek);

    // Assuming you have the necessary dataset values
    // const goButton = event.currentTarget;

    const dateInput = event.currentTarget;
    const schoolToken = dateInput.dataset.schoolToken;
    const instructorToken = dateInput.dataset.instructorToken;
    const domId = dateInput.dataset.domId;

    // Update the calendar
    await this.fetchAndUpdateCalendar(
      action,
      startingDate,
      schoolToken,
      instructorToken,
      domId
    );
  }

  showSelectDate(event) {
    event.preventDefault();
    document.getElementById("edit-date").style.display = "block";
    document.getElementById("calendar-full-date").style.display = "none";
  }

  hideSelectDate(event) {
    event.preventDefault();
    document.getElementById("edit-date").style.display = "none";
    document.getElementById("calendar-full-date").style.display = "block";
  }

  getMondayOfWeek(date) {
    const day = date.getDay();
    const diff = date.getDate() - day + (day === 0 ? -6 : 1); // Adjust when day is Sunday
    return new Date(date.setDate(diff));
  }

  async fetchAndUpdateCalendar(
    action,
    startingDate,
    schoolToken,
    instructorToken,
    domId
  ) {
    let action_word = "to_date_week";
    if (action.startsWith("enquiry_")) {
      action_word = action.substring("enquiry_".length);
    } else {
      alert(action);
    }

    const response = await fetch(
      `/schools/dashboard/calendar/weekly/${schoolToken}/${action_word}/${startingDate}/instructor/${instructorToken}`,
      {
        method: "GET",
        headers: {
          Accept: "text/vnd.turbo-stream.html",
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );

    if (response.ok) {
      const responseBody = await response.text();
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(responseBody, "text/html");
      const template = xmlDoc.querySelector("template");
      const targetElement = document.getElementById(domId);
      if (template && targetElement) {
        targetElement.innerHTML = "";
        targetElement.appendChild(template.content.cloneNode(true));
      }
    }
  }

  async updateCalendarDateWeekView(startingDate, instructorId) {
    const targetElement = document.getElementById(
      `calendar-date-${instructorId}`
    );
    if (targetElement) {
      const formattedDate = `${startingDate.toLocaleString("default", {
        month: "short",
      })} ${startingDate.getFullYear()}, Week ${this.getWeek(startingDate)}`;
      targetElement.innerHTML = formattedDate;
    }
  }

  async updateNavigationButtons(startingDate, action, instructorId) {
    startingDate = this.parseDateString(startingDate);

    const previousWeekButton = document.getElementById(
      `previous-week-button-${instructorId}`
    );
    const nextWeekButton = document.getElementById(
      `next-week-button-${instructorId}`
    );

    const previousWeekStartingDate = new Date(startingDate);
    const nextWeekStartingDate = new Date(startingDate);

    let setCalendarDate =
      action == "to_date"
        ? startingDate.getDate() - 7
        : startingDate.getDate() + 7;

    previousWeekStartingDate.setDate(setCalendarDate);
    nextWeekStartingDate.setDate(setCalendarDate);
    this.updateCalendarDateWeekView(
      action == "to_date" ? previousWeekStartingDate : nextWeekStartingDate,
      instructorId
    );

    previousWeekButton.dataset.startingDate = this.formatDateToString(
      previousWeekStartingDate
    );
    nextWeekButton.dataset.startingDate =
      this.formatDateToString(nextWeekStartingDate);
  }

  formatDateToString(date) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-indexed, so add 1
    const day = date.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  parseDateString(dateString) {
    const cleanedDateString = dateString.replace(/"/g, ""); // Remove double quotes
    const [year, month, day] = cleanedDateString.split("-").map(Number);
    return new Date(year, month - 1, day); // Subtract 1 from the month since it's 0-indexed
  }

  getWeek(date) {
    date = new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
    );
    date.setUTCDate(date.getUTCDate() + 4 - (date.getUTCDay() || 7));
    var yearStart = new Date(Date.UTC(date.getUTCFullYear(), 0, 1));
    var weekNo = Math.ceil(((date - yearStart) / 86400000 + 1) / 7);
    return weekNo;
  }
}
