import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["frame"]

  connect() {
    if (this.frameTargets.length > 0) {
      this.loadNextFrame(0); // Start loading from the first frame
    }
  }

  loadNextFrame(index) {
    if (index >= this.frameTargets.length) {
      return; // Stop if no more frames
    }

    const frame = this.frameTargets[index];
    const src = frame.dataset.loadFrameSrc; // Ensure the data attribute is correctly referenced

    frame.src = src; // Set the src to start loading
    frame.addEventListener('turbo:frame-load', () => {
      this.loadNextFrame(index + 1); // Load next frame after current one loads
    }, { once: true });
  }
}
