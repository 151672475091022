// app/javascript/controllers/driving_school_student_progress_controller.js

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["skills"];

  connect() {
  }

  toggleSkills(event) {
    event.preventDefault();
    const skillsId = event.currentTarget.dataset.skillsId;
    const skillsSection = this.skillsTargets.find(s => s.id === `skills-${skillsId}`);
    
    if (skillsSection) {
      skillsSection.classList.toggle('u-hidden');
    } else {
      console.error(`No skills section found with id skills-${skillsId}`);
    }
  }
}
