// https://www.kirilv.com/canvas-confetti

import { Controller } from "stimulus"
export default class extends Controller {
  connect() {
    this.launchConfetti();
  }

  launchConfetti() {
    confetti({
      particleCount: 300,
      spread: 55,
      angle: 120,
      origin: { x: 1, y: 1 },
      colors: ['#741988', '#FFCA67', '#0A0B86', '#006FB1', '#99DE21']
    });
  }
}
