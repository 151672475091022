import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["link", "formContainer"];

  async openEnquiry(event) {
    event.preventDefault();

    const token = event.currentTarget.dataset.schoolToken;

    const url = '/s/' + token + '/review'

    try {
      window.location = url
    } catch (error) {
      console.error("Error fetching form:", error);
    }
  }

  async submitForm(event) {
    
  }
}