import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.teensCheckbox = document.getElementById("teens_field");
    this.adultsCheckbox = document.getElementById("adults_field");
    this.adminsCheckbox = document.getElementById("admin_only_field");

    if (this.teensCheckbox) {
      this.teensCheckbox.addEventListener("change", this.teensOrAdultsChanged.bind(this));
    }

    if (this.adultsCheckbox) {
      this.adultsCheckbox.addEventListener("change", this.teensOrAdultsChanged.bind(this));
    }

    if (this.adminsCheckbox) {
      this.adminsCheckbox.addEventListener("change", this.adminsChanged.bind(this));
    }
  }

  teensOrAdultsChanged(event) {
    if (event.target.checked && this.adminsCheckbox) {
      this.adminsCheckbox.checked = false;
    }
  }

  adminsChanged(event) {
    if (event.target.checked) {
      if (this.teensCheckbox) {
        this.teensCheckbox.checked = false;
      }

      if (this.adultsCheckbox) {
        this.adultsCheckbox.checked = false;
      }
    }
  }
}
