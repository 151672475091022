export function listenForChangesWithinElement(element, onChangeFunction) {
  if (!element) {
    return;
  }

  // create an observer instance
  const mutationObserver = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      onChangeFunction();
    });
  });
  // Configure the MutationObserver to listen for attribute changes
  const observerConfiguration = {
    attributes: true,
    childList: true,
    characterData: true,
    subtree: true,
  };
  // Observe all mutations within the element
  mutationObserver.observe(element, observerConfiguration);
}
