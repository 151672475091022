import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "tabForOpenSlotNewPupil",
    "tabForOpenSlotExistingPupil",
    "formForOpenSlotNewPupil",
    "formForOpenSlotExistingPupil"
  ]
  
  connect() {
    // check url and if new_pupil=true then select_new_pupil
    const urlParams = new URLSearchParams(window.location.search);
    const new_pupil = urlParams.get('new_pupil');
    if (new_pupil == 'true') {
      this.select_new_pupil(null);
    }
  }

  select_new_pupil(event) {
    this.tabForOpenSlotNewPupilTarget.classList.add("is-active");
    this.tabForOpenSlotExistingPupilTarget.classList.remove("is-active");

    this.formForOpenSlotNewPupilTarget.classList.remove("u-hidden");
    this.formForOpenSlotExistingPupilTarget.classList.add("u-hidden");
    
    if (event) {
      event.preventDefault();  // Prevent default action of the link
    }
  }

  select_existing_pupil(event) {
    this.tabForOpenSlotNewPupilTarget.classList.remove("is-active");
    this.tabForOpenSlotExistingPupilTarget.classList.add("is-active");
    
    this.formForOpenSlotNewPupilTarget.classList.add("u-hidden");
    this.formForOpenSlotExistingPupilTarget.classList.remove("u-hidden");
    if (event) {
      event.preventDefault();  // Prevent default action of the link
    }
  }
}
