import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [];

  connect() {
    // We need to decide what to do here,
    // Need to add listeners that trigger this whenever a relevant value changes
    this.set_data_attributes_on_weekview();
  }

  async set_data_attributes_on_weekview() {
    const element = document.querySelector("#week-view");
    if(!element) return;

    const location_data = this.getLocationData();

    // console.log(location_data);

    if (location_data.pick_up_address_line_1 && location_data.pick_up_zipcode) {
      const csrf_token = document.querySelector('meta[name="csrf-token"]').content;
      const driving_school_token = document.getElementById("us-school-token").innerHTML.trim();
      const instructor_id = document.getElementById( "us-lesson-instructor-id").innerHTML.trim();
      const pick_up_category = location_data.office_pickup_category;
      const drop_off_category = location_data.office_drop_off_category;
      const pick_up_address_line_1 = location_data.pick_up_address_line_1;
      const pick_up_zipcode = location_data.pick_up_zipcode;
      const pick_up_school_id = location_data.pick_up_school_id;
      const pick_up_point_id = location_data.pick_up_point_id;
      const drop_off_address_line_1 = location_data.drop_off_address_line_1;
      const drop_off_zipcode = location_data.drop_off_zipcode;
      const drop_off_school_id = location_data.drop_off_school_id;
      const drop_off_point_id = location_data.drop_off_point_id;

      element.dataset.weekViewPostParameterCsrfToken = csrf_token;
      element.dataset.weekViewPostParameterDrivingSchoolToken = driving_school_token;
      element.dataset.weekViewPostParameterInstructorId = instructor_id;
      element.dataset.weekViewPostParameterPickUpCategory = pick_up_category;
      element.dataset.weekViewPostParameterDropOffCategory = drop_off_category;
      element.dataset.weekViewPostParameterPickUpAddressLine1 = pick_up_address_line_1;
      element.dataset.weekViewPostParameterPickUpZipcode = pick_up_zipcode;
      element.dataset.weekViewPostParameterPickUpSchoolId = pick_up_school_id;
      element.dataset.weekViewPostParameterPickUpPointId = pick_up_point_id;
      element.dataset.weekViewPostParameterDropOffAddressLine1 = drop_off_address_line_1;
      element.dataset.weekViewPostParameterDropOffZipcode = drop_off_zipcode;
      element.dataset.weekViewPostParameterDropOffSchoolId = drop_off_school_id;
      element.dataset.weekViewPostParameterDropOffPointId = drop_off_point_id;
    }
  }

  getLocationData() {
    let office_pickup_category = "";
    let office_drop_off_category = "";
    const same_as_pick_up_location = "";

    let pick_up_address_line_1 = "11414 Chalon Rd";
    let pick_up_zipcode = "90049";
    let pick_up_school_id = "";
    let pick_up_point_id = "";

    let drop_off_address_line_1 = "11414 Chalon Rd";
    let drop_off_zipcode = "90049";
    let drop_off_school_id = "";
    let drop_off_point_id = "";

    
    if (same_as_pick_up_location == true) {
      // use same values as pick up
      office_drop_off_category = office_pickup_category;
      if (office_pickup_category == "Pick-up points") {
        office_drop_off_category = 'Drop-off points'
      }
      
      drop_off_address_line_1 = pick_up_address_line_1;
      drop_off_zipcode = pick_up_zipcode;
      drop_off_school_id = pick_up_school_id;
      drop_off_point_id = pick_up_point_id;
    } else {
      if (office_drop_off_category == "Address") {
        drop_off_address_line_1 = document.getElementById("booking_location_drop_off_home_work_address_value").value;
        drop_off_zipcode = document.getElementById("booking_location_drop_off_home_work_zipcode_value").value;
      }
      // if pick up is school, get the ID for the school pick_up_high_school_id
      if (office_drop_off_category == "School") {
        drop_off_school_id = document.getElementById("driving_school_booking_drop_off_high_school_id").value;
      }
      if (office_drop_off_category == "Drop-off points") {
        drop_off_point_id = document.getElementById("driving_school_booking_drop_off_drop_off_point_id").value;
      }
    }
    office_pickup_category= "Address"
    office_drop_off_category= "Address"

    return {
      office_pickup_category,
      office_drop_off_category,
      pick_up_address_line_1,
      pick_up_zipcode,
      pick_up_school_id,
      pick_up_point_id,
      drop_off_address_line_1,
      drop_off_zipcode,
      drop_off_school_id,
      drop_off_point_id
    };
  }
}