import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  async previousWeek(event) {
    event.preventDefault();
    const startingDate = event.currentTarget.dataset.startingDate;
    await this.fetchAndUpdateCalendar("calendar_previous_week", startingDate);
  }

  async nextWeek(event) {
    event.preventDefault();
    const startingDate = event.currentTarget.dataset.startingDate;
    await this.fetchAndUpdateCalendar("calendar_next_week", startingDate);
  }

  async fetchAndUpdateCalendar(action, startingDate) {
    
    let action_word = 'previous_week'
    if (action.startsWith('calendar_')) {
      action_word = action.substring('calendar_'.length);
    } else {
      alert(action);
    }

    this.updateNavigationButtons(startingDate, action_word);
    const response = await fetch(`/schools/setup/calendar/${action_word}/${startingDate}`, {
      method: "GET",
      headers: {
        "Accept": "text/vnd.turbo-stream.html",
        "Content-Type": "application/x-www-form-urlencoded"
      }
    });
  
    if (response.ok) {
      const responseBody = await response.text();
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(responseBody, "text/html");
      const template = xmlDoc.querySelector("template");
      const targetElement = document.getElementById("weekly-calendar");
  
      if (template && targetElement) {
        targetElement.innerHTML = "";
        targetElement.appendChild(template.content.cloneNode(true));
      }
    }
  }

  async updateNavigationButtons(startingDate, action) {
    startingDate = new Date(startingDate);
    
    const previousWeekButton = document.querySelector("[data-action='click->calendar#previousWeek']");
    const nextWeekButton = document.querySelector("[data-action='click->calendar#nextWeek']");

    const previousWeekStartingDate = new Date(startingDate);
    const nextWeekStartingDate = new Date(startingDate);

    if (action == 'previous_week') {
      previousWeekStartingDate.setDate(startingDate.getDate() - 7);
      nextWeekStartingDate.setDate(startingDate.getDate() - 7);
    } else {
      previousWeekStartingDate.setDate(startingDate.getDate() + 7);
      nextWeekStartingDate.setDate(startingDate.getDate() + 7);
    }
    
    previousWeekButton.dataset.startingDate = previousWeekStartingDate;
    nextWeekButton.dataset.startingDate = nextWeekStartingDate;
  }

  
}
