import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    // find all elements with ID start with us-booking-next- and hide them
    const nextButtons = document.querySelectorAll('[id^="us-booking-next"]');
    for (var i = 0; i < nextButtons.length; i++) {
      nextButtons[i].hidden = true;
    }

    //find all elements that start with 'progress-' and hide them
    const progressElements = document.querySelectorAll('[id^="progress-"]');
    for (var i = 0; i < progressElements.length; i++) {
      progressElements[i].hidden = true;
    }

    //find all elements that start with 'us-booking-back-' and hide them
    const backButtons = document.querySelectorAll('[id^="us-booking-back"]');
    for (var i = 0; i < backButtons.length; i++) {
      backButtons[i].classList.remove("u-block");
      backButtons[i].hidden = true;
    }

    // find gearbox-form and hide it if it exists.
    const gearboxForm = document.getElementById("gearbox-form");
    if(gearboxForm) gearboxForm.hidden = true;
  }
}