// app/javascript/controllers/event_actions_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "event", "school", "deletePanel", "eventActions", "token" ]

  connect() {
    // this.eventActionsTarget.style.display = "block";
  }

  openDeletePanel(event) {
    event.preventDefault();
    this.deletePanelTarget.style.display = "block";
  }

  closeDeletePanel(event) {
    event.preventDefault();
    this.deletePanelTarget.style.display = "none";
  }

  deleteEventConfirm(event) {
    event.preventDefault();
    const eventId = this.eventTarget.textContent.trim();
    const isSchool = this.schoolTarget.textContent.trim();
    const navigationToken = this.tokenTarget.textContent.trim();
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
    let url = "/pro/web/diary";
    if (isSchool == "true") {
      url = "/schools/team/"+navigationToken+"/time-off";
    }

    fetch(`/pro/web/diary/event/${eventId}/delete`, {
      method: 'DELETE',
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "X-CSRF-Token": csrfToken,
      },
    })
    .then(response => {
      if (response.ok) {
        console.log('Event deleted');
        console.log(url);
        window.location.href = `${url}?event_delete=true`;
      } else {
        alert('An error has occurred and our technical team have been notified.');
      }
    })
    .catch(error => {
      alert('An error has occurred and our technical team have been notified.');
    });
  }
}
