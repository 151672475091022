import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
  }

  async validate(event) {

    const driving_school_note_pupil_id = document.getElementById("driving_school_note_pupil_id").value;
    
    if (isNaN(driving_school_note_pupil_id)) {
      alert("Please select a pupil");
      event.preventDefault();
      return;
    }

    const selected_item_pupil_id = document.getElementById("selected-item-pupil-" + driving_school_note_pupil_id).value;
    // check the selected_item_pupil_id value is the ID
    if (selected_item_pupil_id != driving_school_note_pupil_id) {
      alert("Please select a pupil");
      event.preventDefault();
      return;
    }

    // find instructor_id and vehicle_id and make sure they are not blank
    const instructor_id = document.getElementById("instructor_id");
    
    if (instructor_id == undefined || instructor_id.value == "" || isNaN(instructor_id.value)) {
      alert("Please check the student and instructor are selected");
      event.preventDefault();
      return;
    }

    const vehicle_id = document.getElementById("vehicle_id");
    if (vehicle_id == undefined || vehicle_id.value == "" || isNaN(vehicle_id.value)) {
      alert("Please check the student and vehicle are selected");
      event.preventDefault();
      return;
    }

    // same with start_date and start_time
    const start_date = document.getElementById("start_date");
    if (start_date == undefined || start_date.value == "") {
      alert("Please select a start date");
      event.preventDefault();
      return;
    }

    const start_time = document.getElementById("start_time");
    if (start_time == undefined || start_time.value == "") {
      alert("Please select a start time");
      event.preventDefault();
      return;
    }

  }
}