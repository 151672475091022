import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["archivePanel", "archiveReason", "deletePanel"]

  connect() {
    this.csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
  
    this.archivePanelTarget.hidden = true;
    // this.deletePanelTarget.hidden = true;
  }

  showArchivePanel(event) {
    event.preventDefault();
    this.archivePanelTarget.hidden = false;
  }

  hideArchivePanel(event) {
    event.preventDefault();
    this.archivePanelTarget.hidden = true;
  }

  showDeletePanel(event) {
    event.preventDefault();
    this.deletePanelTarget.hidden = false;
  }

  hideDeletePanel(event) {
    event.preventDefault();
    this.deletePanelTarget.hidden = true;
  }

  confirmArchive(event) {
    event.preventDefault();
    const pupilId = document.querySelector("#pupil-id").textContent.trim();
    const data = { archive_type: this.archiveReasonTarget.value };

    fetch(`/schools/pupils/${pupilId}/archive`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': this.csrfToken // Include CSRF token here
      },
      body: JSON.stringify(data),
    })
    .then(response => {
      if (response.ok) {
        window.location.href = "/schools/pupils/?pupil_archived=true";
      } else {
        alert('An error has occurred and our technical team have been notified.');
      }
    })
    .catch(() => {
      alert('An error has occurred and our technical team have been notified.');
    });
  }

  confirmDelete(event) {
    event.preventDefault();
    const pupilId = document.querySelector("#pupil-id").textContent.trim();

    fetch(`/pro/web/pupils/${pupilId}/delete`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    })
    .then(response => {
      if (response.ok) {
        window.location.href = "/pro/web/pupils?pupil_deleted=true";
      } else {
        alert('An error has occurred and our technical team have been notified.');
      }
    })
    .catch(() => {
      alert('An error has occurred and our technical team have been notified.');
    });
  }
}
