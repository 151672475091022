import { Controller } from "@hotwired/stimulus";
import * as Luxon from "luxon";

/**
 * The purpose of this controller is to be attached to a form with date and/or time controls.
 * It can listen for changes on these form items and edit a hidden form item with an iso8601
 * compliant date and/or time string. It takes in the form names of various form items and
 * a timezone string. It will then update the iso8601 form item with the latest iso8601 string
 * based on a timezone.
 *
 * TODO: This should probably use targets instead of ids or names.
 */
export default class extends Controller {
  connect() {
    console.log("Connected iso time controller");
    setTimeout(() => {
      this.attemptToAddEventListeners();
      this.updateTimeStrings();
      this.populateIso8601Element();
    });
  }

  latestTimeString = "";
  latestDateString = "";
  latestIso8601String = "";

  hasAddedDateListener = false;
  hasAddedTimeListener = false;

  attemptToAddEventListeners() {
    const useIds = !!this.data.get("useIds");

    const timeName = this.data.get("timeName");
    const timeElement = useIds
      ? document.getElementById(timeName)
      : this.element.querySelector('input[name="' + timeName + '"]');
    if (timeElement && !this.hasAddedTimeListener) {
      timeElement.addEventListener("change", (event) => {
        this.updateTimeStrings();
        this.populateIso8601Element();
        this.attemptToAddEventListeners();
      });
      this.hasAddedTimeListener = true;
    }

    console.log(`Time name is ${timeName}`);
    console.log(`Time element is ${timeElement}`);

    const dateName = this.data.get("dateName");
    const dateElement = useIds
      ? document.getElementById(dateName)
      : this.element.querySelector('input[name="' + dateName + '"]');
    if (dateElement && !this.hasAddedDateListener) {
      dateElement.addEventListener("change", (event) => {
        this.updateTimeStrings();
        this.populateIso8601Element();
        this.attemptToAddEventListeners();
      });
      this.hasAddedDateListener = true;
    }
  }

  updateTimeStrings() {
    console.log("Updating time strings");

    const useIds = !!this.data.get("useIds");
    const timeName = this.data.get("timeName");
    const timeElement = useIds
      ? document.getElementById(timeName)
      : this.element.querySelector('input[name="' + timeName + '"]');
    const dateName = this.data.get("dateName");
    const dateElement = useIds
      ? document.getElementById(dateName)
      : this.element.querySelector('input[name="' + dateName + '"]');

    this.latestTimeString = timeElement ? timeElement.value : "";
    this.latestDateString = dateElement ? dateElement.value : "";

    const timezone = this.data.get("timezone");
    const dateTime =
      this.latestTimeString && this.latestDateString
        ? Luxon.DateTime.fromISO(
            this.latestDateString + "T" + this.latestTimeString,
            { zone: timezone }
          )
        : undefined;

    this.latestIso8601String = dateTime ? dateTime.toString() : "";
  }

  populateIso8601Element() {
    const useIds = !!this.data.get("useIds");
    const isoName = this.data.get("isoName");
    const isoElement = useIds
      ? document.getElementById(isoName)
      : this.element.querySelector('input[name="' + isoName + '"]');
    if (isoElement) {
      isoElement.value = this.latestIso8601String;
      isoElement.dispatchEvent(
        new Event("change", {
          bubbles: true,
          cancelable: true,
        })
      );
    }
  }
}

function optionalChaining(valueFn) {
  try {
    return valueFn();
  } catch (e) {
    return undefined;
  }
}
