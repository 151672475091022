import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["officePanel", "officeRadio"]

  connect() {
    this.updateSelectedOffice()
  }

  selectOffice(event) {
    const clickedPanel = event.currentTarget
    const radioButton = clickedPanel.querySelector('input[type="radio"]')
    radioButton.checked = true
    this.updateSelectedOffice()
  }

  updateSelectedOffice() {
    this.officePanelTargets.forEach((panel, index) => {
      const radio = this.officeRadioTargets[index]
      if (radio.checked) {
        panel.classList.add("Panel--highlight")
        panel.classList.remove("Panel--borderOnly")
      } else {
        panel.classList.remove("Panel--highlight")
        panel.classList.add("Panel--borderOnly")
      }
    })
  }
}