import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["link"]
  static controllerName = "school-pupil-navigation"

  connect() {
    this.updateActiveClass()
  }

  updateActiveClass() {
    this.linkTargets.forEach((link) => {
      if (link.href === window.location.href) {
        link.classList.add("is-active");
      } else {
        link.classList.remove("is-active");
      }
    });
  }
  
}
