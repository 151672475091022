import { Controller } from "@hotwired/stimulus";
import { listenForChangesWithinElement } from "../../utils/mutation-listening";

export default class extends Controller {
  static targets = ["dragObject", "dragTarget"];

  dragControllerId = this.createRandomId();
  registeredDragObjects = [];
  registeredDragTargets = [];

  documentDragoverListener;
  documentDropListener;

  lastDraggedStartLeftOfElement = 0;
  lastDraggedStartTopOfElement = 0;
  lastDraggedStartRightOfElement = 0;
  lastDraggedStartBottomOfElement = 0;
  lastDraggedStartX = 0;
  lastDraggedStartY = 0;
  draggedElementAdditionalData = {};

  connect() {
    console.log("Drag and drop controller connected!");
    this.addEventListeners();
    listenForChangesWithinElement(this.element, () => {
      this.addEventListeners();
    });
    this.documentDragoverListener = (event) => {
      event.stopPropagation();
      event.preventDefault();
      const dragTargetElement = this.dragTargetTargets.find((dragTarget) => {
        const rect = dragTarget.getBoundingClientRect();
        return (
          event.clientX >= rect.left &&
          event.clientX <= rect.right &&
          event.clientY >= rect.top &&
          event.clientY <= rect.bottom
        );
      });
      if (dragTargetElement) {
        this.calculateDragPositionVariablesAndEmitEvent(
          event,
          dragTargetElement,
          "drag-and-drop:drag"
        );
      }
    };
    this.documentDropListener = (event) => {
      event.stopPropagation();
      // Find a target element the mouse is currently in
      const dragTargetElement = this.dragTargetTargets.find((dragTarget) => {
        const rect = dragTarget.getBoundingClientRect();
        return (
          event.clientX >= rect.left &&
          event.clientX <= rect.right &&
          event.clientY >= rect.top &&
          event.clientY <= rect.bottom
        );
      });
      if (dragTargetElement) {
        this.calculateDragPositionVariablesAndEmitEvent(
          event,
          dragTargetElement,
          "drag-and-drop:drop"
        );
      }
      this.resetDraggingProperties();
    };
    this.element.addEventListener(
      "dragover",
      this.documentDragoverListener,
      true
    );
    this.element.addEventListener("drop", this.documentDropListener, true);
  }

  disconnect() {
    console.log("Drag and drop controller disconnected!");
    this.element.removeEventListener("dragover", this.documentDragoverListener);
    this.element.removeEventListener("drop", this.documentDropListener);
  }

  addEventListeners() {
    this.dragObjectTargets.forEach((dragObject) => {
      const uniqueElementId =
        dragObject.dataset[this.dragControllerId] || this.createRandomId();
      const isRegistered = this.registeredDragObjects.includes(uniqueElementId);
      if (!isRegistered) {
        this.registeredDragObjects.push(uniqueElementId);
        dragObject.dataset[this.dragControllerId] = uniqueElementId;
        dragObject.setAttribute("draggable", "true");
        dragObject.addEventListener("dragstart", (event) => {
          this.dragStart(event);
          event.target.style.opacity = 0.5;
        });
        dragObject.addEventListener("drag", (event) => {
          this.drag(event);
        });
        dragObject.addEventListener("dragend", (event) => {
          this.dragEnd(event);
          event.target.style.opacity = 1;
        });
      } else {
        // console.log(
        //   "Already registered drag object with id: ",
        //   uniqueElementId
        // );
      }
    });

    this.dragTargetTargets.forEach((dragTarget) => {
      const uniqueElementId =
        dragTarget.dataset[this.dragControllerId] || this.createRandomId();
      const isRegistered = this.registeredDragTargets.includes(uniqueElementId);
      if (!isRegistered) {
        this.registeredDragTargets.push(uniqueElementId);
        dragTarget.dataset[this.dragControllerId] = uniqueElementId;
      } else {
        // console.log(
        //   "Already registered drag target with id: ",
        //   uniqueElementId
        // );
      }
    });
  }

  dragStart(event) {
    this.resetDraggingProperties();
    this.lastDraggedStartLeftOfElement =
      event.target.getBoundingClientRect().left;
    this.lastDraggedStartTopOfElement =
      event.target.getBoundingClientRect().top;
    this.lastDraggedStartRightOfElement =
      event.target.getBoundingClientRect().right;
    this.lastDraggedStartBottomOfElement =
      event.target.getBoundingClientRect().bottom;
    this.lastDraggedStartX = event.clientX;
    this.lastDraggedStartY = event.clientY;
    this.draggedElementAdditionalData = {};
    Object.keys(event.target.dataset).forEach((key) => {
      if (key.startsWith("dragAndDrop")) {
        this.draggedElementAdditionalData[
          key.charAt(11).toLowerCase() + key.slice(12)
        ] = event.target.dataset[key];
      }
    });
  }

  drag(event) {}

  dragEnd(event) {
    this.element.dispatchEvent(
      new CustomEvent("drag-and-drop:end", {
        detail: {},
      })
    );
  }

  allowDrop(event) {
    event.preventDefault();
  }

  calculateDragPositionVariablesAndEmitEvent(event, element, emittedEventName) {
    const rect = element.getBoundingClientRect();
    const mouseDropXPercentage = (event.clientX - rect.left) / rect.width;
    const draggedElementLeftXPercentage =
      (event.clientX -
        (this.lastDraggedStartX - this.lastDraggedStartLeftOfElement) -
        rect.left) /
      rect.width;
    const draggedElementRightXPercentage =
      (event.clientX -
        (this.lastDraggedStartX - this.lastDraggedStartRightOfElement) -
        rect.left) /
      rect.width;
    const mouseDropYPercentage = (event.clientY - rect.top) / rect.height;
    const draggedElementTopYPercentage =
      (event.clientY -
        (this.lastDraggedStartY - this.lastDraggedStartTopOfElement) -
        rect.top) /
      rect.height;
    const draggedElementBottomYPercentage =
      (event.clientY -
        (this.lastDraggedStartY - this.lastDraggedStartBottomOfElement) -
        rect.top) /
      rect.height;

    // Get the params defiend on the element
    const dataLeftOnElement =
      Number(element.dataset.dragAndDropLeft || "0") || 0;
    const dataRightOnElement =
      Number(element.dataset.dragAndDropRight || "0") || 0;
    const dataTopOnElement = Number(element.dataset.dragAndDropTop || "0") || 0;
    const dataBottomOnElement =
      Number(element.dataset.dragAndDropBottom || "0") || 0;

    // Work out the corresponding number
    const leftX =
      dataLeftOnElement +
      (dataRightOnElement - dataLeftOnElement) * draggedElementLeftXPercentage;
    const rightX =
      dataLeftOnElement +
      (dataRightOnElement - dataLeftOnElement) * draggedElementRightXPercentage;
    const topY =
      dataTopOnElement +
      (dataBottomOnElement - dataTopOnElement) * draggedElementTopYPercentage;
    const bottomY =
      dataTopOnElement +
      (dataBottomOnElement - dataTopOnElement) *
        draggedElementBottomYPercentage;
    const mouseX =
      dataLeftOnElement +
      (dataRightOnElement - dataLeftOnElement) * mouseDropXPercentage;
    const mouseY =
      dataTopOnElement +
      (dataBottomOnElement - dataTopOnElement) * mouseDropYPercentage;
    // Get all data attributes on an elemen
    const dropTargetAdditionalData = {};
    Object.keys(element.dataset).forEach((key) => {
      if (key.startsWith("dragAndDrop")) {
        dropTargetAdditionalData[key.charAt(11).toLowerCase() + key.slice(12)] =
          element.dataset[key];
      }
    });

    this.element.dispatchEvent(
      new CustomEvent(emittedEventName, {
        detail: {
          leftX,
          rightX,
          topY,
          bottomY,
          mouseX,
          mouseY,
          dropTargetAdditionalData,
          draggedElementAdditionalData: this.draggedElementAdditionalData,
        },
      })
    );
  }

  resetDraggingProperties() {
    this.lastDraggedStartLeftOfElement = 0;
    this.lastDraggedStartTopOfElement = 0;
    this.lastDraggedStartRightOfElement = 0;
    this.lastDraggedStartBottomOfElement = 0;
    this.lastDraggedStartX = 0;
    this.lastDraggedStartY = 0;
    this.draggedElementAdditionalData = {};
  }

  createRandomId() {
    return Math.random().toString(36).substring(7);
  }
}
