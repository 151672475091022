import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    if (typeof SignaturePad !== 'undefined') {
      this.studentSignaturePad = new SignaturePad(this.element.querySelector("#student-signature-canvas"));
      this.guardianSignaturePad = new SignaturePad(this.element.querySelector("#guardian-signature-canvas"));
    }
  }

  clearStudentSignature(event) {
    this.studentSignaturePad.clear();
  }

  clearGuardianSignature(event) {
    this.guardianSignaturePad.clear();
  }

  saveStudentSignature(event) {
    event.preventDefault();
    

    if (this.studentSignaturePad) {
      if (this.studentSignaturePad.isEmpty()) {
        
        let studentSignature = this.element.querySelector("#student-signature");
        if (studentSignature.classList.contains("u-hidden") == false) {
          alert("Please sign before submitting");
          event.currentTarget.disabled = false;
        }

      } else {
        this.element.querySelector("#student_signature").value = this.studentSignaturePad.toDataURL();
      }
    }
  }

  saveGuardianSignature(event) {
    event.preventDefault();
    if (this.guardianSignaturePad) {  
      if (this.guardianSignaturePad.isEmpty()) {
        console.log("empty");
      } else {
      this.element.querySelector("#guardian_signature").value = this.guardianSignaturePad.toDataURL();
      }
    }

  }
}
