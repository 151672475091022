import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form"];

  connect() {
  }

  updateSlot(event) {
    
    this.hideErrors();
    event.target.disabled = true;

    let spinner = event.target.querySelector('.Spinner');
    spinner.classList.remove('u-hidden');
    spinner.classList.add('u-inlineBlock');

    let errors = this.validateForm();

    if (errors.length > 0) {
      event.preventDefault();
      this.displayErrors(errors);
      event.target.disabled = false;


      spinner.classList.add('u-hidden');
      spinner.classList.remove('u-inlineBlock');

      return;
    }

  }

  validateForm() {
    let error = [];

    let zone_ids = this.getCheckedElements('[id^="zone_"]');
    if (zone_ids == null) {
      error.push("Please select at least one zone");
    }

    let vehicle_ids = this.getCheckedElements('[id^="vehicle_"]');
    if (vehicle_ids == null) {
      error.push("Please select at least one vehicle");
    }

    // make sure start_date and start_time are filled in
    if (
      document.getElementById("start_date").value == "" ||
      document.getElementById("start_time").value == ""
    ) {
      error.push("Please select a date and time");
    }

    // make sure available_to_current_students or available_to_new_students is checked
    if (
      document.getElementById("available_to_current_students").checked ==
        false &&
      document.getElementById("available_to_new_students").checked == false
    ) {
      error.push("Please select at least one student type");
    }

    return error;
  }

  getCheckedElements(selector) {
    var elements = document.querySelectorAll(selector);
    var checkedElements = [];

    elements.forEach(function (element) {
      if (element.checked) {
        // Add the ID of the checked element to the array
        // You can change 'id' to any other attribute you need
        checkedElements.push(element.id);
      }
    });

    // Return null if no elements are checked, or the array of checked element IDs
    return checkedElements.length > 0 ? checkedElements : null;
  }

  displayErrors(errors) {
    if (errors.length > 0) {
      document.getElementById("error-messages").innerHTML = errors.join("<br>");
      document
        .getElementById("error-messages-panel")
        .classList.remove("u-hidden");
    }
  }

  hideErrors() {
    document.getElementById("error-messages-panel").classList.add("u-hidden");

    // find error-messages and hide it
    const errorMessages = document.getElementById("error-messages-server-error");
    if (errorMessages) {
      errorMessages.classList.add("u-hidden");
    }
  }
}