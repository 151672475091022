import { Controller } from "@hotwired/stimulus";

// TODO:
// Make images optional
// Fix secondary up down arrow
// Rename this, it's not just for instructors

export default class extends Controller {
  static targets = [
    "currentItemWrapper",
    "currentItemName",
    "currentInstructorImage",
    "dropdown",
    "searchContainer",
    "searchInput",
    "instructorOutOfZoneDropdown",
    "instructorOutOfZoneDropdownArrow",
  ];

  // Properties that track the current display properties of the UI
  areItemsExpanded = false;
  areSecondaryItemsExpanded = false;
  searchTerm = "";

  connect() {
    // When the widget is first initialised, we should make sure the default item is broadcast as selected
    // to ensure that the desired selection behaviour on any parent components is triggered
    // set in a timeout so the event is not triggered before the parent component has had a chance to listen for it
    setTimeout(() => {
      const id = this.currentItemWrapperTarget.dataset.instructorId;
      const dispatchItemString =
        this.currentItemWrapperTarget.dataset.dispatchItem;
      let dispatchItem = { id: id };
      try {
        dispatchItem = JSON.parse(dispatchItemString);
      } catch (error) {
        console.log(error);
      }
      if (!(!id || id === "undefined" || id == "null" || id == "0")) {
        if (this.data.has("inputQuerySelector")) {
          try {
            const inputQuerySelector = this.data.get("inputQuerySelector");
            const inputElement = document.querySelector(inputQuerySelector);
            inputElement.value = id;
          } catch (e) {
            console.log(e);
            console.log("Could not set input_query_selector value");
          }
        }

        this.element.dispatchEvent(
          new CustomEvent("itemSelected", {
            detail: {
              id: id,
              dispatchItem: dispatchItem,
            },
          })
        );
      }
    });
  }

  forceSelectItem(event) {
    // search for either a primary item or secondary item with the right data-instructor-id
    const primaryItem = this.element.querySelector(
      `.primary-item[data-instructor-id='${event.detail.id}']`
    );
    const secondaryItem = this.element.querySelector(
      `.secondary-items[data-instructor-id='${event.detail.id}']`
    );

    if (primaryItem) {
      this.selectItem({ currentTarget: primaryItem });
    } else if (secondaryItem) {
      this.selectItem({ currentTarget: secondaryItem });
    } else {
      console.log("No item found with id: ", event.detail.id);
    }
  }

  addBadge(event) {
    this.element.querySelectorAll(`.Badge`).forEach((element) => {
      if (
        !event.detail.id ||
        element.dataset.instructorId === `${event.detail.id}`
      ) {
        element.classList = "";
        event.detail.badgeClasses.forEach((badgeClass) => {
          element.classList.add(badgeClass);
        });
        element.innerHTML = event.detail.badgeText;
      }
    });
  }

  toggleSelection(event) {
    console.log("toggleSelection");
    this.areItemsExpanded = !this.areItemsExpanded;
    this.setDisplayProperties();
  }

  filter(event) {
    this.searchTerm = event.currentTarget.value;
    console.log(this.searchTerm);
    this.setDisplayProperties();
  }

  toggleSecondarySelection() {
    this.areSecondaryItemsExpanded = !this.areSecondaryItemsExpanded;
    this.setDisplayProperties();
  }

  selectItem(event) {
    const id = event.currentTarget.dataset.instructorId;
    const dispatchItemString = event.currentTarget.dataset.dispatchItem;
    let dispatchItem = { id: id };
    try {
      dispatchItem = JSON.parse(dispatchItemString);
    } catch (error) {
      console.log(error);
    }
    const name = event.currentTarget.dataset.instructorName;
    const image = event.currentTarget.getElementsByTagName("img")[0].src;

    this.currentItemWrapperTarget.dataset.instructorId = id;
    this.currentItemNameTarget.innerHTML = name;
    this.currentInstructorImageTarget.src = image;

    this.closeSelection();

    // If the parent element used input_query_selector, we should set the value of that input to the name of the selected item
    if (this.data.has("inputQuerySelector")) {
      try {
        const inputQuerySelector = this.data.get("inputQuerySelector");
        const inputElement = document.querySelector(inputQuerySelector);
        inputElement.value = id;
      } catch (e) {
        console.log(e);
        console.log("Could not set input_query_selector value");
      }
    }

    this.element.dispatchEvent(
      new CustomEvent("itemSelected", {
        detail: {
          id: id,
          dispatchItem: dispatchItem,
        },
      })
    );
  }

  closeSelection() {
    this.areItemsExpanded = false;
    this.setDisplayProperties();
  }

  setDisplayProperties() {
    const showSearch = this.areItemsExpanded;
    this.searchContainerTarget.style.height = showSearch ? "50px" : "0px";

    const rotateDropdown = this.areItemsExpanded;
    this.dropdownTarget.style.transform = rotateDropdown
      ? "rotate(180deg)"
      : "rotate(360deg)";

    const rotateOutOfZoneDropdown = this.areInstructorsOutOfZoneExpanded;
    this.instructorOutOfZoneDropdownArrowTarget.style.transform =
      rotateOutOfZoneDropdown ? "rotate(180deg)" : "rotate(360deg)";

    const showSecondaryDropdown = this.areItemsExpanded;
    this.instructorOutOfZoneDropdownTarget.style.height = showSecondaryDropdown
      ? "30px"
      : "0px";

    const showOutOfZoneInstructors =
      this.areItemsExpanded && this.areSecondaryItemsExpanded;
    const outOfZoneInstructors =
      this.element.getElementsByClassName("secondary-items");
    for (let i = 0; i < outOfZoneInstructors.length; i++) {
      const element = outOfZoneInstructors[i];
      const instructorName = element.dataset.instructorName;
      const showThisInstructor = instructorName
        .toLowerCase()
        .includes(this.searchTerm.toLowerCase());
      element.style.height =
        showOutOfZoneInstructors && showThisInstructor ? "50px" : "0px";
    }

    const showInZoneInstructors = this.areItemsExpanded;
    const inZoneInstructors =
      this.element.getElementsByClassName("primary-item");
    console.log(inZoneInstructors);
    for (let i = 0; i < inZoneInstructors.length; i++) {
      const element = inZoneInstructors[i];
      console.log(element);
      const instructorName = element.dataset.instructorName;
      console.log(instructorName);
      const showThisInstructor = instructorName
        .toLowerCase()
        .includes(this.searchTerm.toLowerCase());
      console.log(showThisInstructor);
      element.style.height =
        showInZoneInstructors && showThisInstructor ? "50px" : "0px";
    }
  }
}
