import { Controller } from "stimulus";

export default class extends Controller {
  
  static targets = ["form"];
  async transfer_package(event) {
    event.preventDefault();

    const selectedPurchaseHistories = Array.from(this.formTarget.elements)
      .filter(element => element.type === 'checkbox' && element.checked)
      .map(checkbox => checkbox.value);

    const pupilId = this.element.getAttribute("data-pupil-id-value");
    const purchaseId = this.element.getAttribute("data-original-purchase-id-value");

    // if selectedPurchaseHistories is empty
    if (selectedPurchaseHistories.length === 0) {
      const errorsPanel = document.getElementById('errors-panel');
      const errorsPanelText = document.getElementById('errors-panel-text'); // Assuming you have a specific element for the text
      if (errorsPanel && errorsPanelText) {
        errorsPanel.hidden = false;
        errorsPanelText.textContent = "Please select at least one package to transfer."
        errorsPanelText.style.display = 'block'; // Make sure the panel is visible
      }
      return;
    }

    fetch(`/schools/pupils/${pupilId}/purchase-transfer/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // Add any other headers like authentication tokens if required
      },
      body: JSON.stringify({ 
        purchase_histories: selectedPurchaseHistories,
        original_purchase_id: purchaseId,
        pupil_id: pupilId
      })
    })
    .then(response => {
      if (!response.ok) {
        return response.json().then(data => {
          // Check if the server provides a specific error message, use it
          const errorMessage = data.error || `Error: ${response.status} - ${response.statusText}`;
          throw new Error(errorMessage);
        }).catch(error => {
          // If parsing the response as JSON fails, throw a generic network error message
          throw error.message ? new Error(error.message) : new Error(`Network error: ${response.status} - ${response.statusText}`);
        });
      }
      if (response !== null) {
      return response.json();
      }
    })
    .then(data => {
      window.location.href = `/schools/pupils/${pupilId}/packages`;
      // console.log('Success:', data);
      // Handle success, like redirecting or updating the UI
    })
    .catch(error => {
      console.error('Error:', error);
      // Display the error message in the errors-panel div
      const errorsPanel = document.getElementById('errors-panel');
      const errorsPanelText = document.getElementById('errors-panel-text'); // Assuming you have a specific element for the text
      if (errorsPanel && errorsPanelText) {
        errorsPanel.hidden = false;
        errorsPanelText.textContent = error.message;
        errorsPanelText.style.display = 'block'; // Make sure the panel is visible
      }
    });
    
  }
  
  async remove() {
    try {
      
      const purchaseId = this.element.getAttribute("data-purchase-id-value");
      const pupilId = this.element.getAttribute("data-pupil-id-value");
      
      // Define the API endpoint. Adjust this URL according to your API structure.
      const url = `/schools/pupils/${pupilId}/purchase-remove/${purchaseId}`;
    
      const response = await fetch(url, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          "X-CSRF-Token": document.querySelector("meta[name='csrf-token']").getAttribute("content")
        },
        // body: JSON.stringify(data),  // Use if you need to send a body with the request
      });

      const data = await response.json();

      // Handle the response
      if (response.ok) {
        console.log("Successfully removed:", data);
        location.reload();
      } else {
        console.error("Error removing:", data);
      }

    } catch (error) {
      alert("Sorry, something went wrong. Please try again later.");
    }
  }



}
