import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["vehicleCheckbox", "vehicleDays"];
  connect() {
    this.toggleAllDays();
  }
  toggleAllDays() {
    const vehicleCheckboxes = document.querySelectorAll(
      '[id^="premium_instructor_profile_driving_school_vehicle_ids_"]'
    );
    const vehicleDays = document.querySelectorAll(
      '[id^="driving_school_vehicle_"]'
    );
    vehicleCheckboxes.forEach((checkbox) => {
      const vehicleId = checkbox.id.split("_")[7];
      const vehicleDaysId = `driving_school_vehicle_${vehicleId}_days`;
      const vehicleDaysElement = document.getElementById(vehicleDaysId);
      if (checkbox.checked) {
        vehicleDaysElement.style.display = "grid";
      } else {
        vehicleDaysElement.style.display = "none";
      }
    });
    vehicleCheckboxes.forEach((checkbox) => {
      checkbox.addEventListener("change", (event) => {
        const vehicleId = checkbox.id.split("_")[7];
        const vehicleDaysId = `driving_school_vehicle_${vehicleId}_days`;
        const vehicleDaysElement = document.getElementById(vehicleDaysId);
        if (checkbox.checked) {
          vehicleDaysElement.style.display = "grid";
        } else {
          vehicleDaysElement.style.display = "none";
        }
      });
    });
  }
}
