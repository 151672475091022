import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 
    "lateFee", "noShowFee" ]

  connect() {
    
  }

  toggleLateFee() {
    // find lateFee from target
    const lateFee = this.lateFeeTarget;
    // change if lateFee is disabled or enabled
    if(lateFee.disabled) {
      lateFee.disabled = false;
    } else {
      lateFee.disabled = true;
    }
  }

  toggleNoShowFee() {
    // find noShowFee from target
    const noShowFee = this.noShowFeeTarget;
    // change if noShowFee is disabled or enabled
    if(noShowFee.disabled) {
      noShowFee.disabled = false;
    } else {
      noShowFee.disabled = true;
    }
  }

}
