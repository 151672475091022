import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    // find all 'ol' elements and remove them
    const ol = document.querySelectorAll('ol');
    ol.forEach((element) => {
      element.remove();
    }
    );
    // find enquiries-next and remove it
    const next = document.querySelector('#enquiries-next');
    next.remove();

    // find "upload-photo-bonus-points"
    const uploadPhoto = document.querySelector('#upload-photo-bonus-points');
    uploadPhoto.remove();
  }

}