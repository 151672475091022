import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "zoneCheckbox", "zoneDays" ]

  connect() {
    this.toggleAllDays();
  }

  toggleAllDays() {

    const zoneCheckboxes = document.querySelectorAll('[id^="premium_instructor_profile_driving_school_zone_ids_"]');
    const zoneDays = document.querySelectorAll('[id^="driving_school_zones_"]');
    
    zoneCheckboxes.forEach((checkbox) => {
      const zoneId = checkbox.id.split('_')[7];
      const zoneDaysId = `driving_school_zones_${zoneId}_days`;
      const zoneDaysElement = document.getElementById(zoneDaysId);
      if(checkbox.checked) {
        zoneDaysElement.style.display = 'grid';
      } else {
        zoneDaysElement.style.display = 'none';
      }
    });
    
    zoneCheckboxes.forEach((checkbox) => {
      checkbox.addEventListener('change', (event) => {
        const zoneId = checkbox.id.split('_')[7];
        const zoneDaysId = `driving_school_zones_${zoneId}_days`;
        const zoneDaysElement = document.getElementById(zoneDaysId);
        if(checkbox.checked) {
          zoneDaysElement.style.display = 'grid';
        } else {
          zoneDaysElement.style.display = 'none';
        }
      });
    });
    
  }

}
