import { Controller } from "@hotwired/stimulus";
import * as Luxon from "luxon";
import { getOffsetNamesForTimezone } from "../../utils/dates-and-times";

/**
 * This controller can take a timezone and abbreviate it for display purposes.
 */
export default class extends Controller {

    connect() {
        const timezone = this.data.get("timezone");
        const timezoneClass = this.data.get("timezoneClass");
        const timezoneElements = this.element.querySelectorAll('.' + timezoneClass);

        const offsetAbbreviation = getOffsetNamesForTimezone(timezone);
        timezoneElements.forEach(el => {
            el.innerHTML = offsetAbbreviation;
        });
    }

}