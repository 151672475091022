import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  cancelLesson(event) {
    event.preventDefault();
    const cancellationReasonId = this.data.get("cancellationReasonId");
    const cancellationReason = document.getElementById(cancellationReasonId);
    let url = this.data.get("url");
    url = url + "?reason=" + cancellationReason.value;

    // get the guardian_pupil query string value
    const urlParams = new URLSearchParams(window.location.search);
    const guardianPupil = urlParams.get("guardian_pupil");
    if (guardianPupil) {
      url = url + "&guardian_pupil=" + guardianPupil;
    }

    window.location.href = url + "?reason=" + cancellationReason.value;
  }

  payCancelledLesson(event) {
    event.preventDefault();
    const url = this.data.get("payUrl");
    window.location.href = url;
  }

  waiveFeeJson(event) {
    event.preventDefault();

    let cancelledLessonId = event.currentTarget.dataset.lessonId;
    if (cancelledLessonId == null) {
      cancelledLessonId = document.getElementById("cancelled-lesson-id").value;
    }

    const url = `/schools/us/dashboard/lessons/${cancelledLessonId}/waive-fee`;

    fetch(url, {
      method: "GET",
      // JSON response
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.text())
      .then((content) => {
        alert("Fee waived");
        window.location.reload();
      })
      .catch((error) => alert("Error waiving fee"));
  }

  waiveFee(event) {
    event.preventDefault(); // Prevent the default form submission
    let cancelledLessonId = event.currentTarget.dataset.lessonId;
    if (cancelledLessonId == null) {
      cancelledLessonId = document.getElementById("cancelled-lesson-id").value;
    }

    const url = `/schools/us/dashboard/lessons/${cancelledLessonId}/waive-fee`;

    fetch(url, {
      method: "GET",
      headers: {
        Accept: "text/vnd.turbo-stream.html",
        "Turbo-Frame": "quick_look_cancel_lesson",
      },
    })
      .then((response) => response.text())
      .then((content) => {
        Turbo.renderStreamMessage(content);
      })
      .catch((error) => console.error(error));
  }

  sendEmailReminder(event) {
    event.preventDefault(); // Prevent the default form submission
    let cancelledLessonId = event.currentTarget.dataset.lessonId;
    if (cancelledLessonId == null) {
      cancelledLessonId = document.getElementById("cancelled-lesson-id").value;
    }

    const url = `/schools/us/dashboard/lessons/${cancelledLessonId}/email-reminder`;

    fetch(url, {
      method: "GET",
      headers: {
        Accept: "text/vnd.turbo-stream.html",
        "Turbo-Frame": "quick_look_cancel_lesson",
      },
    })
      .then((response) => response.text())
      .then((content) => {
        Turbo.renderStreamMessage(content);
      })
      .catch((error) => console.error(error));
  }
}
