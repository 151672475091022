import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static get targets() {
    return ["form"]
  }

  submitTimeout = null;
  submit(event) {
    event.preventDefault()
    
    if(this.submitTimeout) {
      clearTimeout(this.submitTimeout);
    }

    this.submitTimeout = setTimeout(() => {
      this.formTarget.requestSubmit()
    }, 500);
  }
}