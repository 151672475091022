import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "shiftCheckbox", "shiftDays" ]

  connect() {
    this.toggleAllDays();
  }

  toggleAllDays() {

    // find premium_instructor_profile_driving_school_shift_ids_#{shift.id}
    // and if it is checked, then show the days driving_school_shifts_<%= shift.id %>_days

    const shiftCheckboxes = document.querySelectorAll('[id^="premium_instructor_profile_driving_school_shift_ids_"]');
    const shiftDays = document.querySelectorAll('[id^="driving_school_shifts_"]');
    
    shiftCheckboxes.forEach((checkbox) => {
      const shiftId = checkbox.id.split('_')[7];
      const shiftDaysId = `driving_school_shifts_${shiftId}_days`;
      const shiftDaysElement = document.getElementById(shiftDaysId);
      if(checkbox.checked) {
        shiftDaysElement.style.display = 'grid';
      } else {
        shiftDaysElement.style.display = 'none';
      }
    });
    
    shiftCheckboxes.forEach((checkbox) => {
      checkbox.addEventListener('change', (event) => {
        const shiftId = checkbox.id.split('_')[7];
        const shiftDaysId = `driving_school_shifts_${shiftId}_days`;
        const shiftDaysElement = document.getElementById(shiftDaysId);
        if(checkbox.checked) {
          shiftDaysElement.style.display = 'grid';
        } else {
          shiftDaysElement.style.display = 'none';
        }
      });
    });
    
  }

}
