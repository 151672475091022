import { Controller } from "@hotwired/stimulus";
import { setCurrentHour, setCurrentDay } from "../../utils/calendarUtils";
import * as luxon from "luxon";

export default class extends Controller {
  static targets = [
    "link",
    "formContainer",
    "calendarStyle",
    "horizontalDragPreview",
    "verticalDragPreview",
    "fullLoader",
  ];

  connect() {
    // const hourElements = this.element.querySelectorAll(".Calendar-Hour");
    // setCurrentHour(hourElements);
    // const dayElements = this.element.querySelectorAll(".Calendar-WeekDay");
    // setCurrentDay(dayElements);

    console.log("Hello from Stimulus connect!");
  }

  disconnect() {
    this.hideDragPreview();
    this.hideTextAtBottomOfScreen();
    this.hideLoader();
  }

  itemDragged(event) {
    const type =
      (event &&
        event.detail &&
        event.detail.draggedElementAdditionalData &&
        event.detail.draggedElementAdditionalData.type) ||
      "";
    if (type === "lesson") {
      this.lessonDragged(event, "lesson");
    } else if (type === "reserved") {
      this.lessonDragged(event, "reserved slot");
    } else if (type === "slot") {
      this.lessonDragged(event, "slot");
    } else if (type === "slot_uk") {
      this.lessonDragged(event, "slot");
    } else {
      console.log("Unknown type of dragged element");
    }
  }

  itemDropped(event) {
    const type =
      (event &&
        event.detail &&
        event.detail.draggedElementAdditionalData &&
        event.detail.draggedElementAdditionalData.type) ||
      "";
    if (type === "lesson") {
      this.lessonDropped(event);
    } else if (type === "reserved") {
      // this.reservedLessonDropped(event);
    } else if (type === "slot") {
      this.slotDropped(event);
    } else if (type === "slot_uk") {
      this.ukSlotDropped(event, "slot");
    } else {
      console.log("Unknown type of dropped element");
    }
  }

  itemDragEnded(event) {
    this.hideDragPreview();
  }

  lessonDragged(event, entityType) {
    const timezone = this.data.get("timezone") || "Europe/London";
    let newStartTimestamp = event.detail.topY;
    newStartTimestamp = Math.floor(newStartTimestamp / 300) * 300;
    let newEndTimestamp = Math.floor(
      newStartTimestamp +
        event.detail.draggedElementAdditionalData.durationInMinutes * 60
    );
    const newStartTime = luxon.DateTime.fromSeconds(newStartTimestamp, {
      zone: timezone,
    }).toISO();
    const newEndTime = luxon.DateTime.fromSeconds(newEndTimestamp, {
      zone: timezone,
    }).toISO();

    this.moveDragPreview(newStartTime, newEndTime, entityType);
  }

  async lessonDropped(event) {
    this.hideDragPreview();
    this.showLoader();

    try {
      const csrfToken = document.querySelector(
        'meta[name="csrf-token"]'
      ).content;
      let newStartTimestamp = event.detail.topY;
      newStartTimestamp = Math.floor(newStartTimestamp / 300) * 300;
      const newStartTimeIso = luxon.DateTime.fromSeconds(newStartTimestamp);
      const schoolToken = event.detail.draggedElementAdditionalData.schoolToken;
      const lessonId = event.detail.draggedElementAdditionalData.lessonId;
      const vehicleId = event.detail.draggedElementAdditionalData.vehicleId;
      const durationInMinutes =
        event.detail.draggedElementAdditionalData.durationInMinutes;
      const originalStartTime = luxon.DateTime.fromISO(
        event.detail.draggedElementAdditionalData.originalStartTime
      );
      const newStartTime = luxon.DateTime.fromISO(newStartTimeIso);
      const originalInstructor =
        event.detail.draggedElementAdditionalData.originalInstructorId;
      if (originalStartTime.toUnixInteger() === newStartTime.toUnixInteger()) {
        this.hideLoader();
        this.hideDragPreview();
        this.hideTextAtBottomOfScreen();
        return;
      }

      const url = `/schools/${schoolToken}/quick-look-edit-lesson`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken,
        },
        body: JSON.stringify({
          instructor_id: originalInstructor,
          vehicle_id: vehicleId,
          iso_start_time: newStartTimeIso,
          lesson_id: lessonId,
          duration_in_minutes: durationInMinutes,
        }),
      });

      if (response.ok) {
        setTimeout(() => {
          this.hideLoader();
          this.hideDragPreview();
          this.hideTextAtBottomOfScreen();
        }, 2000);
      } else {
        alert("Something went wrong, please try again later");
        this.hideLoader();
        this.hideDragPreview();
        this.hideTextAtBottomOfScreen();
      }
    } catch (error) {
      alert("Something went wrong, please try again later");
      this.hideLoader();
      this.hideDragPreview();
      this.hideTextAtBottomOfScreen();
    } finally {
    }
  }

  async ukSlotDropped(event) {
    this.hideDragPreview();
    this.showLoader();

    try {
      const csrfToken = document.querySelector(
        'meta[name="csrf-token"]'
      ).content;
      let newStartTimestamp = event.detail.topY;
      newStartTimestamp = Math.floor(newStartTimestamp / 300) * 300;

      const startTimeIso = luxon.DateTime.fromSeconds(newStartTimestamp);
      const originalStartTime =
        event.detail.draggedElementAdditionalData.originalStartTime;
      const lessonId = event.detail.draggedElementAdditionalData.lessonId;

      const isSameTime =
        startTimeIso.toUnixInteger() ===
        luxon.DateTime.fromISO(originalStartTime).toUnixInteger();
      if (isSameTime) {
        this.hideLoader();
        this.hideDragPreview();
        this.hideTextAtBottomOfScreen();
        return;
      }

      const url = `/pro/web/slot/update`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken,
        },
        body: JSON.stringify({
          new_time_iso: startTimeIso,
          lesson_id: lessonId,
        }),
      });

      if (response.ok) {
        setTimeout(() => {
          this.hideLoader();
          this.hideDragPreview();
          this.hideTextAtBottomOfScreen();
        }, 2000);
      } else {
        console.log("Something went wrong, please try again later");
        this.hideLoader();
        this.hideDragPreview();
        this.hideTextAtBottomOfScreen();
      }
    } catch (error) {
      // Stack trace
      console.log(error);
      console.log("Something went wrong, please try again later");
      this.hideLoader();
      this.hideDragPreview();
      this.hideTextAtBottomOfScreen();
    } finally {
    }
  }

  async slotDropped(event) {
    this.hideDragPreview();
    this.showLoader();

    try {
      const csrfToken = document.querySelector(
        'meta[name="csrf-token"]'
      ).content;
      let newStartTimestamp = event.detail.topY;
      newStartTimestamp = Math.floor(newStartTimestamp / 300) * 300;

      const authenticityToken = this.data.get("authenticity");

      const startTimeIso = luxon.DateTime.fromSeconds(newStartTimestamp);
      const originalStartTime =
        event.detail.draggedElementAdditionalData.originalStartTime;
      const originalEndTime =
        event.detail.draggedElementAdditionalData.originalEndTime;
      const vehicleIds = JSON.parse(
        event.detail.draggedElementAdditionalData.vehicleIds || "[]"
      );
      const zoneIds = JSON.parse(
        event.detail.draggedElementAdditionalData.zoneIds || "[]"
      );
      const instructorId =
        event.detail.draggedElementAdditionalData.instructorId;
      const currentStudents =
        event.detail.draggedElementAdditionalData.currentStudents;
      const newStudents = event.detail.draggedElementAdditionalData.newStudents;

      const isSameTime =
        startTimeIso.toUnixInteger() ===
        luxon.DateTime.fromISO(originalStartTime).toUnixInteger();
      if (isSameTime) {
        this.hideLoader();
        this.hideDragPreview();
        this.hideTextAtBottomOfScreen();
        return;
      }

      const url = `/schools/bookings/update-slot`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken,
        },
        body: JSON.stringify({
          original_start_time: originalStartTime,
          original_end_time: originalEndTime,
          start_time_iso: startTimeIso,
          vehicle_ids: vehicleIds,
          zone_ids: zoneIds,
          instructor_id: instructorId,
          slot_edit_instructor_id: instructorId,
          current_students: currentStudents,
          new_students: newStudents,
          original_instructor_id: instructorId,
        }),
      });

      if (response.ok) {
        setTimeout(() => {
          this.hideLoader();
          this.hideDragPreview();
          this.hideTextAtBottomOfScreen();
        }, 2000);
      } else {
        console.log("Something went wrong, please try again later");
        this.hideLoader();
        this.hideDragPreview();
        this.hideTextAtBottomOfScreen();
      }
    } catch (error) {
      // Stack trace
      console.log(error);

      console.log("Something went wrong, please try again later");
      this.hideLoader();
      this.hideDragPreview();
      this.hideTextAtBottomOfScreen();
    } finally {
    }
  }

  hideDragPreview() {
    this.hideTextAtBottomOfScreen();
    if (!this.hasHorizontalDragPreviewTarget) return;
    const dragPreview = this.horizontalDragPreviewTarget;
    if (!dragPreview) return;
    dragPreview.classList.add("u-hidden");
  }

  moveDragPreview(startTimeIso, endTimeIso, entityType) {
    const timeZone = this.data.get("timezone") || "Europe/London";
    console.log("moveDragPreview", startTimeIso, endTimeIso, entityType);
    if (!this.hasHorizontalDragPreviewTarget) return;
    const dragPreview = this.horizontalDragPreviewTarget;
    if (!dragPreview) return;
    const startDateTime =
      luxon.DateTime.fromISO(startTimeIso).setZone(timeZone);
    const endDateTime = luxon.DateTime.fromISO(endTimeIso).setZone(timeZone);
    const durationInMinutes = endDateTime.diff(
      startDateTime,
      "minutes"
    ).minutes;
    const startHours = startDateTime.toFormat("hha").toLowerCase();
    const startMins = startDateTime.toFormat("mm").toLowerCase();
    const endHours = endDateTime.toFormat("hha").toLowerCase();
    const endMins = endDateTime.toFormat("mm").toLowerCase();
    // eed to set the day of week e.g. mon, tue, wed etc.
    const dayOfWeek = startDateTime.toFormat("ccc").toLowerCase();
    dragPreview.dataset.timeHours = startHours;
    dragPreview.dataset.timeMins = startMins;
    dragPreview.dataset.duration = durationInMinutes;
    dragPreview.dataset.day = dayOfWeek;
    dragPreview.classList.remove("u-hidden");
    this.displayTextAtBottomOfScreen(
      `Move ${entityType} to ${startDateTime.toFormat(
        "h:mma"
      )} - ${endDateTime.toFormat("h:mma")}`
    );
  }

  panelId = `${Math.random().toString(36).substring(7)}`;
  displayTextAtBottomOfScreen(text) {
    // Create the panel and animate upwards if necessary
    let panel = document.getElementById(this.panelId);
    if (!panel) {
      panel = document.createElement("div");
      panel.id = this.panelId;
      panel.style.position = "fixed";
      panel.style.bottom = "0";
      panel.style.left = "0";
      panel.style.width = "100%";
      panel.style.backgroundColor = "#FEFAE3";
      panel.style.padding = "10px";
      panel.style.textAlign = "center";
      panel.style.zIndex = "5";
      panel.style.borderTop = "1px solid #EFDE43";
      // Panel should animate offscreen
      panel.style.transform = "translateY(100%)";
      panel.style.transition = "transform 0.5s";
      document.body.appendChild(panel);
    }

    setTimeout(() => {
      panel.style.transform = "translateY(0)";
    });
    panel.textContent = text;
  }

  hideTextAtBottomOfScreen() {
    const panel = document.getElementById(this.panelId);
    if (!panel) return;
    panel.style.transform = "translateY(100%)";
  }

  showLoader() {
    // Set display to flex
    if (!this.hasFullLoaderTarget) return;
    this.fullLoaderTarget.style.display = "flex";
  }

  hideLoader() {
    // Set display to none
    if (!this.hasFullLoaderTarget) return;
    this.fullLoaderTarget.style.display = "none";
  }
}
