window.reportFeedback = function(is_useful) {
  const feedback_data = { 'page': window.location.pathname, 'is_useful': is_useful, 'comment': '' }
  const url = '/feedbacks/'
  window.ajaxRecaptcha("feedback", {
    url: url,
    data: feedback_data,
    type: 'POST',
    success: function(response) {
      $('#feedback-thanks').show()
      $('#feedback-request').hide()
      return false
    }
  })
}