import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "address",
    "zipcode",
    "pickUpAddressButton",
    "dropOffAddressButton",
  ];

  connect() {
    console.log("school_lesson_quick_look_edit_location_controller.js");
    this.load_schools_and_pickup_points();
    this.initialize_note_counter("pick_up_note");
    this.initialize_note_counter("drop_off_note");
  }

  async load_schools_and_pickup_points() {
    var lesson_id = document.getElementById("lesson_id");
    let address_line_1 = document.getElementById(
      "booking_location_pickup_home_work_address_value"
    ).value;
    const zipcode = document.getElementById(
      "booking_location_pickup_home_work_zipcode_value"
    ).value;
    let address_line_2 = document.getElementById(
      "booking_location_pickup_home_work_address_line_2_value"
    ).value;
    let city = document.getElementById(
      "booking_location_pickup_home_work_city_value"
    ).value;
    let state = document.getElementById(
      "booking_location_pickup_home_work_state_value"
    ).value;
    let country = document.getElementById(
      "booking_location_pickup_home_work_country_value"
    ).value;

    var school_token = document.getElementById("us-school-token").value;
    if (lesson_id) {
      lesson_id = lesson_id.value;
    }

    let extraParams = "";
    if (document.getElementById("pick_up_location_id")) {
      const pickupLocationId = document.getElementById(
        "pick_up_location_id"
      ).value;
      const pickUpCategory = document.getElementById("pick_up_category").value;
      const dropoffLocationId = document.getElementById(
        "drop_off_location_id"
      ).value;
      const dropOffCategory =
        document.getElementById("drop_off_category").value;
      extraParams = `&pick_up_location_id=${pickupLocationId}&pick_up_category=${pickUpCategory}&drop_off_location_id=${dropoffLocationId}&drop_off_category=${dropOffCategory}`;
      extraParams = encodeURIComponent(extraParams);
    }

    let response = null;
    // if zipcode is blank fetch by lesson id
    if (zipcode == "") {
      const url = `/schools/us/fetch-locations/v2/lesson/${lesson_id}/admin?driving_school_token=${school_token}${extraParams}`;
      response = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "text/vnd.turbo-stream.html",
        },
      });
    } else {
      // strip out any # or / from address_line_1
      address_line_1 = address_line_1.replace(/#/g, "");
      address_line_1 = address_line_1.replace(/\//g, "");

      const url = `/schools/us/fetch-locations/v2/admin?driving_school_token=${school_token}&address_line_1=${address_line_1}&zipcode=${zipcode}${extraParams}&lesson_id=${lesson_id}`;

      response = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "text/vnd.turbo-stream.html",
        },
      });
    }

    if (response.ok) {
      const stream = await response.text();
      Turbo.renderStreamMessage(stream);
    } else {
      console.log("error");
    }
  }

  previous_drop_off_selected(event) {
    const address = event.currentTarget.getAttribute("data-address");
    const zipcode = event.currentTarget.getAttribute("data-zipcode");

    if (address != "" && zipcode != "") {
      document.getElementById(
        "booking_location_drop_off_home_work_address_value"
      ).value = address;
      document.getElementById(
        "booking_location_drop_off_home_work_zipcode_value"
      ).value = zipcode;
      this.booking_drop_off_home_work_clicked(event);
    }

    // get target addressButton and make sure its checked.
    this.dropOffAddressButtonTarget.checked = true;
  }

  previous_pick_up_selected(event) {
    const address = event.currentTarget.getAttribute("data-address");
    const zipcode = event.currentTarget.getAttribute("data-zipcode");

    if (address != "" && zipcode != "") {
      document.getElementById(
        "booking_location_pickup_home_work_address_value"
      ).value = address;
      document.getElementById(
        "booking_location_pickup_home_work_zipcode_value"
      ).value = zipcode;
      this.booking_pickup_home_work_clicked(event);
    }

    // get target addressButton and make sure its checked.
    this.pickUpAddressButtonTarget.checked = true;
  }

  toggle_drop_off_location(event) {
    // check if sameAsPickUpLocation is checked
    if (document.getElementById("sameAsPickUpLocation").checked) {
      document.getElementById("full-drop-off-form").hidden = true;
      document.getElementById("sameAsPickUpLocation").value = "true";

      const address_line_1 = document.getElementById(
        "address_line_1_value"
      ).value;
      const zipcode = document.getElementById("zipcode_value").value;
      const access_note = document.getElementById("access_note").value;
      const city = document.getElementById("city_value").value;
      const state = document.getElementById("state_value").value;
      const country = document.getElementById("country_value").value;
      const address_line_2 = document.getElementById(
        "address_line_2_value"
      ).value;

      document.getElementById(
        "booking_location_drop_off_home_work_address_value"
      ).value = address_line_1;
      document.getElementById(
        "booking_location_drop_off_home_work_zipcode_value"
      ).value = zipcode;
      document.getElementById("drop_off_note").value = access_note;
    } else {
      document.getElementById("full-drop-off-form").hidden = false;
      document.getElementById("sameAsPickUpLocation").value = "false";

      document.getElementById(
        "booking_location_drop_off_home_work_address_value"
      ).value = "";
      document.getElementById(
        "booking_location_drop_off_home_work_zipcode_value"
      ).value = "";
      document.getElementById("drop_off_note").value = "";

      this.initialize_note_counter("drop_off_note");
    }
  }

  toggle_drop_off_location_lesson(event) {
    // check if sameAsPickUpLocation is checked
    if (document.getElementById("sameAsPickUpLocation").checked) {
      document.getElementById("full-drop-off-form").hidden = true;
      document.getElementById("sameAsPickUpLocation").value = "true";
    } else {
      document.getElementById("full-drop-off-form").hidden = false;
      document.getElementById("sameAsPickUpLocation").value = "false";
    }
  }

  booking_pickup_home_work_clicked(event) {
    document.getElementById("booking-location-pickup-home-work").hidden = false;
    document.getElementById("booking-location-pickup-points").hidden = true;
    // document.getElementById("booking-location-pickup-schools").hidden = true; // remove booking-location-pickup-schools
    document.getElementById("booking-location-pickup-previous").hidden = true;
  }

  booking_pickup_points_clicked(event) {
    document.getElementById("booking-location-pickup-home-work").hidden = true;
    document.getElementById("booking-location-pickup-points").hidden = false;
    // document.getElementById("booking-location-pickup-schools").hidden = true;
    document.getElementById("booking-location-pickup-previous").hidden = true;
  }

  booking_pickup_previous_clicked(event) {
    document.getElementById("booking-location-pickup-previous").hidden = false;
    document.getElementById("booking-location-pickup-home-work").hidden = true;
    document.getElementById("booking-location-pickup-points").hidden = true;
  }

  booking_drop_off_home_work_clicked(event) {
    document.getElementById(
      "booking-location-drop-off-home-work"
    ).hidden = false;
    document.getElementById("booking-location-drop-off-points").hidden = true;
    document.getElementById("booking-location-drop-off-previous").hidden = true;
  }

  booking_drop_off_points_clicked(event) {
    document.getElementById(
      "booking-location-drop-off-home-work"
    ).hidden = true;
    document.getElementById("booking-location-drop-off-points").hidden = false;
    document.getElementById("booking-location-drop-off-previous").hidden = true;
  }

  booking_drop_off_previous_clicked(event) {
    document.getElementById(
      "booking-location-drop-off-previous"
    ).hidden = false;
    document.getElementById(
      "booking-location-drop-off-home-work"
    ).hidden = true;
    document.getElementById("booking-location-drop-off-points").hidden = true;
  }

  initialize_note_counter(id) {
    const noteField = document.getElementById(id);
    if (!noteField) return;

    this.update_note_counter({ target: noteField });
  }

  update_note_counter(event) {
    const MAX_LENGTH = 100;
    const noteLength = event.target.value.length;
    const noteCounterId = event.target.dataset.counterId;
    const noteCounter = document.getElementById(noteCounterId);

    if (noteCounter) {
      noteCounter.textContent = `${noteLength}/${MAX_LENGTH}`;

      if (noteLength > MAX_LENGTH) {
        noteCounter.style.color = "red";
      } else {
        noteCounter.style.color = "";
      }
    }
  }

  validate(event) {
    const errors = this.validate_form();

    if (errors.length > 0) {
      event.preventDefault();
      this.show_validation_block(errors.join("<br>"));
      return;
    }
  }

  validate_form() {
    let errors = [];

    let currentPickupCategory = "";
    if (!document.getElementById("booking-location-pickup-home-work").hidden) {
      currentPickupCategory = "Address";
    } else if (
      !document.getElementById("booking-location-pickup-points").hidden
    ) {
      currentPickupCategory = "Pick-up points";
    }

    let currentDropoffCategory = "";
    if (!document.getElementById("sameAsPickUpLocation").checked) {
      if (
        !document.getElementById("booking-location-drop-off-home-work").hidden
      ) {
        currentDropoffCategory = "Address";
      } else if (
        !document.getElementById("booking-location-drop-off-points").hidden
      ) {
        currentDropoffCategory = "Drop-off points";
      }
    }

    if (currentPickupCategory === "Address") {
      this.validate_access_note(
        "pick_up_note",
        "Access note for pick-up location",
        errors
      );
    }

    if (currentDropoffCategory === "Address") {
      this.validate_access_note(
        "drop_off_note",
        "Access note for drop-off location",
        errors
      );
    }

    if (currentPickupCategory === "Pick-up points") {
      this.validate_location({
        locationSelector: "#booking-location-pickup-points",
        errors,
      });
    }

    if (currentDropoffCategory === "Drop-off points") {
      this.validate_location({
        locationSelector: "#booking-location-drop-off-points",
        isPickup: false,
        errors,
      });
    }

    return errors;
  }

  validate_access_note(fieldId, fieldName, errors) {
    const field = document.getElementById(fieldId);
    const MAX_LENGTH = 100;
    if (field && field.value.trim().length > MAX_LENGTH) {
      errors.push(`${fieldName} must be ${MAX_LENGTH} characters or less.`);
    }
  }

  validate_location({ locationSelector, isPickup = true, errors }) {
    const locationPoints = document.querySelectorAll(
      `${locationSelector} ul li`
    );
    const locationType = isPickup ? "pick-up" : "drop-off";

    if (!locationPoints || locationPoints.length === 0) {
      errors.push(
        `No ${locationType} points are available, you must select another type of ${locationType} location.`
      );
    } else {
      const selectedPoint = Array.from(locationPoints).some(
        (point) => !point.classList.contains("Panel--subtle")
      );

      if (!selectedPoint) {
        errors.push(
          `You must select a ${locationType} point from the options.`
        );
      }
    }
  }

  show_validation_block(message) {
    const validationBlock = document.getElementById("validation-block");
    const validationMessage = document.getElementById("validation-message");

    validationMessage.innerHTML = message;
    validationBlock.hidden = false;
    validationMessage.hidden = false;
  }
}
