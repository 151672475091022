import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  

  connect() {
  }

  async checkConflicts(event) {
    event.preventDefault();
    const lessonConflictsValidationError = document.getElementById('lesson_conflicts_validation_error');
    lessonConflictsValidationError.hidden = true;

    // find end_date and start_date
    const startDate = document.getElementById('start_date').value;
    const endDate = document.getElementById('end_date').value;
    // get instructor-id and remove white spaces
    // const instructorId = document.getElementById('instructor-id').value.replace(/\s/g, '');
    const instructorIdElement = document.getElementById("instructor-id");
    const instructorId = instructorIdElement.textContent.trim();

    // call 'schools/web/event/check_lesson_conflicts' with start_date and end_date and instructor_id
    const url = `/schools/web/event/check-lesson-conflicts?start_date=${startDate}&end_date=${endDate}&instructor_id=${instructorId}`;
    const response = fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    response
    .then((response) => {
      if (response.status === 409) {
        // If the server responds with a 409 status, alert the user and prevent form submission
        // find lesson_conflicts_validation_error
        const lessonConflictsValidationError = document.getElementById('lesson_conflicts_validation_error');
        lessonConflictsValidationError.hidden = false;
        
        return Promise.reject('Conflict error');
      } else {
        return response.json();
      }
    })
    .then((data) => {
      // This code will only run if the response is not a 409
      const form = document.getElementById('us_school_event');
      form.submit();
    })
    .catch((error) => {
      console.error('Error:', error);
      // You can handle additional error cases here if needed
    });

    
  }

}
