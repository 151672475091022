import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "addressInput",
    "zipcodeInput",
    "addressListContainer",
    "addressList",
    "itemTemplate",
    "itemTemplateAddress",
    "itemTemplateZipcode",
    "loader",
    "error",
    "locationIqPlaceId",
    "googlePlaceId",
    "latitude",
    "longitude",
  ];
  // States 'idle', 'typing', 'loading', 'loaded', 'error'
  state = "idle";
  typingTimer = null;
  useGoogle = false;

  valueChangeListener = (event) => {
    if (!this.addressInputTarget.value || !this.zipcodeInputTarget.value) {
      this.showNothing();
      return;
    }

    // find us-booking-next-3
    let usBookingNext3 = document.getElementById("us-booking-next-3");
    if (usBookingNext3) {
      // disable the button
      usBookingNext3.disabled = true;
    }
    
    this.useGoogle = false;
    this.state = "typing";
    this.showLoading();
    this.lastTypingTime = Date.now();
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
    this.typingTimer = setTimeout(() => {
      this.callApi();
    }, 1500);
  };

  connect() {
    this.addressInputTarget.addEventListener("input", this.valueChangeListener);
    this.zipcodeInputTarget.addEventListener("input", this.valueChangeListener);
    this.showNothing();
  }

  disconnect() {
    this.addressInputTarget.removeEventListener(
      "input",
      this.valueChangeListener
    );
    this.zipcodeInputTarget.removeEventListener(
      "input",
      this.valueChangeListener
    );
  }

  async callApi() {
    // make a get call to utilities_api/search_for_location
    // get drivingSchoolToken 
    let drivingSchoolToken = this.data.get("drivingSchoolToken");
    
    if (!drivingSchoolToken) {
      drivingSchoolToken = "";
    }

    try {
      this.state = "loading";
      const response = await fetch(
        `/utilities_api/search_for_location?address=${this.addressInputTarget.value}&zipcode=${this.zipcodeInputTarget.value}&use_google=${this.useGoogle}&driving_school_token=${drivingSchoolToken}`
      );
      const data = await response.json();
      this.addressListTarget.innerHTML = "";
      data["addresses"].forEach((item) => {
        const clone = this.itemTemplateTarget.content.cloneNode(true);
        this.addressListTarget.appendChild(clone);
        const lastChild = this.addressListTarget.lastElementChild;
        lastChild.querySelector(
          '[data-address-autocomplete-target="itemTemplateAddress"]'
        ).innerText = item.formatted_address;
        lastChild.addEventListener("click", () => {
          this.addressInputTarget.value = item.formatted_address;
          this.zipcodeInputTarget.value = item.zipcode;
          // this.locationIqPlaceIdTarget.value = item.location_iq_place_id;
          // this.googlePlaceIdTarget.value = item.google_place_id;
          // this.latitudeTarget.value = item.latitude;
          // this.longitudeTarget.value = item.longitude;
          this.showNothing();
        });
      });

      this.state = "loaded";
      this.showLoaded();

      if (data["addresses"].length === 0 && !this.useGoogle) {
        this.useGoogle = true;
        this.showLoading();
        this.callApi();
      }
      else if (data["addresses"].length === 0 && this.useGoogle) {
        this.showError();
      }
    } catch (error) {
      console.error(error);
      this.state = "error";
      this.showError();
    }
        // find us-booking-next-3
    let usBookingNext3 = document.getElementById("us-booking-next-3");
    if (usBookingNext3) {
      usBookingNext3.disabled = false;
    }
  }

  clickNoAddressFound() {
    if (this.useGoogle) {
      this.showError();
    } else {
      this.useGoogle = true;
      this.showLoading();
      this.callApi();
    }
  }

  showNothing() {
    this.addressListContainerTarget.style.display = "none";
    this.loaderTarget.style.display = "none";
    this.errorTarget.style.display = "none";
  }

  showLoading() {
    this.addressListContainerTarget.style.display = "none";
    this.loaderTarget.style.display = "block";
    this.errorTarget.style.display = "none";
  }

  showLoaded() {
    this.addressListContainerTarget.style.display = "block";
    this.loaderTarget.style.display = "none";
    this.errorTarget.style.display = "none";
  }

  showError() {
    this.addressListContainerTarget.style.display = "none";
    this.loaderTarget.style.display = "none";
    this.errorTarget.style.display = "block";
  }
}
