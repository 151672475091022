import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  
  showReferralCodeField(event) {
    
    event.preventDefault();
  
    this.validateBookingFields().then(errors => {
      if (errors.length > 0) {
        // Handle errors (e.g., display them to the user)
        const errorPanel = document.getElementById("booking-errors-panel");
        errorPanel.classList.remove("u-hidden");
  
        const errorList = document.getElementById("booking-errors");
        errorList.innerHTML = "";
        errors.forEach(error => {
          const li = document.createElement("li");
          li.innerText = error;
          errorList.appendChild(li);
        });
        return; // Stop further execution if there are errors
      }

      const errorPanel = document.getElementById("booking-errors-panel");
      errorPanel.classList.add("u-hidden");
  
      // submit the form
      const form = document.getElementById("new_booking");
      form.submit();
    });
  }
  
  validateBookingFields() {
    return this.validateBookingFieldsAsync(); 
  }

  // Asynchronous function for validation
  async validateBookingFieldsAsync() {
    let errors = [];

    // Retrieve form elements
    const bookingFirstName = document.getElementById("booking_first_name");
    const bookingLastName = document.getElementById("booking_last_name");
    const bookingEmail = document.getElementById("booking_email");
    const bookingMobileNumber = document.getElementById("booking_mobile_number");
    const bookingPickupLocation = document.getElementById("booking_pickup_location");
    const bookingWhenAmIFree = document.getElementById("booking_when_am_i_free");
    const bookingPostcode = document.getElementById("booking_postcode");
    const bookingGearboxManual = document.getElementById("booking-form-car-manual");
    const bookingGearboxAutomatic = document.getElementById("booking-form-car-automatic");
    const bookingGearboxBoth = document.getElementById("booking-form-car-both");

    // make sure a gearbox is selected
    if (!bookingGearboxManual.checked && !bookingGearboxAutomatic.checked && !bookingGearboxBoth.checked) {
      errors.push("Gearbox must be selected");
    }

    // Validate each field
    if (!bookingFirstName.value) errors.push("First Name can't be blank");
    if (!bookingLastName.value) errors.push("Last Name can't be blank");
    if (!bookingPostcode.value) errors.push("Postcode can't be blank");
    if (!bookingEmail.value) errors.push("Email can't be blank");

    if (bookingEmail.value) {
      // Validate email format
      const emailRegex = /\S+@\S+\.\S+/;
      if (!emailRegex.test(bookingEmail.value)) {
        errors.push("Email is invalid");
      }

      // Check if email is taken
      try {
        // const encodedEmail = btoa(bookingEmail.value);
        // const response = await fetch(`/us/student/${encodedEmail}/taken`);
        // const data = await response.json();
        // if (data.email_taken) {
        //   errors.push("This email is already taken, please use another email.");
        // }
      } catch (error) {
        console.error("Error checking email:", error);
        // Optionally, add a generic error message
        errors.push("Unable to verify email at this time");
      }
    }

    if (!bookingMobileNumber.value) errors.push("Mobile Number can't be blank");
    if (bookingMobileNumber.value) {
      // Validate mobile number format
      const mobileNumberRegex = /^[0-9]{10,13}$/;
      if (!mobileNumberRegex.test(bookingMobileNumber.value)) {
        errors.push("Mobile Number is invalid");
      }
    }

    if (!bookingPickupLocation.value) errors.push("Pickup Location can't be blank");
    if (!bookingWhenAmIFree.value) errors.push("When are you free can't be blank");

    return errors;
  }

  bookingFormCarAutomatic(event) {
    let instructorsCarTransmission = document.getElementById('car-transmission').textContent.trim();
    if (instructorsCarTransmission !== 'both') {
      if (instructorsCarTransmission === 'manual') {
        document.getElementById("modal-transmission").classList.remove('u-hidden');
      }
    }
  }

  bookingFormCarManual(event) {
    let instructorsCarTransmission = document.getElementById('car-transmission').textContent.trim();
    if (instructorsCarTransmission !== 'both') {
      if (instructorsCarTransmission === 'automatic') {
        document.getElementById("modal-transmission").classList.remove('u-hidden');
      }
    }
  }

  showMeOtherInstructorsButton(event) {
    event.preventDefault();
    let transmissionPreference = 'manual';
    let instructorsCarTransmission = document.getElementById('car-transmission').textContent.trim();
    if (instructorsCarTransmission === 'manual') {
      transmissionPreference = 'automatic';
    }

    let postcode = ''; // Ensure you have a way to get the postcode value
    if (postcode === '') {
      window.location.href = 'https://www.goroadie.com/search';
    } else {
      window.location.href = `http://localhost:3000/search/postcode/${postcode}?transmission-type-${transmissionPreference}=true`;
    }
  }

  acceptTransmissionButton(event) {
    event.preventDefault();
    let instructorsCarTransmission = document.getElementById('car-transmission').textContent.trim();
    if (instructorsCarTransmission === 'manual') {
      document.getElementById('booking-form-car-manual').checked = true;
      document.getElementById('booking-form-car-automatic').checked = false;
    } else if (instructorsCarTransmission === 'automatic') {
      document.getElementById('booking-form-car-manual').checked = false;
      document.getElementById('booking-form-car-automatic').checked = true;
    }
    document.getElementById("modal-transmission").classList.add('u-hidden');
  }
}
