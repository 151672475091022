import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "pupilFirstName",
    "pupilLastName",
    "pupilMobileNumber",
    "pupilEmail",
    "pupilAddress",
    "pupilPostcode",
    "formError",
    "datePicker",
    "timePicker",
    "offerDate",
  ]
  
  connect() {
    this.setupPickers();
    this.prepareForm();
  }

  prepareForm() {
    const urlParams = new URLSearchParams(window.location.search);
    const pupil_first_name = urlParams.get('pupil_first_name');
    if (pupil_first_name) {
      this.pupilFirstNameTarget.value = pupil_first_name;
    }
    const pupil_last_name = urlParams.get('pupil_last_name');
    if (pupil_last_name) {
      this.pupilLastNameTarget.value = pupil_last_name;
    }
    const pupil_mobile_number = urlParams.get('pupil_mobile_number');
    if (pupil_mobile_number) {
      this.pupilMobileNumberTarget.value = pupil_mobile_number;
    }
    const pupil_email = urlParams.get('pupil_email');
    if (pupil_email) {
      this.pupilEmailTarget.value = pupil_email;
    }
    const pupil_address = urlParams.get('pupil_address');
    if (pupil_address) {
      this.pupilAddressTarget.value = pupil_address;
    }
    const pupil_postcode = urlParams.get('pupil_postcode');
    if (pupil_postcode) {
      this.pupilPostcodeTarget.value = pupil_postcode;
    } 
  }

  setupPickers() {
    const datePickerElement = this.datePickerTarget;
    const timePickerElement = this.timePickerTarget;

    $(datePickerElement).pickadate({
      onClose: () => {
        // Removed hide() call
        if ($(datePickerElement).val().length > 0) {
          $(timePickerElement).pickatime('open');
        }
      }
    });

    $(timePickerElement).pickatime({
      min: [7, 0],
      interval: 15,
      max: [21, 0],
      onSet: (event) => {
        // Removed hide() call
        const date = $(datePickerElement).val();
        const time = $(timePickerElement).val();
        this.offerDateTarget.value = `${date} ${time}`;
      }
    });

    $(datePickerElement).on('mousedown', (event) => {
      event.preventDefault();
    });

    $(timePickerElement).on('mousedown', (event) => {
      event.preventDefault();
    });
  }

  submitLessonRequest(event) {
    let validationErrors = this.validateNewPupilForm(event);
    debugger;
    if (validationErrors.length > 0) {
      event.preventDefault(); 
      this.formErrorTarget.classList.remove("u-hidden");
      this.formErrorTarget.innerHTML = validationErrors.join("<br>");
      return;
    }

    // enable this to submit the form
    this.formTarget.submit();
    alert("Lesson request submitted!");
  }

  validateNewPupilForm(event) {
    let errors = []

    if (this.pupilFirstNameTarget.value == "") {
      errors.push("First name is a required field.");
    }
    if (this.pupilLastNameTarget.value == "") {
      errors.push("Last name is a required field.");
    }
    if (this.pupilMobileNumberTarget.value == "") {
      errors.push("Mobile number is a required field.");
    } else {
      if (this.pupilMobileNumberTarget.value.length < 10) {
        errors.push("Mobile number must be at least 10 characters long.");
      }
    }
    if (this.pupilEmailTarget.value == "") {
      errors.push("Email is a required field.");
    } else {
      if (!this.validateEmail(this.pupilEmailTarget.value)) {
        errors.push("Email is not in a valid format.");
      }
    }
    if (this.pupilAddressTarget.value == "") {
      errors.push("Address is a required field.");
    }
    if (this.pupilPostcodeTarget.value == "") {
      errors.push("Postcode is a required field.");
    }

    //check date is not in the past
    let date = new Date(this.offerDateTarget.value);
    let now = new Date();
    if (date < now) {
      errors.push("Date and time must be in the future.");
    }

    return errors;
  }

  validateEmail(email) {
    // regex for email validation
    let emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

}
